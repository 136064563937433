import {
  EmployerInvestmentDetail,
  EmployeeInvestmentDetail
} from 'redux/reducers/employer';

export default function InvestmentTableTopBar({
  employee_investment_detail,
  employer_investment_detail
}: InvestmentTableTopBarProps) {
  return (
    <div>
      <div className='table-responsive'>
        <table className='table table-borderless investmentsTable'>
          <thead>
            <tr>
              <th>Employer Contribution to Date</th>
              <th>Matched Contributions to date</th>
              <th>Total Contributions to date</th>
              <th>Accumulated Value</th>
              <th>Return (absolute)</th>
              <th>Return (annualize)</th>
            </tr>
            {employer_investment_detail.length
              ? employer_investment_detail.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {item.contributions_todate?.toLocaleString() || 0} PKR
                      </td>
                      <td>
                        {item.matched_contribution?.toLocaleString() || 0} PKR
                      </td>
                      <td>
                        {item.total_contribution?.toLocaleString() || 0} PKR
                      </td>
                      <td>{item.accumulated_values?.toLocaleString() || 0}</td>
                      <td>{item.return_absolute} %</td>
                      <td>{item.return_annualize} %</td>
                    </tr>
                  );
                })
              : null}
            <tr>
              <th>Holdings</th>
              <th>Value</th>
              <th>Allocation %</th>
            </tr>
          </thead>
          <tbody>
            {employee_investment_detail.length
              ? employee_investment_detail.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.holdings?.toLocaleString() || 0}</td>
                      <td>{item.values?.toLocaleString() || 0}</td>
                      <td>{item.percent?.toLocaleString() || 0} %</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}

interface InvestmentTableTopBarProps {
  employer_investment_detail: Array<EmployerInvestmentDetail>;
  employee_investment_detail: Array<EmployeeInvestmentDetail>;
}
