import axios, { AxiosError } from 'axios';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { AnyAction, Middleware } from 'redux';
import { onLogout } from 'redux/actions';
import { RootState } from 'redux/store';
import { mapAPIError } from './mapAPIError';

export const api: Middleware =
  ({
    dispatch,
    getState
  }: {
    dispatch: Dispatch<AnyAction>;
    getState: () => RootState;
  }) =>
  next =>
  async action => {
    if (!action.payload?.apiName) return next(action);

    next(action);

    const {
      apiName,
      data,
      formData = false,
      headers: extraHeaders,
      method,
      onFail,
      onSuccess,
      url
    } = action.payload;
    const baseURL = process.env.REACT_APP_BASE_URL;

    dispatch({ payload: apiName, type: 'globalLoading/start' });
    dispatch({ type: `${apiName}/${action.type}` });

    const token = `Bearer ${getState().auth.accessToken}`;
    const headers = { Authorization: token, ...extraHeaders };
    if (formData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    try {
      const response = await axios.request({
        baseURL,
        data,
        headers,
        method,
        url
      });
      dispatch({
        payload: response.data,
        type: `${apiName}/${action.type}Success`
      });
      dispatch({ payload: apiName, type: 'globalLoading/stop' });

      onSuccess && dispatch(onSuccess(response.data, data));
    } catch (err) {
      const error = err as AxiosError;
      const { message, response } = error;
      const payload = {
        message: mapAPIError(error),
        statusCode: response?.status,
        statusText: response?.statusText
      };
      if (payload.statusCode === 403) {
        dispatch(onLogout());
        toast.dismiss();
      } else {
        dispatch({ payload, type: `${apiName}/${action.type}Fail` });
        dispatch({ payload, type: `globalErrors/get` });
      }
      dispatch({ payload: apiName, type: 'globalLoading/stop' });
      onFail && onFail(message, response);
    }
  };
