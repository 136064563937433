export const getDateDMYFormat = (time: string, divider = '/') => {
  const date = new Date(time);
  return `${String(date.getDate()).padStart(2, '0')}${divider}${String(
    date.getMonth() + 1
  ).padStart(2, '0')}${divider}${String(date.getFullYear()).padStart(2, '0')}`;
};

export const getDateYMDFormat = (time: string, divider = '-') => {
  if (time?.length) {
    const date = new Date(time);
    return `${String(date.getFullYear()).padStart(2, '0')}${divider}${String(
      date.getMonth() + 1
    ).padStart(2, '0')}${divider}${String(date.getDate()).padStart(2, '0')}`;
  }
  return '';
};
