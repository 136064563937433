import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Cnic from 'jsx/components/CompleteAccount/Cnic';
import HeaderText from 'jsx/components/CompleteAccount/HeaderText';
import Personal from 'jsx/components/CompleteAccount/Personal';
import Financial from 'jsx/components/CompleteAccount/Financial';

import AccountConfirmation from 'jsx/components/Shared/AccountConfirmation';
import { memo, useCallback, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import {
  onAddFunds,
  onAddOccupationalGoal,
  onAddVPSGoal,
  onClearSignupProcess,
  onGetAccountProfile,
  onSubmitAccountSetup
} from 'redux/actions';
import {
  onGetCities,
  onGetCountries,
  onGetOccupations,
  onGetSourcesOfIncome,
  onGetStates
} from 'redux/reducers/sharedStateData';
import { mutualAccountForm } from 'validate/mutual-account/form';
import Address from 'jsx/components/CompleteAccount/Address';
import Account from 'jsx/components/CompleteAccount/Account';
import { toast, ToastContainer } from 'react-toastify';
import { getDateYMDFormat } from 'utils/getDateDMYFormat';
import { useLocation } from 'react-router-dom';
import { toBase64 } from 'utils/convertToBase64';
import { useAppLoading } from 'hooks/appLoading';
import Loader from 'jsx/components/Shared/Loader';
const MutualFundAccount = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state }: any = useLocation();
  const dispatch = useAppDispatch();
  const [images, setImages] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [displayAccountConfirmation, setDisplayAccountConfirmation] =
    useState(false);
  const loading = useAppLoading('auth');
  const { cities, countries, occupations, sourcesOfIncome, states } =
    useAppSelector(state => state.sharedData);
  const { msg, user, userAccountData } = useAppSelector(state => state.auth);
  const notify = useCallback(
    () =>
      msg?.length &&
      toast.success(msg, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => dispatch(onClearSignupProcess()),
        onOpen: () => setDisplayAccountConfirmation(true),
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, msg]
  );
  useEffect(() => {
    notify();
  }, [notify]);
  const getInitialData = useCallback(() => {
    dispatch(onGetCountries());
    dispatch(onGetOccupations());
    dispatch(onGetSourcesOfIncome());
    dispatch(onGetAccountProfile());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const getStates = (id: number) => {
    if (id) {
      dispatch(onGetStates(id));
    }
  };

  useEffect(() => {
    if (userAccountData.addressValues.country) {
      getStates(userAccountData.addressValues.country);
    }
    if (
      userAccountData.addressValues.states &&
      parseInt(formik.values.country)
    ) {
      dispatch(
        onGetCities(
          parseInt(formik.values.country),
          userAccountData.addressValues.states
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountData.addressValues]);
  const getCities = (stateId: number) => {
    if (formik.values.country && stateId) {
      dispatch(onGetCities(parseInt(formik.values.country), stateId));
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Religion: 'NA',
      account_type: 1,
      ...userAccountData.personalValues,
      ...userAccountData.cnicValues,
      ...userAccountData.addressValues,
      ...userAccountData.financialValues,
      ...userAccountData.accountValues,
      PreferredContactNumber: user?.phone,
      dob: getDateYMDFormat(userAccountData.personalValues.dob),
      email: user?.email,
      father_name: user?.user_name?.split(' ')[1],
      isOtherCity: false,
      isOtherCountry: false,
      isOtherNativeCountry: false,
      isOtherResidentCountry: false,
      isOtherState: false,
      name: user?.user_name?.split(' ')[0]
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: async (values: any) => {
      if (!images.length && !userAccountData.cnicValues.cnic_front_image) {
        toast.error('images are required');
        return;
      }
      const formData = new FormData();
      for (const key in values) {
        // @ts-ignore
        formData.append(key, values[key]);
      }
      const CNICFrontImage = images.length
        ? await toBase64(images[0])
        : userAccountData.cnicValues.cnic_front_image;
      const CNICBackImage = images.length
        ? await toBase64(images[1])
        : userAccountData.cnicValues.cnic_back_image;
      const Data = {
        address: values.primary_address,
        annual_income: values.AnnualIncome,
        beneficial_owner: values.beneficial_owner === 'yes' ? true : false,
        city: values.city,
        cnic_back_image: CNICBackImage,
        cnic_expiry_date: values.cnic_expiry_date,
        cnic_front_image: CNICFrontImage,
        cnic_name: values.name,
        cnic_number: values.cnic_number,
        country: values.country,
        date_of_birth: values.date_of_birth,
        first_name: values.name,
        first_nationality: values.Nationality,
        gender: values.gender === '1' ? true : false,
        income_source_id: values.income_source_id,
        is_usa_resident: values.USAPerson === 'yes' ? true : false,
        last_name: values.father_name,
        marital_status: values.marital_status === '17' ? true : false,
        native_country: values.native_country,
        occupation_id: values.occupation_id,
        office_number: `92${values.OfficeNumber}`,
        other_city: values.other_city,
        other_country: values.other_country,
        other_native_country: values.other_native_country,
        other_resident_country: values.other_resident_country,
        other_state: values.other_state,
        phone_number: `92${values.phone}`,
        primary_address: values.primary_address,
        resident_country: values.resident_country,
        second_nationality: values.Nationality2,
        send_account_statement:
          values.send_account_statement === 'yes' ? true : false,
        states: values.states,
        tax_residence: values.tax_residence,
        zakat_deduction: values.zakat_deduction === 'yes' ? true : false
      };
      if (state) {
        if (state.isEmployer) {
          dispatch(onAddOccupationalGoal(state.goalDetails));
        } else {
          dispatch(onAddVPSGoal(state.goalDetails));
        }
        dispatch(onAddFunds(state.funds));
      }
      dispatch(onSubmitAccountSetup(Data));
    },
    validationSchema: mutualAccountForm
  });

  return (
    <>
      <ToastContainer />
      {displayAccountConfirmation && (
        <AccountConfirmation
          setDisplayAccountConfirmation={setDisplayAccountConfirmation}
        />
      )}
      {state && <Alert variant={'danger'}>Please complete your account.</Alert>}
      {loading ? (
        <div className='text-center'>
          <Loader width={200} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <HeaderText />
          <Personal countries={countries} formik={formik} />
          {!!formik.getFieldProps('Nationality').value && (
            <Cnic
              formik={formik}
              imageError={imageError}
              setImageError={setImageError}
              setImages={setImages}
            />
          )}
          {!!formik.getFieldProps('cnic_number').value && (
            <Financial
              formik={formik}
              occupations={occupations}
              sourcesOfIncome={sourcesOfIncome}
            />
          )}
          {!!formik.getFieldProps('AnnualIncome').value && (
            <Address
              cities={cities}
              countries={countries}
              formik={formik}
              getCities={getCities}
              getStates={getStates}
              states={states}
            />
          )}
          {!!formik.getFieldProps('city').value && (
            <>
              <Account formik={formik} />
              <div className='text-right'>
                <Button type='submit'>Confirm</Button>
              </div>
            </>
          )}
        </form>
      )}
    </>
  );
};

export default memo(MutualFundAccount);
