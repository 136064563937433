import { Row, Button } from 'react-bootstrap';
import { memo } from 'react';
import SelectField from '../Shared/FormikSelectField';
import InputField from '../Shared/FormikInputField';
import { useFormik } from 'formik';
import { ChecqueCollectionFormSchema } from 'validate/paymnet-options/form';

const ChecqueCollectionForm = () => {
  const paymentOptionsFormIk = useFormik({
    initialValues: {
      address: '',
      bank: '',
      email: '',
      instruction: '',
      name: '',
      phoneNumber: ''
    },
    onSubmit: (values: FormValues) => {
      // eslint-disable-next-line no-console
      console.log(values);
    },
    validationSchema: ChecqueCollectionFormSchema
  });
  return (
    <>
      <form onSubmit={paymentOptionsFormIk.handleSubmit}>
        <Row>
          <SelectField
            col={4}
            fieldName='bank'
            fields={banks}
            formik={paymentOptionsFormIk}
            label='Select Bank'
            required={true}
          />
          <InputField
            required
            col={4}
            formik={paymentOptionsFormIk}
            label='Name'
            name='name'
            placeholder='Enter Name'
          />
          <InputField
            required
            col={4}
            formik={paymentOptionsFormIk}
            label='Phone Number'
            name='phoneNumber'
            placeholder='000000000'
          />
          <InputField
            required
            col={4}
            formik={paymentOptionsFormIk}
            label='Email'
            name='email'
            placeholder='Enter Email'
          />
          <InputField
            required
            col={4}
            formik={paymentOptionsFormIk}
            label='Address'
            name='address'
            placeholder='Enter Address'
          />
          <InputField
            required
            col={4}
            formik={paymentOptionsFormIk}
            label='Instruction For Rider'
            name='instruction'
            placeholder='Enter Instruction For Rider'
            type='textarea'
          />
        </Row>
        <div className='text-right'>
          <Button type='submit'>Process Payment</Button>
        </div>
      </form>
    </>
  );
};
export default memo(ChecqueCollectionForm);

const banks = [
  { label: 'bank 1', value: '1' },
  { label: 'bank 2', value: '2' },
  { label: 'bank 3', value: '3' },
  { label: 'bank 4', value: '4' }
];

interface FormValues {
  address: string;
  bank: string;
  email: string;
  instruction: string;
  name: string;
  phoneNumber: string;
}
