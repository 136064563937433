import { ThemeContext } from 'context/ThemeContext';
import 'css/index.css';
import 'css/step.css';
import { AnimatePresence, motion } from 'framer-motion';
import CompanyProfile from 'jsx/components/Thematic/CompanyProfile';
import Auth from 'jsx/containers/Auth';
import MutualFundAccount from 'jsx/containers/CompleteAccountSetup';
import Dashboard from 'jsx/containers/Dashboard';
import FundDetails from 'jsx/containers/FundDetails';
import FundsOverview from 'jsx/containers/FundsOverview';
import GoalsOverview from 'jsx/containers/GoalsOverview';
import Learn from 'jsx/containers/Learn';
import PaymentOptions from 'jsx/containers/PaymentOptions';
import Pension from 'jsx/containers/Pension';
import Portfolio from 'jsx/containers/Portfolio';
import RiskProfile from 'jsx/containers/RiskProfile';
import Thematic from 'jsx/containers/Thematic';
import TransactionHistory from 'jsx/containers/TransactionHistory';
import Footer from 'jsx/layouts/Footer';
import Header from 'jsx/layouts/Header';
import LinkAccounts from 'jsx/pages/LinkAccounts';
import { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { links } from 'static/links';
import EmployerScreen from '../pages/EmployerScreen';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './PrivateRoute';
import Scroller from './Scroller';
import AccountConfirmation from 'jsx/pages/AccountConfirmation/AccountConfirmation';
import { useAppSelector } from 'hooks/reduxHooks';
import ConfirmationModal from 'jsx/components/Shared/ConfirmationModal';
import MyAccounts from 'jsx/containers/MyAccounts';
import RiskScore from 'jsx/containers/RiskScore';
import EmployerDashboard from 'jsx/containers/EmployerDashboard';

const pageVariants = {
  in: { opacity: 1 },
  initial: { opacity: 0 },
  transition: { duration: 0.5 }
};

const Routes = () => {
  const { menuToggle } = useContext(ThemeContext);
  const { IsAuthorized, isAuth, roleType } = useAppSelector(
    state => state.auth
  );

  const location = useLocation();
  const routes = [
    { component: MyAccounts, exact: true, url: links.myAccounts },
    { component: Dashboard, exact: true, url: links.home },
    { component: Pension, exact: true, url: links.pension },
    { component: Portfolio, exact: true, url: links.portfolio },
    { component: RiskProfile, exact: true, url: links.riskProfile },
    { component: RiskScore, exact: true, url: links.riskScore },
    {
      component: TransactionHistory,
      exact: true,
      url: links.transactionHistory
    },
    { component: LinkAccounts, exact: true, url: links.linkAccounts },
    { component: FundsOverview, exact: true, url: links.fundsOverview },
    { component: GoalsOverview, exact: true, url: links.overview },
    { component: GoalsOverview, exact: true, url: links.pensionAccount },
    { component: GoalsOverview, exact: true, url: links.taxSavingAccount },
    { component: Thematic, exact: true, url: links.thematic },
    { component: FundDetails, exact: true, url: links.fundDetails },
    { component: Learn, exact: true, url: links.learn },
    { component: MutualFundAccount, exact: true, url: links.mutualFundAccount },
    { component: PaymentOptions, exact: true, url: links.paymentOptions },
    { component: CompanyProfile, exact: true, url: links.companyProfile },
    { component: EmployerScreen, exact: true, url: links.employerScreen },
    { component: EmployerDashboard, exact: false, url: links.employer }
  ];

  return (
    <ErrorBoundary>
      <Scroller>
        <AnimatePresence>
          <Switch key={location.pathname} location={location}>
            <Route component={Auth} path={links.auth} />
            <Route
              exact
              path={links.confirmation}
              render={() => <AccountConfirmation />}
            />
            <motion.div
              animate='in'
              className={`show  ${menuToggle ? 'menu-toggle' : ''}`}
              exit='out'
              id='main-wrapper'
              initial='initial'
              variants={pageVariants}
            >
              <Header isAuth={isAuth} roleType={roleType} />
              {!IsAuthorized && isAuth && <ConfirmationModal />}

              <div className='content-body'>
                <div
                  className='container-fluid'
                  style={{ minHeight: window.screen.height - 60 }}
                >
                  {routes.map((data, i) => (
                    <PrivateRoute
                      key={i}
                      component={data.component}
                      exact={data.exact}
                      path={data.url}
                    />
                  ))}
                </div>
              </div>
              <Footer />
            </motion.div>
          </Switch>
        </AnimatePresence>
      </Scroller>
    </ErrorBoundary>
  );
};

export default Routes;
