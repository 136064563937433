import { useAppSelector } from 'hooks/reduxHooks';
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Loader from '../Shared/Loader';

const gainLossValue = (
  loading: boolean,
  num?: string,
  staticColor?: string
) => {
  if (!num) return;
  let color = '#3d4465';
  const numbr = parseInt(num) === -0 ? 0 : parseInt(num);

  if (numbr >= 1) color = 'green';
  else if (numbr <= -1) color = 'red';
  return (
    <h4 style={{ color: staticColor ?? color }}>
      {loading ? <Loader /> : <>{numbr.toLocaleString()} PKR</>}
    </h4>
  );
};

const HomeTopGrid = () => {
  const { totalGainLoss, totalGainLossLoading } = useAppSelector(
    state => state.dashboard
  );
  const totalValue = gainLossValue(
    totalGainLossLoading,
    `${totalGainLoss[0]?.total_value}`,
    '#3d4465'
  );
  const totalGainLossValue = gainLossValue(
    totalGainLossLoading,
    `${totalGainLoss[0]?.total_gain_loss}`
  );
  const todayGainLossValue = gainLossValue(
    totalGainLossLoading,
    `${totalGainLoss[0]?.gain_loss}`
  );

  return (
    <>
      <Card className='pb-0' style={{ height: 'auto' }}>
        <Card.Body>
          <Row>
            <Col lg={4}>
              <h3>Total Value</h3>
              {totalValue}
            </Col>
            <Col lg={4}>
              <h3>Gain/Loss Today</h3>
              {todayGainLossValue}
            </Col>
            <Col lg={4}>
              <h3>Gain/Loss Total</h3>
              {totalGainLossValue}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default memo(HomeTopGrid);
