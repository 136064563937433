import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import AppSelect from 'jsx/components/Shared/AppSelect';
import EmployesTable from './EmployesTable';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';
import {
  onClearEmployerMsg,
  onGetEmployeeListForEmployer,
  onGetEmployerContribution,
  onImportExcelFile
} from 'redux/reducers/employer';
import Loader from 'jsx/components/Shared/Loader';
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';

export default function DashboardEmployees() {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const {
    employerContribution,
    employerEmployees,
    employerEmployeesTotalRecords,
    msg
  } = useAppSelector(state => state.employer);

  const loading = useAppLoading('employer');

  const getInitialData = useCallback(() => {
    dispatch(onGetEmployerContribution());
    dispatch(onGetEmployeeListForEmployer(currentPage));
  }, [dispatch, currentPage]);

  const onDrop = useCallback(
    (acceptedFiles: Files) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formData: any = new FormData();
        formData.append('file', file as File);
        dispatch(onImportExcelFile(formData));
        return;
      }
      toast.error('Please select only one file');
    },
    [dispatch]
  );

  const notify = useCallback(
    () =>
      msg?.length &&
      toast.success(msg, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => dispatch(onClearEmployerMsg()),
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      }),
    [dispatch, msg]
  );

  useEffect(() => {
    notify();
  }, [notify]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept:
      'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop
  });

  return (
    <>
      <ToastContainer />
      <div>
        {loading ? (
          <div className='text-center'>
            <Loader width={200} />
          </div>
        ) : (
          <>
            <Card className='pb-2 ' style={{ height: 'auto' }}>
              <Card.Header className='d-block'>
                <Row>
                  <Col className='custom-padding' lg={6} sm={12}>
                    <p className='mb-0 text-black text-capitalize '>
                      {`${
                        employerContribution.length
                          ? employerContribution[0].employeer_name
                          : 'no company'
                      }/Employees`}
                    </p>
                    <h2 className='mb-0 text-primary '>Employees</h2>
                  </Col>
                  <Col className=' employeesHeadOptions' lg={6} sm={12}>
                    <AppSelect
                      col={4}
                      fieldName='employees'
                      fields={[{ label: 'john Doe', value: '1' }]}
                      placeholder='Search'
                    />
                    <AppSelect
                      col={4}
                      fieldName='employeeStatus'
                      fields={[
                        { label: 'Show All', value: '1' },
                        { label: 'Active', value: '2' },
                        { label: 'Terminated', value: '3' },
                        { label: 'Not Participating', value: '4' },
                        { label: 'Not Eligible', value: '5' }
                      ]}
                      placeholder='Select Status'
                      value={'1'}
                    />

                    <Col lg={4}>
                      <div className='form-group'>
                        <div {...getRootProps()} className=''>
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <Button className='rit cutomMarginBottom w-100 h-38 p-0'>
                              Import
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className='mt-2'>
                  <Col md={12} sm={12}>
                    <EmployesTable
                      employerEmployees={employerEmployees}
                      employerEmployeesTotalRecords={
                        employerEmployeesTotalRecords
                      }
                      setCurrentPage={setCurrentPage}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Files = Array<Record<string, any>>;
