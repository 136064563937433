import { Link } from 'react-router-dom';
import { memo } from 'react';

import { links } from 'static/links';
import { onLogout } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import DropDown from 'jsx/components/Shared/DropDown';

const HeaderRight = () => {
  const dispatch = useAppDispatch();
  const logoutHandle = () => dispatch(onLogout());
  const {  user } = useAppSelector(state => state.auth);

  return (
    <ul className='navbar-nav header-right'>
      <DropDown subTitle={user?.phone} title={user?.user_name ?? ''}>
        <>
          <Link className='dropdown-item ptr ai-icon' to={links.riskProfile}>
            <svg
              className='text-primary'
              fill='none'
              height={18}
              id='icon-user1'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              viewBox='0 0 24 24'
              width={18}
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
              <circle cx={12} cy={7} r={4} />
            </svg>
            <span className='ml-2'>Risk Profile </span>
          </Link>
          <Link
            className='dropdown-item ptr ai-icon'
            to={links.transactionHistory}
          >
            <span className='fa fa-list fa-lg' />
            <span className='ml-2'> Transaction History </span>
          </Link>
          <Link className='dropdown-item ptr ai-icon' to={links.linkAccounts}>
            <span className='fa fa-credit-card fa-lg' />
            <span className='ml-2'> Link Accounts </span>
          </Link>

          <span
            className='dropdown-item ai-icon'
            onClick={() => logoutHandle()}
          >
            <svg
              className='text-danger'
              fill='none'
              height={18}
              id='icon-logout'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              viewBox='0 0 24 24'
              width={18}
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
              <polyline points='16 17 21 12 16 7' />
              <line x1={21} x2={9} y1={12} y2={12} />
            </svg>
            <span className='ml-2 ptr'>Logout </span>
          </span>
        </>
      </DropDown>
    </ul>
  );
};

export default memo(HeaderRight);
