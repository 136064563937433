import { useFormik } from 'formik';
import { memo } from 'react';
import { Button, Row } from 'react-bootstrap';
import { OnlineBankFormSchema } from 'validate/paymnet-options/bank-transfer';
import InputField from '../Shared/FormikInputField';
import SelectField from '../Shared/FormikSelectField';
import { paymentOptionProps } from './PaymentOptionsConent';

const OnlineBankForm = ({
  banksList,
  submitBankTransfer
}: paymentOptionProps) => {
  const OnlineBankFormIk = useFormik({
    initialValues: { accountNumber: '', accountTitle: '', bank: '' },
    onSubmit: (values: OnlineBankFormValues) => {
      // eslint-disable-next-line no-console

      submitBankTransfer({
        AccNumber: values.accountNumber,
        AccTitle: values.accountTitle,
        BankId: Number(values.bank)
      });
    },
    validationSchema: OnlineBankFormSchema
  });

  return (
    <>
      <form onSubmit={OnlineBankFormIk.handleSubmit}>
        <Row>
          <SelectField
            col={4}
            fieldName='bank'
            fields={banksList}
            formik={OnlineBankFormIk}
            label='Select Bank'
            required={true}
          />
          <InputField
            required
            col={4}
            formik={OnlineBankFormIk}
            label='Account Title'
            name='accountTitle'
            placeholder='Enter Account Title'
          />
          <InputField
            required
            col={4}
            formik={OnlineBankFormIk}
            label='Bank Account Number'
            name='accountNumber'
            placeholder='Enter Account Number'
          />
        </Row>
        <div className='text-right'>
          <Button type='submit'>Process Payment</Button>
        </div>
      </form>
    </>
  );
};
export default memo(OnlineBankForm);

interface OnlineBankFormValues {
  accountNumber: string;
  accountTitle: string;
  bank: string;
}
