import { useAppSelector } from 'hooks/reduxHooks';
import { Col, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InvestmentProps } from './HomeExposure';

export default function FundDetailsModal({
  selectedInvestment,
  setSelectedInvestment
}: FundDetailsModalProps) {
  const { InvestmentList } = useAppSelector(state => state.pieChartData);
  return (
    <>
      <Modal
        centered
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => setSelectedInvestment(null)}
        show={selectedInvestment}
      >
        <Modal.Header closeButton>
          <h5 style={{ fontSize: '20px', margin: '0' }}>
            {selectedInvestment?.name}
          </h5>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <ul className='w-100 mt-3'>
            {InvestmentList?.filter(
              el => el.catergory_name === selectedInvestment?.name.slice(0, -1)
            )?.map((item, index) => (
              <>
                <li key={index}>
                  <Row className='px-5 '>
                    <Col xs={8}>
                      <Row>
                        <Link target='' to={`fund/1`}>
                          <Col xs={12}>
                            <h5 style={{ fontSize: '20px', margin: '0' }}>
                              {item.symbol}
                            </h5>
                          </Col>
                          <Col style={{ marginTop: '-1%' }} xs={12}>
                            <p
                              style={{
                                color:
                                  item.catergory_name === 'Stock'
                                    ? '#f0aa2d'
                                    : '#4012A0'
                              }}
                            >
                              {item.fund_name}
                            </p>
                          </Col>
                        </Link>
                      </Row>
                    </Col>
                    <Col style={{ textAlign: 'end' }} xs={4}>
                      <h5>{item.percentage}%</h5>
                    </Col>
                  </Row>
                </li>
              </>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}

interface FundDetailsModalProps {
  selectedInvestment: InvestmentProps | null;
  setSelectedInvestment: (selectedInvestment: InvestmentProps | null) => void;
}
