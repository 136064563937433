import 'bootstrap-daterangepicker/daterangepicker.css';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Loader from 'jsx/components/Shared/Loader';
import { useCallback, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { onGetFundOverview } from 'redux/reducers/fundsSnapshot';
import { links } from 'static/links';
import FundChart from './FundChart';
import FundInfo from './FundInfo';
import FundObjective from './FundObjective';
import KeyFacts from './KeyFacts';
import Performance from './Performance';
import PortfolioComposition from './PortfolioComposition';
import RiskMeter from './RiskMeter';
import './style.css';
import YieldTable from './YieldTable';

const FundDetails = () => {
  const dispatch = useAppDispatch();
  const { fundsOverview } = useAppSelector(state => state.fundsSnapshot);

  const overViewData = fundsOverview ? fundsOverview[0] : {};

  const { id } = useParams();

  const loading = useAppLoading('fundsSnapshot');

  const getInitialData = useCallback(() => {
    dispatch(onGetFundOverview(id));
  }, [dispatch, id]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  if (loading) return <Loader />;

  return (
    <>
      <div className='page-titles mt-1'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item active'>
            <Link to={links.fundsOverview}>Funds</Link>
          </li>
          <li className='breadcrumb-item'>
            <span>Fund Details</span>
          </li>
        </ol>
      </div>

      <div className='d-flex flex-wrap mb-3'>
        <h4 className='fs-24 text-black mr-auto font-w600 mb-3'>
          Fund Details
        </h4>
      </div>
      <div className='row'>
        <div className='col-xl-3 col-xxl-4'>
          <FundInfo data={overViewData} />
          <RiskMeter />
          <FundObjective objectiveData={overViewData} />
          <YieldTable />
        </div>
        <div className='col-xl-9 col-xxl-8'>
          <FundChart />
          <KeyFacts />

          <Row>
            <Col sm={7}>
              <Performance />
            </Col>
            <Col sm={5}>
              <PortfolioComposition />{' '}
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Card className='pb-2' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h4 className='mb-0 green fs-16'>
                    Important fund performance information
                  </h4>
                </Card.Header>
                <Card.Body className='pb-3'>
                  <i>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </i>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FundDetails;
