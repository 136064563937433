import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Button, Card } from 'react-bootstrap';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import Statistics from '../Shared/Statistics';
import { links } from 'static/links';

const FundChart = () => {
  const { push } = useHistory();
  return (
    <Card style={{ height: 'auto' }}>
      <Card.Header className='pb-0 d-block d-sm-flex flex-wrap border-0 align-items-center'>
        <div className='mr-auto mb-3'>
          <h4 className='fs-20 text-black'>Fund Chart</h4>
          <p className='mb-0 fs-12'>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
        <Button
          className='btn btn-primary ptr mr-3 mb-3'
          onClick={() => push(links.mutualFundAccount)}
        >
          Invest Now
        </Button>
        <span className='btn btn-primary  ptr light mr-3  mb-3'>
          <i className='las la-download scale5 mr-2' />
          Download Report
        </span>

        <div className='input-group detault-daterange mr-3  mb-3 coinDetails-datepiker'>
          <span className='input-group-text'>
            <i className='las la-calendar' />
          </span>
          <DateRangePicker>
            <input className='form-control' type='text' />
          </DateRangePicker>
        </div>
      </Card.Header>
      <Card.Body className='pb-0 pt-sm-3 pt-0'>
        <div className='row sp20 mb-4 align-items-center'>
          <div className='col-lg-8 col-xxl-12 d-flex flex-wrap justify-content-between align-items-center'>
            <div className='px-2 info-group'>
              <p className='fs-18 mb-1'>Nav as of date: 13 Aug 2021</p>
              <div className='d-flex align-end'>
                <h2 className='fs-28 font-w600 text-black mr-3'>103.57</h2>
                <h3 className='fs-20 font-w600 text-success'>
                  1.64% <i className='fa fa-caret-up' />
                </h3>
              </div>
            </div>

            <div className='px-2 info-group'>
              <p className='fs-18 mb-4'>YTD Total Return</p>
              <h2 className='fs-20 font-w600 text-success'>
                7.64% <i className='fa fa-caret-up' />
              </h2>
            </div>
          </div>
          <div className='d-flex col-lg-4 col-xxl-12 align-items-center mt-sm-0 mt-3 justify-content-center'>
            <div className='px-2 info-group'>
              <p className='fs-18 mb-1'>Absolute Return (Annualized)</p>
              <div className='d-flex justify-content-center'>
                <div className='mr-3'>
                  <div>1 Year</div>
                  <span className='text-danger'>
                    3.64% <i className='fa fa-caret-down' />
                  </span>
                </div>
                <div className='mr-3'>
                  <div>3 Years</div>
                  <span className='text-success'>
                    1.97% <i className='fa fa-caret-up' />
                  </span>
                </div>
                <div className='mr-3'>
                  <div>5 Years</div>
                  <span className='text-success'>
                    4.82% <i className='fa fa-caret-up' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bar-chart' id='chart'>
          <Statistics series={series} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(FundChart);

const series = [
  { data: [50, 50, 35, 50, 40, 40, 55, 45, 35, 50, 55], name: 'series1' }
];
