import { memo } from 'react';
import { Card } from 'react-bootstrap';
import { fundsOverviewProps } from './FundInfo';

const FundObjective = ({
  objectiveData
}: {
  objectiveData: fundsOverviewProps;
}) => {
  const { objective_ } = objectiveData || {};

  return (
    <Card className='pb-2' style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='mb-0 text-black fs-20'>Fund Objective</h4>
      </Card.Header>
      <Card.Body className='pb-0'>{objective_}</Card.Body>
    </Card>
  );
};

export default memo(FundObjective);
