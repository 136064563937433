import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import WelcomeScreen from 'jsx/components/Dashboard/WelcomeScreen';
import { memo, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { onGetGoals } from 'redux/actions';
import SideBar from './SideBar';
import AccountsContainer from 'jsx/components/Dashboard/AccountsContainer';
import { useLocation } from 'react-router-dom';

const MyAccounts = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state }: any = useLocation();
  const [title, setTitle] = useState(
    state ? state.title : 'Occupation Pension'
  );
  const dispatch = useAppDispatch();
  const { goals, user } = useAppSelector(state => state.auth);
  const { completedGoals } = useAppSelector(state => state.dashboard);

  const getInitialData = useCallback(() => {
    dispatch(onGetGoals());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  return (
    <>
      {parseInt(goals) <= 0 && completedGoals.length === 0 ? (
        <WelcomeScreen userName={user?.user_name ?? ''} />
      ) : (
        <>
          <Row>
            <Col lg={3}>
              <SideBar />
            </Col>
            <Col lg={9}>
              <AccountsContainer
                completedGoals={completedGoals}
                setTitle={setTitle}
                title={title}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(MyAccounts);
