import { Modal } from 'react-bootstrap';
import { useAppDispatch } from 'hooks/reduxHooks';
import { onLogout } from 'redux/actions';

const ConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const logoutHandle = () => dispatch(onLogout());

  return (
    <Modal
      centered
      aria-labelledby='contained-modal-title-vcenter'
      show={true}
      size='lg'
    >
      <Modal.Body>
        <div className='ConfirmationContentDiv'>
          <span className='ConfirmationContentIcon'>
            <i
              aria-hidden='true'
              className='fa fa-exclamation-triangle'
              style={{ color: 'red' }}
            ></i>
          </span>
          <span>
            <h3>Kindly confirm your account in order to use Mahaana.</h3>
          </span>
          <span className='ConfirmationContentButton'>
            <button
              className='btn btn-primary btn-block'
              onClick={logoutHandle}
            >
              Logout
            </button>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
