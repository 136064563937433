import * as Yup from 'yup';
export const VPSGoal = Yup.object({
  initial_amount: Yup.number()
    .required('Initial Amount is required.')
    .typeError('Only numbers allowed'),
  investment_type: Yup.string().required('Investment type is required'),
  monthly_saving: Yup.number().when('plan_type', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    is: (val: any) => val && !!val.length,
    then: Yup.number()
      .required('Monthly saving is required.')
      .typeError('Only numbers are required ')
  }),
  plan_type: Yup.string().required('Plan Type is required.')
});
