/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppLoading } from 'hooks/appLoading';
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { City, Country, State } from 'redux/reducers/sharedStateData';
import AppSelect from '../Shared/AppSelect';
import { FadeIn } from '../Shared/FadeIn';
import InputField from '../Shared/FormikInputField';

const Address = ({
  cities,
  countries,
  formik,
  getCities,
  getStates,
  states
}: AddressProps) => {
  const loading = useAppLoading('sharedStateData');
  return (
    <FadeIn>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12}>
              <h3 className='text-center text-secondary'>Address Details</h3>
            </Col>
            <AppSelect
              fieldSelect
              required
              col={4}
              disabled={false}
              fieldName='tax_residence'
              fields={countries}
              formik={formik}
              label='Country Tax Residence'
              onChange={val => {
                formik.setFieldValue('tax_residence', val);
              }}
              placeholder='Select Tax Residence Country'
              value={Number(formik.values.tax_residence)}
            />
            <InputField
              required
              col={4}
              formik={formik}
              label='Primary Address'
              name='primary_address'
              placeholder='Enter Primary Address'
            />
            <AppSelect
              fieldSelect
              required
              col={4}
              disabled={false}
              fieldName='country'
              fields={countries}
              formik={formik}
              label='Country'
              onChange={(val, option) => {
                formik.setFieldValue('country', val);
                formik.setFieldValue('states', null);
                formik.setFieldValue('city', null);
                getStates(parseInt(val));
                formik.setFieldValue(
                  'isOtherCountry',
                  option.label === 'Other'
                );
              }}
              placeholder='Select Country'
              value={Number(formik.values.country)}
            />
            {countries.find(
              el => Number(formik.values.country) === Number(el.value)
            )?.label === 'Other' && (
              <InputField
                required
                col={4}
                formik={formik}
                label='(Other Country)'
                name='other_country'
                placeholder=''
              />
            )}
            {formik.values.country && (
              <AppSelect
                fieldSelect
                required
                col={4}
                disabled={false}
                fieldName='states'
                fields={states}
                formik={formik}
                label='State'
                loading={loading}
                onChange={(val, option) => {
                  formik.setFieldValue('states', val);
                  formik.setFieldValue('city', null);
                  getCities(parseInt(val));
                  formik.setFieldValue(
                    'isOtherState',
                    option.label === 'Other'
                  );
                }}
                placeholder='Select State'
                value={Number(formik.values.states)}
              />
            )}

            {states.find(el => Number(formik.values.states) === el.id)
              ?.label === 'Other' && (
              <InputField
                required
                col={4}
                formik={formik}
                label='(Other State)'
                name='other_state'
                placeholder=''
              />
            )}
            {formik.values.states && (
              <AppSelect
                fieldSelect
                required
                col={4}
                disabled={false}
                fieldName='city'
                fields={cities}
                formik={formik}
                label='City'
                loading={loading}
                onChange={(val, option) => {
                  formik.setFieldValue('city', val);
                  formik.setFieldValue('isOtherCity', option.label === 'Other');
                }}
                placeholder='Select City'
                value={Number(formik.values.city)}
              />
            )}
            {cities.find(el => Number(formik.values.city) === el.id)?.label ===
              'Other' && (
              <InputField
                required
                col={4}
                formik={formik}
                label='(Other City)'
                name='other_city'
                placeholder=''
              />
            )}
          </Row>
        </Card.Body>
      </Card>
    </FadeIn>
  );
};

export default memo(Address);

interface AddressProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  countries: Array<Country>;
  cities: Array<City>;
  getStates: (countryId: number) => void;
  getCities: (stateId: number) => void;
  states: Array<State>;
}
