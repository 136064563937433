import { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import { links } from 'static/links';

const Header = ({
  isAuth,
  roleType
}: {
  isAuth: boolean;
  roleType: number;
}) => {
  const { push } = useHistory();

  useEffect(() => {
    if (!isAuth) push(links.login);
  }, [isAuth, push]);
  if (!isAuth) return null;

  return (
    <div className='header'>
      <div className='header-content'>
        <nav className='navbar navbar-expand'>
          <div className='collapse navbar-collapse '>
            <HeaderLeft />
            <div className='widht-100'>
              {isAuth && roleType === 1 && (
                <NavLink
                  activeClassName='active-link'
                  className='employer-link ptr ai-icon border-0'
                  to={links.employerDashboard}
                >
                  <span className='ml-2'> Employer Dashboard </span>
                </NavLink>
              )}
            </div>
            <HeaderRight />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
