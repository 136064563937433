import { memo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Filters, Funds, Fund } from 'redux/reducers/funds';
import { getDateDMYFormat } from 'utils/getDateDMYFormat';
import Loader from '../Shared/Loader';
import TableHead from './TableHead';
import Topbar from './Topbar';

const FundsOverview = ({
  filters,
  funds,
  isLoading,
  setSelectedFund
}: FundsOverviewProps) => {
  const [activeName, setActiveName] = useState('Newest');

  return (
    <>
      <Topbar
        activeName={activeName}
        filters={filters}
        isLoading={isLoading}
        setActiveName={setActiveName}
      />

      <div className='row'>
        <div className='col-xl-12'>
          <div className='table-responsive table-hover fs-14 '>
            <div className='dataTables_wrapper no-footer' id='example6_wrapper'>
              <table
                aria-describedby='example6_info'
                className='table display mb-4 dataTablesCard font-w600 short-one border-no card-table text-black dataTable no-footer'
                id='marketCapital'
                role='grid'
              >
                <TableHead />

                <tbody>
                  {funds.map((val, index) => (
                    <tr key={index} className='odd ptr' role='row'>
                      <td className='sorting_1'>
                        <span className='rank-ic fs-20'>{val.fund_symbol}</span>
                      </td>

                      {/* NAME  */}
                      <td className='wspace-no'>
                        <span className='text-black'>{val.name}</span>
                      </td>

                      {/* NAV  */}
                      <td>{val.net_asset_value.toFixed(2)}</td>

                      {/* NAV %  */}
                      <td>
                        <span
                          className={textClass(
                            val.percent_change_net_asset_value
                          )}
                        >
                          {val.percent_change_net_asset_value.toFixed(2)}%
                        </span>
                      </td>

                      {/* 1year  */}

                      <td>
                        <span
                          className={textClass(
                            val.absolute_return_annualized_1_year
                          )}
                        >
                          {val.absolute_return_annualized_1_year.toFixed(2)}
                        </span>
                      </td>

                      {/* 3Year  */}
                      <td>
                        <span
                          className={textClass(
                            val.absolute_return_annualized_3_year
                          )}
                        >
                          {val.absolute_return_annualized_3_year.toFixed(2)}
                        </span>
                      </td>
                      {/* 5Year  */}
                      <td>
                        <span
                          className={textClass(
                            val.absolute_return_annualized_5_year
                          )}
                        >
                          {val.absolute_return_annualized_5_year.toFixed(2)}
                        </span>
                      </td>

                      {/* since Inception  */}
                      <td>
                        <span
                          className={textClass(
                            val.absolute_return_annualized_since_inception
                          )}
                        >
                          {val.absolute_return_annualized_since_inception.toFixed(
                            2
                          )}
                        </span>
                        <br />
                        <span
                          style={{
                            color: 'gray',
                            fontSize: '12px',
                            // @ts-ignore
                            fontWeight: '300'
                          }}
                        >
                          {getDateDMYFormat(val.launch_date, '-')}
                        </span>
                      </td>

                      {/* Expense Ratio  */}
                      <td>{index + 1}%</td>

                      {/* Asset Class  */}
                      <td>ASD</td>

                      <td>
                        <Button
                          disabled={isLoading}
                          onClick={e => setSelectedFund(val)}
                          style={{ minWidth: 100 }}
                        >
                          Invest Now
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!funds.length && !isLoading && (
                <h2 className='text-center'>No Data Found</h2>
              )}
              {isLoading && !funds.length && (
                <div className='text-center'>
                  <Loader width={50} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(FundsOverview);

const textClass = (num: number) => {
  if (num > 0) return 'text-primary';
  if (num < 0) return 'text-danger';
  return '';
};

interface FundsOverviewProps {
  filters: Filters;
  funds: Funds;
  isLoading: boolean;
  setSelectedFund: (fund: Fund) => void;
}
