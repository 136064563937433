import { useAppSelector } from 'hooks/reduxHooks';
import { memo, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CompletedGoalsProps } from 'redux/actions';
import { links } from 'static/links';
import { Compound } from '../Portfolio/Steps/Compound';
import { intrestDataProps } from '../Portfolio/Steps/ConservativeStep/ProgressBarChart';
import Statistics from '../Shared/Statistics';
import FundDetailsModal from './FundDetailsModal';
import { InvestmentProps } from './HomeExposure';

const OccupationalAccountDetails = ({
  completedGoals
}: OccupationalAccountDetailsProps) => {
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentProps | null>(null);
  const [intrestData, setIntrestData] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intrestDataProps | Record<string, any>
  >({});
  const { riskPortfolioData } = useAppSelector(state => state.pieChartData);
  const { availableGoals } = useAppSelector(state => state.dashboard);

  const [age, setAge] = useState(0);
  const { push } = useHistory();
  const isOccupationalPAccountOpen = !!completedGoals?.find(
    el => el.goal_type_id === 2
  );
  useEffect(() => {
    if (completedGoals?.length) {
      // eslint-disable-next-line array-callback-return
      completedGoals.map(el => {
        if (el.goal_type_id === 2) {
          setAge(el.age);
          handleIntrestCompute({
            age: el.age ? el.age : 0,
            initial_amount: 0,
            monthly_saving: el.monthly_amount ? el.monthly_amount : 0
          });
        }
      });
    }
  }, [completedGoals]);

  const handleIntrestCompute = (values: IntrestValuesProps) => {
    const age = 65 - values.age;
    const computedIntrest = Compound(
      values.initial_amount,
      values.monthly_saving,
      age < 1 ? 1 : age,
      0.096,
      12
    );
    setIntrestData(computedIntrest);
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const temp = [
    { data: intrestData.total, labels: range(age, 66), name: 'amount' }
  ];
  const handleOccupationalClick = () => {
    push(`${links.portfolio}?role=employer`);
    push({
      pathname: links.portfolio,
      search: '?role=employer',
      state: {
        goal_id: availableGoals.find(
          el =>
            el.goal_type_description ===
            'Enroll in an occupational pension scheme'
        )?.id
      }
    });
  };

  return (
    <>
      <Col lg={4}>
        <h3 className='mt-3'>Portfolio</h3>
        <ul className='widht-70'>
          {riskPortfolioData.map((item, index) => {
            return (
              <>
                <li
                  key={index}
                  className='HomeAccountList'
                  onClick={e => setSelectedInvestment(item)}
                >
                  <span>{item.name} </span>
                  <span className='float-right'> {item.value}% </span>
                </li>
              </>
            );
          })}
        </ul>
      </Col>
      <Col lg={8}>
        {isOccupationalPAccountOpen ? (
          <>
            <h3 className='text-center mt-3'>
              You will have{' '}
              <span className=' text-primary'>
                {parseInt(intrestData?.result as string).toLocaleString()}
              </span>{' '}
              PKR by age 65.
            </h3>
            <Statistics isDashboard={true} series={temp} />
          </>
        ) : (
          <div className='text-center'>
            <h5>You have not created occupational goal yet.</h5>
            <button
              className='btn mt-2 btn-primary '
              onClick={handleOccupationalClick}
            >
              Create Occupational Goal
              <span
                className='ml-2 fa fa-arrow-right'
                style={{ transform: 'rotate(310deg)' }}
              />
            </button>
          </div>
        )}
      </Col>
      <FundDetailsModal
        selectedInvestment={selectedInvestment}
        setSelectedInvestment={setSelectedInvestment}
      />
    </>
  );
};

export default memo(OccupationalAccountDetails);

interface OccupationalAccountDetailsProps {
  completedGoals: Array<CompletedGoalsProps>;
}
interface IntrestValuesProps {
  initial_amount: number;
  monthly_saving: string | number;
  age: number;
}
