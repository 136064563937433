import { Modal } from 'react-bootstrap';
const AccountConfirmation = ({
  setDisplayAccountConfirmation
}: AccountConfirmationProps) => {
  const handleModalClose = () => {
    setDisplayAccountConfirmation(false);
  };
  return (
    <Modal
      centered
      aria-labelledby='contained-modal-title-vcenter'
      onHide={handleModalClose}
      show={true}
      size='sm'
    >
      <Modal.Header closeButton>
        <div className='ConfirmationHeader'>
          <i className='fa fa-info-circle ConfirmationHeaderIcon'></i>
          <h3 className='ConfirmationHeaderText'>Confirmation</h3>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='ConfirmationContentDiv' style={{ alignItems: 'start' }}>
          <span>
            <p className='ConfirmationContentText'>
              We have informed your employer about your wish of financial
              security
            </p>
            <br />
            <p className='ConfirmationContentText'>
              As soon as your employer confirms, We will proceed with account
              opening
            </p>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccountConfirmation;
interface AccountConfirmationProps {
  setDisplayAccountConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
}
