import { Col } from 'react-bootstrap';
import { EmployeData } from 'redux/actions';
const EmployerDetails = ({ employeData }: { employeData: EmployeData }) => {
  const wrapper = (name: string, value?: string) => (
    <>
      <div
        className='w-100 pr-5 ml-3 mt-3 d-flex '
        style={{ padding: '10px 0px' }}
      >
        <strong className='width-318'>{name}</strong>
        {value && <span className='ml-3 text-black'>{value}</span>}
      </div>
    </>
  );
  return (
    <>
      <Col lg={12}>
        <div>
          {wrapper('Employer Name', `${employeData.employer_name}`)}
          {wrapper(
            'Gross Salary',
            `${parseInt(
              employeData.current_gross_monthly_salary
            ).toLocaleString()} PKR`
          )}
          {wrapper(
            'Base Salary',
            `${parseInt(employeData.basic_salary).toLocaleString()} PKR`
          )}
          {wrapper('Income Tax', `${Number(employeData.avg_tax).toFixed(2)} %`)}
          {wrapper(
            'Employer Contribution',
            `${parseInt(
              employeData.employer_contribution
            ).toLocaleString()} PKR`
          )}
          {wrapper(
            'Your Contribution',
            `${parseInt(
              employeData.employeeContributionPercent
            ).toLocaleString()} PKR`
          )}
          {wrapper(
            'Current Accumulated Benefit',
            `${parseInt(employeData.accumulated_benefit).toLocaleString()} PKR`
          )}
          <hr />
          {wrapper('By the age of 65 :')}
          {wrapper(
            'Based on existing plan you will have',
            `${parseInt(employeData.plan_amount).toLocaleString()} PKR`
          )}
          {wrapper(
            'With Mahana pension account you will have',
            `${parseInt(
              employeData.mahaana_pension_account_amount
            ).toLocaleString()} PKR`
          )}
        </div>
      </Col>
    </>
  );
};

export default EmployerDetails;
