import { memo, useState } from 'react';
import { thematicTabs } from 'static/thematic';
import TabContent from './TabContent';
import './thematic.css';

const Thematic = () => {
  const [selected, setSelected] = useState(thematicTabs[0]);

  return (
    <div className='thematic'>
      <div className='top' style={{ background: selected.color }}>
        <h1>{selected.title}</h1>
        <div>
          <strong>I want to: </strong>
        </div>
        <p>{selected.subTitle}</p>
      </div>
      <div className='tabs'>
        {thematicTabs.map(val => (
          <div
            key={val.title}
            className='tab text-center ptr'
            onClick={() => setSelected(val)}
            style={{
              background: val.title === selected.title ? val.color : ''
            }}
          >
            <span className={`fa ${val.icon} thematic-icons`} />
            <p>{val.title}</p>
          </div>
        ))}
      </div>
      <TabContent
        bg={selected.color}
        items={selected.items}
        title={selected.title}
      />
    </div>
  );
};

export default memo(Thematic);
