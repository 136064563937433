import { Col } from 'react-bootstrap';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { memo } from 'react';

const AuthInputField = ({
  autoFocus = false,
  bottomInfo,
  col = 12,
  disabled = false,
  formik,
  isMutual = false,
  label,
  name,
  placeholder,
  required = false,
  topInfo,
  type = 'text'
}: AuthInputFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  return (
    <Col lg={col}>
      <div className='form-group'>
        <label className='mb-1 '>
          <strong>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </strong>
        </label>
        {topInfo && (
          <div className='small-info mb-2 mt-1 text-muted'>{topInfo}</div>
        )}
        <input
          autoFocus={autoFocus}
          className='form-control h-38'
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          type={type}
          {...formik.getFieldProps(name)}
        />
        {bottomInfo && (
          <div className='small-info mb-2 mt-1 text-muted'>{bottomInfo}</div>
        )}
        {
          <span className='text-danger '>
            {formikTouch[name] && formikError[name]}
          </span>
        }
      </div>
    </Col>
  );
};

export default memo(AuthInputField);

interface AuthInputFieldProps {
  autoFocus?: boolean;
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  formik: FormikFieldProps;
  label: string | JSX.Element;
  name: string;
  placeholder: string;
  required?: boolean;
  topInfo?: string;
  type?: string;
  bottomInfo?: string | JSX.Element;
  isMutual?: boolean;
}

export interface FormikFieldProps {
  getFieldProps: (field: string) => void;
  touched: FormikTouched<unknown>;
  errors: FormikErrors<unknown>;
  values: FormikValues;
}
