import { memo, useCallback, useEffect, useState } from 'react';

import FundsOverviewComponent from 'jsx/components/FundsOverview';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';
import {
  onGetFunds,
  onGetFundsCategories,
  onGetFundsRisks,
  onGetFundsCompanies,
  onGetFundsTypes,
  Fund,
  onSaveFundInvestments,
  onAddFunds,
  onClearDashboardNotifications
} from 'redux/actions';
import { Button, Form, Modal, Col } from 'react-bootstrap';
import Loader from 'jsx/components/Shared/Loader';
import { toast, ToastContainer } from 'react-toastify';

const FundsOverview = () => {
  const isLoading = useAppLoading('funds');
  const isDashboardLoading = useAppLoading('dashboard');

  const { filters, funds } = useAppSelector(state => state.funds);
  const { message } = useAppSelector(state => state.dashboard);

  const [selectedFund, setSelectedFund] = useState<Fund>();
  const [total, setTotal] = useState(0);

  const dispatch = useAppDispatch();

  const loadData = useCallback(() => {
    dispatch(onGetFunds());
    dispatch(onGetFundsCategories());
    dispatch(onGetFundsRisks());
    dispatch(onGetFundsCompanies());
    dispatch(onGetFundsTypes());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  const notify = useCallback(
    () =>
      message?.length &&
      toast.success(message, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => dispatch(onClearDashboardNotifications()),
        onOpen: () =>
          message === 'Fund Investment was successful' &&
          setSelectedFund(undefined),
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, message]
  );
  useEffect(() => {
    notify();
  }, [notify]);
  const handleFundInvestment = () => {
    if (selectedFund) {
      const data = {
        bank_funds_allocation_id: selectedFund?.id,
        initial_amount: total
      };
      const investedFund = [
        ...funds
          .filter((el: Fund) => el.id === selectedFund.id)
          .map(elem => ({
            amount: total,
            bank_fund_id: elem.id,
            name: elem.name,
            symbol: elem.fund_symbol
          }))
      ];
      dispatch(onSaveFundInvestments(data));
      dispatch(onAddFunds(investedFund));
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal
        centered
        aria-labelledby='contained-modal-title-vcenter'
        onHide={() => setSelectedFund(undefined)}
        show={!!selectedFund}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Your Investment Amount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col lg={12}>
            <div className='form-group'>
              <label className='mb-1 '>
                <strong>Invest the following amount:</strong>
              </label>
              <div style={{ display: 'flex', width: '30%' }}>
                <input
                  autoFocus={true}
                  className='form-control'
                  onChange={e => setTotal(parseInt(e.target.value))}
                  type={'number'}
                  value={total}
                />
              </div>
            </div>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <div className='custom-goal-footer'>
            <Form.Check
              checked={true}
              className='custom-font-size'
              label='Agree Terms & Condition'
              type='checkbox'
            />
            <Button
              disabled={isLoading || isDashboardLoading}
              onClick={handleFundInvestment}
            >
              {isLoading || isDashboardLoading ? <Loader /> : 'OK'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <FundsOverviewComponent
        filters={filters}
        funds={funds}
        isLoading={isLoading}
        setSelectedFund={setSelectedFund}
      />
    </>
  );
};

export default memo(FundsOverview);
