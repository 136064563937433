import * as Yup from 'yup';

export const signup = Yup.object({
  cnic: Yup.string()
    .required('This field is Required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'CNIC number is not valid'
    )
    .length(13, 'CNIC number is not valid'),
  companyName: Yup.string().when('is_employer', {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    is: (val: any) => val,
    then: Yup.string().required('Company Name is required.')
  }),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], 'The both passwords do not match')
    .label('Password Confirmation'),
  dob: Yup.string().required().label('Date of birth'),
  email: Yup.string().email().required().label('Email'),
  firstname: Yup.string().required().label('First Name'),
  lastname: Yup.string().required().label('Last Name'),

  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])([A-Za-z\d]{6,})$/,
      'Password must contain at least 6 characters, one uppercase, one number'
    )
    .required()
    .min(6)
    .label('Password'),
  phone: Yup.number()
    .required('Phone number is required')
    .typeError('Phone number require numbers only.')
});
