import { memo } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { bankTransferPost } from 'redux/actions';
import { Field } from '../Shared/FormikSelectField';
import ChecqueCollectionForm from './ChecqueCollection';
import OnlineBankForm from './OnlineBankForm';

const PaymentOptionsConent = ({
  banksList,
  submitBankTransfer
}: paymentOptionProps) => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col className='margins-bottom' lg={12}>
              <p>
                Please choose from following payment options to fund your
                account
              </p>
            </Col>
            <Col lg={12}>
              <Tab.Container defaultActiveKey='first' id='left-tabs-example'>
                <Col className='margins-bottom' sm={12}>
                  <Nav className='payment-tabs' variant='pills'>
                    <Nav.Item>
                      <Nav.Link eventKey='first'>ONLINE BANK TRANSFER</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='second'>CHECQUE COLLECTION</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey='first'>
                      <OnlineBankForm
                        banksList={banksList}
                        submitBankTransfer={submitBankTransfer}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey='second'>
                      <ChecqueCollectionForm />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default memo(PaymentOptionsConent);

export interface paymentOptionProps {
  banksList: Array<Field>;
  submitBankTransfer: (data: bankTransferPost) => void;
}
