/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Occupation, SourcesOfIncome } from 'redux/reducers/sharedStateData';
import AppSelect from '../Shared/AppSelect';
import { FadeIn } from '../Shared/FadeIn';
import InputField from '../Shared/FormikInputField';

const Financial = ({
  formik,
  occupations,
  sourcesOfIncome
}: FinancialProps) => {
  const handleOccupationChange = (
    val: string,
    option: Record<string, string | number>
  ) => {
    formik.setFieldValue('occupation_id', val);
    formik.setFieldValue('occupation', option.label);
  };
  return (
    <FadeIn>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12}>
              <h3 className='text-center text-secondary'>Financial Details</h3>
            </Col>
            <AppSelect
              fieldSelect
              required
              col={4}
              disabled={false}
              fieldName='occupation_id'
              fields={occupations}
              formik={formik}
              label='Select Occupation'
              onChange={handleOccupationChange}
              placeholder='Select Occupation'
              value={formik.values.occupation_id}
            />
            <AppSelect
              fieldSelect
              required
              col={4}
              disabled={false}
              fieldName='income_source_id'
              fields={sourcesOfIncome}
              formik={formik}
              label='Select Source of Income'
              onChange={val => formik.setFieldValue('income_source_id', val)}
              placeholder='Select Source of Income'
              value={formik.values.income_source_id}
            />

            <InputField
              required
              col={4}
              formik={formik}
              label='Annual Income'
              name='AnnualIncome'
              placeholder='Enter Annual Income'
            />
          </Row>
        </Card.Body>
      </Card>
    </FadeIn>
  );
};

export default memo(Financial);

interface FinancialProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  occupations: Array<Occupation>;
  sourcesOfIncome: Array<SourcesOfIncome>;
}
