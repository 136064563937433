/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  BankFundAllocationList: [],
  InvestmentList: [],
  riskPortfolioData: []
};

const name = 'progressBarChart';
const progressBarChartSlice = createSlice({
  initialState,
  name,
  reducers: {
    BankFundAllocationListSuccess: (state, action: PayloadAction<any>) => {
      const fundsData = JSON.parse(action.payload.data);
      state.BankFundAllocationList = fundsData;
    },
    UserFundAllocationSuccess: (state, action: PayloadAction<any>) => {
      if (action.payload.data) {
        const chartData: any = [];
        const stockData = action.payload.data.filter(
          (el: any) => Object.keys(el)[0] === 'stock_percent'
        );
        const bondData = action.payload.data.filter(
          (el: any) => Object.keys(el)[0] === 'bond_percent'
        );
        chartData.push(
          {
            list: stockData || [],
            name: 'Stocks',
            value: stockData[0]?.stock_percent
          },
          {
            list: bondData || [],
            name: 'Bonds',
            value: bondData?.[0]?.bond_percent
          }
        );

        state.riskPortfolioData = chartData;
      }
    },
    UserFundInvestmentListSuccess: (state, action: PayloadAction<any>) => {
      const investmentList = JSON.parse(action.payload.data);
      state.InvestmentList = investmentList;
    }
  }
});

export default progressBarChartSlice.reducer;

//? APIS ACTIONS

export const onGetPieChartData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'RiskProfile/GetUserRiskPortfolio'
  },
  type: 'progressBarChartData'
});

export const onGetUserFundAllocation = (id: number | null) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `RiskProfile/GetUserFundAllocation?goal_id=${id}`
  },
  type: 'UserFundAllocation'
});
export const onGetBankFundAllocationList = (id: number | null) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `BankFundsAllocation/GetBankFundAllocationList?goal_id=${id}`
  },
  type: 'BankFundAllocationList'
});
export const onGetUserFundInvestmentList = (
  id: number | null,
  investmentTypeId: number | null
) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `BankFundsAllocation/GetUserFundInvestmentList?goal_id=${id}&fund_investment_type_id=${investmentTypeId}`
  },
  type: 'UserFundInvestmentList'
});

// ? Types

interface InitialState {
  riskPortfolioData: Array<any>;
  BankFundAllocationList: Array<any>;
  InvestmentList: Array<any>;
}
