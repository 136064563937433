import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CompletedGoalsProps, goalProps } from 'redux/actions';
// import Statistics from '../Shared/Statistics';

const GoalsOverview = ({
  completedGoals,
  handleGoalDelete,
  pathname
}: {
  pathname: string;
  completedGoals: Array<CompletedGoalsProps>;
  handleGoalDelete: (goal: goalProps) => void;
}) => {
  return (
    <div className='col-xl-6 col-xxl-12'>
      <div className='card'>
        <div className='card-header d-sm-flex d-block pb-0 border-0'>
          <div>
            <h4 className='fs-20 text-black'>
              {pathname === '/tax-saving-account'
                ? 'Tax Saving Account'
                : pathname === '/pension-account'
                ? 'Pension Account'
                : 'Goals Overview'}
            </h4>
          </div>
        </div>
        <div className='card-body pb-0'>
          <div className='d-flex flex-wrap' style={{ paddingBottom: '20px' }}>
            <Row className='goalsRow'>
              {completedGoals &&
                completedGoals.map(item => {
                  return (
                    <>
                      <Col
                        className='d-flex'
                        lg={12}
                        style={{ marginTop: '20px' }}
                      >
                        <Col lg={4}>
                          <div>
                            <h4 style={{ fontWeight: 'bolder' }}>
                              {item.title}
                            </h4>
                            <p style={{ fontSize: '13px', margin: '0' }}>
                              {item.goal_type_id === 2
                                ? 'Retire on your own terms.'
                                : 'Your employer sponsors this scheme with monthly contributions.'}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <h4 style={{ fontWeight: 'bolder' }}>Balance</h4>
                            <p style={{ margin: '0' }}>
                              PKR {item.balance.toLocaleString()}
                            </p>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <h4 style={{ fontWeight: 'bolder' }}>Goal</h4>
                            <p style={{ margin: '0' }}>
                              Retire at {item.age} with PKR{' '}
                              {item.balance.toLocaleString()}
                            </p>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <i
                            aria-hidden='true'
                            className='fa fa-trash-o fs-5'
                            onClick={e => handleGoalDelete(item)}
                            style={{
                              color: '#f72b50',
                              cursor: 'pointer',
                              display: 'flex',
                              fontSize: '25px',
                              justifyContent: 'center'
                            }}
                          ></i>
                        </Col>
                      </Col>
                    </>
                  );
                })}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(GoalsOverview);
