import { Link, useLocation } from 'react-router-dom';
import { memo } from 'react';
import { useFormik } from 'formik';

import AuthPage from 'jsx/components/Auth/AuthWrapper';
import InputField from 'jsx/components/Shared/FormikInputField';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import { ForgotParams } from 'redux/actions';
import { forgot } from 'validate/auth/forgot';
import { links } from 'static/links';

const ForgotPassword = ({
  forgotPasswordHandle,
  loading,
  openInvite
}: ForgotProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const formik = useFormik({
    initialValues: { email: '', ...(token && { token }) },
    onSubmit: (values: ForgotParams) => {
      forgotPasswordHandle(values);
    },
    validationSchema: forgot
  });

  return (
    <AuthPage title='Reset Your Password'>
      <p className='px20'>
        Enter the email address associated with your account and we'll send you
        a link to reset your password.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <InputField
          required
          disabled={loading}
          formik={formik}
          label='Email'
          name='email'
          placeholder='hello@example.com'
        />
        <div className='form-row d-flex px20 justify-content-end mt-4 mb-2'>
          <div className='form-group'>
            <Link to={links.login}>Return to login</Link>
          </div>
        </div>
        <AuthSubmitButton disabled={loading} isAuth={true} title='Submit' />
      </form>
      <div className='new-account px20 mt-3'>
        <p>
          Don't have an account?
          <span
            className='text-primary ml-1 cursor-pointer'
            onClick={openInvite}
          >
            Sign up
          </span>
        </p>
      </div>
    </AuthPage>
  );
};

export default memo(ForgotPassword);

interface ForgotProps {
  forgotPasswordHandle: (data: ForgotParams) => void;
  loading: boolean;
  openInvite: () => void;
}
