import { Card, Dropdown } from 'react-bootstrap';

const CompanyInfo = () => {
  return (
    <Card style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='mb-0 text-black fs-20'>About</h4>
        <Dropdown className='dropdown ml-auto'>
          <Dropdown.Toggle
            className='i-false p-0 btn-link'
            data-toggle='dropdown'
            variant=''
          >
            <svg
              height='24px'
              version='1.1'
              viewBox='0 0 24 24'
              width='24px'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
            >
              <g fill='none' fillRule='evenodd' stroke='none' strokeWidth={1}>
                <rect height={24} width={24} x={0} y={0} />
                <circle cx={12} cy={5} fill='#000000' r={2} />
                <circle cx={12} cy={12} fill='#000000' r={2} />
                <circle cx={12} cy={19} fill='#000000' r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu
            alignRight={true}
            className='dropdown-menu dropdown-menu-right'
          >
            <span className='dropdown-item'>Edit</span>
            <span className='dropdown-item'>Delete</span>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        <div className='text-left mb-3'>
          <img
            alt=''
            className='mb-3'
            src='https://www.lucky-cement.com/wp-content/uploads/2017/02/logo.png'
            width={'100%'}
          />
          <h2 className='fs-28 font-w600 text-black mb-5 text-center'>
            Lucky cement
          </h2>

          <h4 className=' font-w600 text-black mb-2'>Company Information</h4>
          <div className='table-responsive'>
            <table className='table'>
              <tbody>
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>Industry</th>
                  <td className='text-right'>12.02%</td>
                </tr>
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>Sector</th>
                  <td className='text-right'>12.02%</td>
                </tr>{' '}
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>Employee</th>
                  <td className='text-right'>12.02%</td>
                </tr>{' '}
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>CEO</th>
                  <td className='text-right'>12.02%</td>
                </tr>{' '}
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>Address</th>
                  <td className='text-right'>12.02%</td>
                </tr>{' '}
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>Phone</th>
                  <td className='text-right'>12.02%</td>
                </tr>{' '}
                <tr>
                  <th style={{ color: '#000', fontWeight: 600 }}>Website</th>
                  <td className='text-right text-primary'>12.02%</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className='font-w400 text-black'>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CompanyInfo;
