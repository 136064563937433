import { memo } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import hbl from '../../../images/UBL.png';

const FundInfo = ({ data }: { data: fundsOverviewProps }) => {
  const { fund_symbol, name_ } = data || {};

  return (
    <Card style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='mb-0 text-black fs-20'>About</h4>
        <Dropdown className='dropdown ml-auto'>
          <Dropdown.Toggle
            className='i-false p-0 btn-link'
            data-toggle='dropdown'
            variant=''
          >
            <svg
              height='24px'
              version='1.1'
              viewBox='0 0 24 24'
              width='24px'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
            >
              <g fill='none' fillRule='evenodd' stroke='none' strokeWidth={1}>
                <rect height={24} width={24} x={0} y={0} />
                <circle cx={12} cy={5} fill='#000000' r={2} />
                <circle cx={12} cy={12} fill='#000000' r={2} />
                <circle cx={12} cy={19} fill='#000000' r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu
            alignRight={true}
            className='dropdown-menu dropdown-menu-right'
          >
            <span className='dropdown-item'>Edit</span>
            <span className='dropdown-item'>Delete</span>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        <div className='text-center mb-3'>
          <img alt='' className='mb-3' src={hbl} width={'100%'} />
          <h2 className='fs-24 font-w600 text-black mb-0'>{name_}</h2>
          <p className='fs-14 font-w600 text-black'>{fund_symbol}</p>
          <span className='text-primary fs-14'>1 HBL = 1,151.48 PKR</span>
        </div>
        <p className='fs-14'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut.
        </p>
      </Card.Body>
    </Card>
  );
};

export default memo(FundInfo);

export interface fundsOverviewProps {
  absolute_return_annualized_1_year: number;
  absolute_return_annualized_3_year: number;
  absolute_return_annualized_5_year: number;
  absolute_return_annualized_since_inception: number;
  amount_: number;
  applicable_return_ytd: number;
  benchmark: string;
  cut_off_end_time: string;
  cut_off_start_time: string;
  dealing_days: string;
  front_end_load: number;
  fund_category: string;
  fund_shariah_nature: string;
  fund_symbol: string;
  fund_type: string;
  fundsizeamount: number;
  fundsizedate: string;
  id: number;
  issuer_id: number;
  issuer_name: string;
  launch_date: string;
  management_fee: number;
  name_: string;
  net_asset_value: number;
  objective_: string;
  percent_change_net_asset_value: number;
  pricing_mechanism: number;
  short_code: string;
  validity_date: string;
}
