const auth = '/auth';
const employer = '/employeer';

export const links = {
  auth,
  companyProfile: '/thematic/:id',
  confirmation: '/Activate',
  dashboardEmployees: `${employer}/employees`,
  employer,
  employerContributions: `${employer}/contributions`,
  employerDashboard: `${employer}/dashboard`,
  employerScreen: '/employer',
  forgot: `${auth}/forgot`,
  fundDetails: '/fund/:id',
  fundsOverview: '/funds-overview',
  home: '/',
  investments: `${employer}/investments`,
  learn: '/learn',
  linkAccounts: '/link-accounts',
  login: `${auth}/login`,
  mutualFundAccount: '/mutual-fund-account',
  myAccounts: '/my-accounts',
  otp: `${auth}/sendotp`,
  otpVerify: `${auth}/verify-otp`,
  overview: '/overview',
  paymentOptions: '/payment-options',
  pension: '/pension',
  pensionAccount: '/pension-account',
  portfolio: '/portfolio',
  resetPassword: `${auth}/resetPassword`,
  riskProfile: `/risk-profile`,
  riskScore: '/risk-score',
  setting: `/settings`,
  signup: `${auth}/signup`,
  taxSavingAccount: '/tax-saving-account',
  thematic: '/thematic',
  transactionHistory: '/transaction-history'
};
