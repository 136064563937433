import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { memo, useEffect } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { CompletedGoalsProps } from 'redux/actions';
import {
  onGetEmployeeProvidentFund,
  onGetUserAccountStates,
  onGetVPSBalance
} from 'redux/reducers/employer';

import OccupationalAccountDetails from './OccupationalAccountDetails';
import VPSBalanceDetails from './VPSBalanceDetails';

const list = [
  { title: 'Occupation Pension' },
  { title: 'VPS' },
  { title: 'Tax Saving Account' }
];
const AccountsContainer = ({
  completedGoals,
  setTitle,
  title
}: AccountsContainerProps) => {
  const { user } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(onGetEmployeeProvidentFund(user?.user_id));
    dispatch(onGetVPSBalance(user?.user_id));
    dispatch(onGetUserAccountStates(user?.user_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Card className='pb-0' style={{ height: 'auto' }}>
        <Card.Body className='pb-0'>
          <Row>
            <Col lg={12}>
              <Dropdown className='dropdown bootstrap-select form-control style-1 default-select mb-3 '>
                <Dropdown.Toggle
                  aria-expanded='false'
                  className='btn dropdown-toggle h-38'
                  data-toggle='dropdown'
                  role='button'
                  title='Latest'
                  type='button'
                  variant=''
                >
                  {title}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className='dropdown-menu'
                  role='combobox'
                  style={{
                    left: 0,
                    maxHeight: 174,
                    minHeight: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    top: 0,
                    transform: 'translate3d(0px, 41px, 0px)',
                    willChange: 'transform'
                  }}
                  x-placement='bottom-start'
                >
                  {list.map(item => (
                    <Dropdown.Item
                      key={item.title}
                      onClick={() => setTitle(item.title)}
                    >
                      {item.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <OccupationalAccountDetails completedGoals={completedGoals} />
          </Row>
        </Card.Body>
      </Card>
      <VPSBalanceDetails />
    </>
  );
};

export default memo(AccountsContainer);

interface AccountsContainerProps {
  title: string;
  setTitle: (title: string) => void;
  completedGoals: Array<CompletedGoalsProps>;
}
