import { FormikErrors, FormikTouched } from 'formik';
import { memo } from 'react';
import { Col, Form } from 'react-bootstrap';

const FormikRadioField = ({
  col = 6,
  fieldName,
  fields,
  formik,
  label,
  required = false,
  singleLine = false
}: FormikRadioFieldProps) => {
  const formikTouch = formik.touched as never;
  const formikError = formik.errors as never;

  return (
    <Col lg={col}>
      <div className='form-group'>
        <label className='mb-1 '>
          <strong>
            {label}
            {required && <span className='ml-1 text-danger'>*</span>}
          </strong>
        </label>
        <div className={singleLine ? 'singleLine' : ''}>
          {fields.map(val => (
            <Form.Check
              key={val.value}
              checked={formik.values[fieldName] === val.value}
              disabled={!!val.disabled}
              id={`inline-radio-${val.value}`}
              label={val.label}
              name={fieldName}
              onChange={formik.getFieldProps(fieldName).onChange}
              type='radio'
              value={val.value}
            />
          ))}
        </div>
        <span className='ml-2 text-danger'>
          {formikTouch[fieldName] && formikError[fieldName]}
        </span>
      </div>
    </Col>
  );
};

export default memo(FormikRadioField);

interface FormikRadioFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  disabled?: boolean;
  fieldName: string;
  fields: Array<{ label: string; value: string; disabled?: boolean }>;
  formik: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getFieldProps: any;
    touched: FormikTouched<unknown>;
    errors: FormikErrors<unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
  };
  label: string;
  required?: boolean;
  singleLine?: boolean;
}
