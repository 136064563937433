import { Card, Row } from 'react-bootstrap';
import { memo } from 'react';
import InputField, {
  FormikFieldProps
} from 'jsx/components/Shared/FormikInputField';
import FormikRadioField from 'jsx/components/Shared/FormikRadioField';
import { investmentType } from 'static/selection';

const PortfolioForm = ({ formik }: PortfolioFormProps) => {
  const expected = (
    <Row>
      <InputField
        col={6}
        formik={formik}
        label='Expected money at retirement'
        name='retirement_money'
        placeholder='Enter amount'
        topInfo='How much money would you like to have at retirment?'
      />
      <InputField
        col={6}
        formik={formik}
        label='Expected age at retirement'
        name='retirement_age'
        placeholder='Enter Age'
        topInfo='At which age would you take retirement?'
      />
    </Row>
  );
  const monthly = (
    <Row>
      <InputField
        col={6}
        formik={formik}
        label='Expected monthly saving'
        name='monthly_saving'
        placeholder='Enter amount'
        topInfo='How much money would you like to save every month?'
      />
    </Row>
  );

  const returnSelectForm = () => {
    // @ts-ignore
    const type = formik.getFieldProps('plan_type')?.value;
    if (type === '0') return expected;
    if (type === '1') return monthly;
    return null;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Card.Body>
          <Row>
            <FormikRadioField
              singleLine
              col={6}
              fieldName='investment_type'
              fields={investmentType}
              formik={formik}
              label='Choose Investment'
            />

            {formik?.values?.investment_type && (
              <InputField
                required
                col={6}
                formik={formik}
                label='Initial Amount'
                name='initial_amount'
                placeholder='Enter amount'
              />
            )}
          </Row>

          {formik?.values?.initial_amount && (
            <>
              <div className='d-flex'>
                <h4>Choose one option</h4>
                <span className='ml-1 text-danger'>*</span>
              </div>
              <FormikRadioField
                singleLine
                col={6}
                fieldName='plan_type'
                fields={[
                  {
                    disabled: true,
                    label: 'I know the amount I want at retirement',
                    value: '0'
                  },
                  { label: 'Plan monthly contributions', value: '1' }
                ]}
                formik={formik}
                label=''
              />
            </>
          )}

          {returnSelectForm()}
        </Card.Body>
      </Card>
    </form>
  );
};

export default memo(PortfolioForm);

interface FormikProps extends FormikFieldProps {
  handleSubmit: () => void;
}
export interface PortfolioFormProps {
  formik: FormikProps;
}
