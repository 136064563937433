import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { sideTabs } from 'static/sideTabs';
const SideBar = () => {
  const history = useHistory();
  const location = history.location.pathname;
  return (
    <>
      <Card className='pb-0' style={{ height: '100%' }}>
        <Card.Body className='pb-0'>
          <Row>
            {sideTabs &&
              sideTabs.map((tab, index) => {
                return (
                  <>
                    <Col sm={12}>
                      <div key={index} className='pt-4 pl-2 w-100'>
                        <h4
                          className={
                            location === tab.url
                              ? 'pl-2 sideTabContent'
                              : 'pl-2'
                          }
                          onClick={e => history.push(tab.url)}
                          style={{ cursor: 'pointer' }}
                        >
                          {tab.name}
                        </h4>
                      </div>
                    </Col>
                  </>
                );
              })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default memo(SideBar);
