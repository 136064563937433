/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProgressBarSingleCard = ({
  InvestmentList,
  data,
  type
}: {
  type: string;
  data: Record<string, any>;
  InvestmentList: any;
}) => {
  const color = type === 'Stocks' ? '#f0aa2d' : '#4012A0';
  const variant = type === 'Stocks' ? 'warning' : 'success';
  return (
    <Row className='px-5 mb-5'>
      <Col xs={8}>
        <Row>
          <Link target='_blank' to={`fund/${data.id ?? 1}`}>
            <Col xs={12}>
              <h5 style={{ fontSize: '20px' }}>{data.symbol}</h5>
            </Col>

            <Col xs={12}>
              <p style={{ color }}>{data.fund_name}</p>
            </Col>
          </Link>
          <Col xs={12}>
            <ProgressBar
              color={color}
              now={data.percentage}
              variant={variant}
            />
          </Col>
        </Row>
      </Col>
      <Col style={{ textAlign: 'end' }} xs={4}>
        <h5>{data.percentage}%</h5>
      </Col>
    </Row>
  );
};

export default ProgressBarSingleCard;
