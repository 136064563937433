import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { employerSideTabs } from 'static/EmployerDashboard/employerSideTabs';
export default function EmploerDashboardSideBar() {
  const history = useHistory();
  const location = history.location.pathname;
  return (
    <Card className='pb-0' style={{ height: '100%' }}>
      <Card.Body className='pb-0'>
        <Row>
          {employerSideTabs &&
            employerSideTabs.map((tab, index) => {
              return (
                <>
                  <Col sm={12}>
                    <div key={index} className='pt-4 pl-2 w-100'>
                      <h4
                        className={
                          location === tab.url ? 'pl-2 sideTabContent' : 'pl-2'
                        }
                        onClick={e => history.push(tab.url)}
                        style={{ cursor: 'pointer' }}
                      >
                        {tab.name}
                      </h4>
                    </div>
                  </Col>
                </>
              );
            })}
        </Row>
      </Card.Body>
    </Card>
  );
}
