import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { EmployerEmployee } from 'redux/reducers/employer';
const EmployesTable = ({
  employerEmployees,
  employerEmployeesTotalRecords,
  setCurrentPage
}: EmployesTableProps) => {
  const [currentItems, setCurrentItems] =
    useState<Array<EmployerEmployee>>(employerEmployees);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setCurrentItems(employerEmployees);
    setPageCount(Math.ceil(employerEmployeesTotalRecords / itemsPerPage));
  }, [employerEmployeesTotalRecords, employerEmployees]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: Record<string, number>) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <Col lg={12}>
        <div>
          <Items currentItems={currentItems} />
          {currentItems.length ? (
            <ReactPaginate
              activeClassName='active'
              breakClassName='page-item'
              breakLabel='...'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              disabledClassName='disabledBtn'
              marginPagesDisplayed={3}
              nextClassName='page-item'
              nextLabel={
                <i
                  aria-hidden='true'
                  className='fa fa-chevron-right cursor-pointer '
                />
              }
              nextLinkClassName='page-link'
              onPageChange={handlePageClick}
              pageClassName='page-item'
              pageCount={pageCount}
              pageLinkClassName='page-link'
              pageRangeDisplayed={3}
              previousClassName='page-item'
              previousLabel={
                <i
                  aria-hidden='true'
                  className='fa fa-chevron-left cursor-pointer '
                />
              }
              previousLinkClassName='page-link'
            />
          ) : null}
        </div>
      </Col>
    </>
  );
};

export default EmployesTable;

interface EmployesTableProps {
  employerEmployees: Array<EmployerEmployee>;
  employerEmployeesTotalRecords: number;
  setCurrentPage: (page: number) => void;
}
interface ItemsProps {
  currentItems: Array<EmployerEmployee>;
}

const Items = ({ currentItems }: ItemsProps) => {
  const heads = [
    { colSpan: 0, heading: 'Name', rowSpan: 2 },
    { colSpan: 0, heading: 'Status', rowSpan: 2 },
    { colSpan: 0, heading: 'Salary', rowSpan: 2 },
    { colSpan: 3, heading: 'Contributions', rowSpan: 0 },
    { colSpan: 0, heading: 'Current Balance', rowSpan: 2 },
    { colSpan: 0, heading: 'Profit/Loss', rowSpan: 2 },
    { colSpan: 0, heading: 'Risk Score', rowSpan: 2 },
    { colSpan: 0, heading: 'Signed Up', rowSpan: 2 }
  ];
  const contributionHeads = [
    { heading: 'Employer', rowSpan: 0 },
    { heading: 'Employe', rowSpan: 0 },
    { heading: 'Accumulated', rowSpan: 0 }
  ];
  return (
    <div className='table-responsive'>
      <table
        cellPadding={0}
        cellSpacing={0}
        className='table table-bordered table-striped'
      >
        <thead className='customTableHeader'>
          <tr>
            {heads.map((head, index) => (
              <>
                {head.rowSpan > 0 ? (
                  <th colSpan={head.colSpan} rowSpan={head.rowSpan}>
                    {head.heading}
                  </th>
                ) : (
                  <th colSpan={head.colSpan}>{head.heading}</th>
                )}
              </>
            ))}
          </tr>
          <tr>
            {contributionHeads.map((head, index) => (
              <>
                <th rowSpan={head.rowSpan}>{head.heading}</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody className='customTableBody table'>
          {currentItems.length
            ? currentItems?.map((employee: EmployerEmployee) => (
                <tr key={employee.id}>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>{employee.employee_name}</p>
                  </td>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>{employee.status}</p>
                  </td>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>
                      {employee.salary.toLocaleString()} PKR
                    </p>
                  </td>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>
                      {employee.employer_contribution.toLocaleString()} PKR{' '}
                    </p>
                  </td>
                  <td className='fs-20'>
                    {' '}
                    <p className='mb-0 fs-18'>
                      {employee.employee_contribution.toLocaleString()} PKR{' '}
                    </p>
                  </td>
                  <td className='fs-20'>
                    {' '}
                    <p className='mb-0 fs-18'>
                      {employee.accumlated.toLocaleString()} PKR{' '}
                    </p>
                  </td>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>
                      {employee.balance.toLocaleString()} PKR
                    </p>
                  </td>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>
                      {employee.profit_loss.toLocaleString()} PKR
                    </p>
                  </td>
                  <td className='fs-20'>
                    <p className='mb-0 fs-18'>
                      {employee.risk_score.toLocaleString()}
                    </p>
                  </td>
                  <td className='fs-20'>
                    {employee.signed_up ? (
                      <i
                        aria-hidden='true'
                        className='fa fa-check fa-lg cursor-pointer '
                      />
                    ) : (
                      <i
                        aria-hidden='true'
                        className='fa fa-close fa-lg cursor-pointer '
                      />
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};
