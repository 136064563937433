import { useAppSelector } from 'hooks/reduxHooks';
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import StackedColumn from '../../pages/EmployerDashboard/StackedColumn';

const VPSBalanceDetails = () => {
  const { VPSBalanceDetails, employeeProvidentFundDetails } = useAppSelector(
    state => state.employer
  );
  return (
    <>
      <Row>
        <Col lg={4}>
          <Card className='pb-0' style={{ height: 'auto' }}>
            <Card.Body className='pb-0'>
              <ul className='pb-70'>
                <li className='HomeAccountList'>
                  <span>VPS Balance </span>
                  <span className='float-right'>
                    {' '}
                    {isNaN(parseInt(`${VPSBalanceDetails.VPSBalance}`))
                      ? 0
                      : parseInt(
                          `${VPSBalanceDetails.VPSBalance}`
                        ).toLocaleString()}{' '}
                    PKR
                  </span>
                </li>
                <li className='HomeAccountList'>
                  <span>Total Contribution </span>
                  <span className='float-right'>
                    {' '}
                    {isNaN(parseInt(employeeProvidentFundDetails.PFValue))
                      ? 0
                      : parseInt(
                          employeeProvidentFundDetails.PFValue
                        ).toLocaleString()}{' '}
                    PKR{' '}
                  </span>
                </li>
                <li className='HomeAccountList'>
                  <span>Return Total </span>
                  <span className='float-right'>
                    {' '}
                    {isNaN(parseInt(employeeProvidentFundDetails.ReturnValue))
                      ? 0
                      : parseInt(
                          employeeProvidentFundDetails.ReturnValue
                        ).toLocaleString()}{' '}
                    PKR{' '}
                  </span>
                </li>
                <li className='HomeAccountList'>
                  <span>Monthly Contribution </span>
                  <span className='float-right'>
                    {' '}
                    {isNaN(
                      parseInt(employeeProvidentFundDetails.MonthlyContribution)
                    )
                      ? 0
                      : parseInt(
                          employeeProvidentFundDetails.MonthlyContribution
                        ).toLocaleString()}{' '}
                    PKR{' '}
                  </span>
                </li>
                <li className='HomeAccountList'>
                  <span>Tax Saved </span>
                  <span className='float-right'>
                    {' '}
                    {isNaN(parseInt(`${VPSBalanceDetails.TaxCreditSum}`))
                      ? 0
                      : parseInt(
                          `${VPSBalanceDetails.TaxCreditSum}`
                        ).toLocaleString()}{' '}
                    PKR{' '}
                  </span>
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8}>
          <Card className='pb-0' style={{ height: 'auto' }}>
            <Card.Body className='pb-0'>
              <StackedColumn
                VPSBalanceDetails={VPSBalanceDetails}
                employeeProvidentFundDetails={employeeProvidentFundDetails}
                isExisting={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(VPSBalanceDetails);
