import { memo, useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const PlannedSaving = ({
  employeData,
  formik,
  handleIntrestCompute,
  initialAmount,
  intrestData,
  isEmployer,
  monthlySaving
}: Props) => {
  const [range, setRange] = useState(`0`);
  const range100 = parseInt(range) * 100;
  useEffect(() => {
    if (monthlySaving > 0) {
      setRange(`${Number(monthlySaving) / 100}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlySaving]);
  useEffect(() => {
    if (parseInt(range) > 0) {
      handleIntrestCompute({
        initial_amount: initialAmount,
        monthly_saving: (parseInt(range) * 100).toString()
      });
      formik.setFieldValue(
        'monthly_saving',
        (parseInt(range) * 100).toString()
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (e: any) => {
    setRange(e.target.value);
  };
  return (
    <Card>
      <Card.Header>
        <Card.Title style={{ width: '100%' }}>
          <Col className='pl-0'>
            {isEmployer
              ? 'Monthly Contribution by your Employer'
              : 'My Planned Saving'}
          </Col>
        </Card.Title>
        <Card.Title style={{ width: '100%' }}>
          <Col className='pl-0'>Total Saving at Retirement</Col>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6} style={{ borderRight: '1px solid #e2e2e2' }}>
            {!isEmployer ? (
              <h3 className='mt-3 mb-3'>
                PKR {range100.toLocaleString()}/month
              </h3>
            ) : (
              <h3 className='mt-3 mb-3'>PKR {formik.values.salary}/month</h3>
            )}
            {!isEmployer && (
              <Form.Control
                onChange={e => handleRangeChange(e)}
                type='range'
                value={range}
              />
            )}
            {!isEmployer && (
              <div className='mt-2'>
                You can increase or decrease your planned saving to see how your
                money will grow.
              </div>
            )}
          </Col>
          <Col md={6}>
            <Row>
              <Col xs={1}>
                {' '}
                <i className='fa fa-leaf fa-2x text-info' />
              </Col>
              <Col style={{ textAlign: 'left' }} xs={11}>
                <h3>You've got a plan, now let's act</h3>
              </Col>
            </Row>

            <h3 className='mt-2'>
              You will have{' '}
              {!isEmployer ? (
                <span className='plannedAmount text-primary fs-38'>
                  {isNaN(parseInt(intrestData?.result))
                    ? 0
                    : parseInt(intrestData?.result).toLocaleString()}
                </span>
              ) : (
                <span className='plannedAmount text-primary fs-38'>
                  {`${parseInt(
                    employeData.mahaana_pension_account_amount
                  ).toLocaleString()}`}
                </span>
              )}{' '}
              PKR by age 65.
            </h3>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default memo(PlannedSaving);
interface intrestDataProps {
  interest: number[];
  principal: number[];
  result: string;
  total: number[];
}
interface Props {
  intrestData: intrestDataProps | Record<string, never>;
  monthlySaving: number | string;
  initialAmount: number | string;
  handleIntrestCompute: (values: Record<string, number | string>) => void;
  isEmployer: boolean;
  // eslint-disable-next-line
  formik: any;
  // eslint-disable-next-line
  employeData: any;
}
