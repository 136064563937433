import DropDown from 'jsx/components/Shared/DropDown';
import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

const HeaderLeftDropDown = ({ items, title }: HeaderLeftDropDownProps) => {
  const { pathname } = useLocation();
  const isActive = !!items.find(val => val.link === pathname);

  return (
    <DropDown
      activeClass={isActive ? 'active-link' : ''}
      title={title}
      titleStyle={{ marginRight: 20, padding: 0 }}
    >
      <>
        {items.map(({ link, name }) => (
          <Link key={link} className='dropdown-item ' to={link}>
            {name}
          </Link>
        ))}
      </>
    </DropDown>
  );
};

export default memo(HeaderLeftDropDown);

interface Item {
  name: string;
  link: string;
}

interface HeaderLeftDropDownProps {
  items: Array<Item>;
  title: string;
}
