import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  onAddOccupationalGoal,
  onAddVPSGoal,
  onAddFunds,
  onClearDashboardNotifications
} from 'redux/actions';
import { links } from 'static/links';
import { intrestDataProps } from './ProgressBarChart';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Loader from 'jsx/components/Shared/Loader';
import { toast, ToastContainer } from 'react-toastify';

const Recommendation = ({
  formik,
  intrestData,
  isEmployer,
  onHide,
  show
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
  show: boolean;
  onHide: () => void;
  isEmployer: boolean;
  intrestData: intrestDataProps | Record<string, never>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { formData } = useAppSelector(state => state.riskProfile);
  const { InvestmentList } = useAppSelector(state => state.pieChartData);
  const { created_goal_id } = useAppSelector(state => state.portfolio);
  const { message } = useAppSelector(state => state.dashboard);

  const goal_type_id = formik?.values?.investment_type === 'islamic' ? 1 : 2;
  const isLifeInsaurance =
    formData.personalFormInitialValues.current_insurance.filter(
      (el: string) => el === '58'
    ).length > 0;
  const { isSetUpComplete } = useAppSelector(state => state.auth);
  const { push } = useHistory();
  const notify = useCallback(
    () =>
      message?.length &&
      toast.success(message, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => dispatch(onClearDashboardNotifications()),
        onOpen: () =>
          message === 'Fund Investment was successful' && push(links.home),
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, message, push]
  );
  useEffect(() => {
    notify();
  }, [notify]);
  const computeTotalAmount = () => {
    if (formik.values) {
      return (
        parseInt(formik.values.initial_amount) +
        parseInt(formik.values.monthly_saving)
      );
    } else {
      return 0;
    }
  };
  const [total, setTotal] = useState(computeTotalAmount());
  const validateUserAccount = () => {
    let goalDetails = {};
    const funds = [
      ...InvestmentList.map(elem => ({
        amount: elem.Amount,
        bank_fund_id: elem.bank_funds_allocation_id,
        name: elem.fund_name,
        symbol: elem.symbol
      }))
    ];
    if (!isEmployer) {
      goalDetails = {
        fund_investment_type_id: goal_type_id,
        goal_id: created_goal_id
      };
    } else {
      goalDetails = {
        fund_investment_type_id: goal_type_id,
        goal_id: created_goal_id
      };
    }
    push(links.mutualFundAccount, {
      funds: funds,
      goalDetails: goalDetails,
      isEmployer: isEmployer
    });
  };
  const handleModalClose = () => {
    if (!isSetUpComplete) {
      validateUserAccount();
    } else {
      const funds = [
        ...InvestmentList.map(elem => ({
          amount: elem.Amount,
          bank_fund_id: elem.bank_funds_allocation_id,
          name: elem.fund_name,
          symbol: elem.symbol
        }))
      ];
      setIsLoading(true);
      dispatch(onAddFunds(funds));
      if (!isEmployer && created_goal_id) {
        const data = {
          fund_investment_type_id: goal_type_id,
          goal_id: created_goal_id
        };
        dispatch(onAddVPSGoal(data));
        //make api post call
      } else {
        const data = {
          fund_investment_type_id: goal_type_id,
          goal_id: created_goal_id
        };
        dispatch(onAddOccupationalGoal(data));
      }
      onHide();
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal
        centered
        aria-labelledby='contained-modal-title-vcenter'
        onHide={handleModalClose}
        show={show}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {isEmployer ? 'Recommendation' : 'Your Investment Amount'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isEmployer && (
            <p>
              We noticed you don't currently have{' '}
              <span style={{ color: '#4012A0' }}>life insurance</span> or it
              does not cover all risks. We therefore recommend: IGI Life
              Insurance. Our representative will contact you to help you make
              the right choice.
            </p>
          )}
          {!isEmployer && (
            <>
              <Col lg={12}>
                <div className='form-group'>
                  <label className='mb-1 '>
                    <strong>Invest the following amount:</strong>
                    <span className='small-info mb-2 mt-1 text-muted'>
                      {' '}
                      (This is your initial amount and first monthly
                      contribution)
                    </span>
                  </label>
                  <div style={{ display: 'flex', width: '30%' }}>
                    <input
                      autoFocus={true}
                      className='form-control'
                      onChange={e => setTotal(parseInt(e.target.value))}
                      type={'number'}
                      value={total}
                    />
                  </div>
                </div>
              </Col>
              {!isLifeInsaurance && (
                <div className='alert alert-success' role='alert'>
                  <div>
                    <i
                      aria-hidden='true'
                      className='fa fa-info-circle text-primary'
                    />
                    <strong className='text-primary ml-2 mt-2'>
                      {' '}
                      Recommendation{' '}
                    </strong>
                  </div>
                  <hr />
                  <p>
                    We noticed you don't currently have{' '}
                    <span style={{ color: '#4012A0' }}>life insurance</span> or
                    it does not cover all risks. We therefore recommend: IGI
                    Life Insurance. Our representative will contact you to help
                    you make the right choice.
                  </p>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='custom-goal-footer'>
            <Form.Check
              checked={true}
              className='custom-font-size'
              label='Agree Terms & Condition'
              type='checkbox'
            />
            <Button disabled={isLoading} onClick={handleModalClose}>
              {isLoading ? <Loader /> : 'OK'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Recommendation;
