import { Card } from 'react-bootstrap';
import { memo } from 'react';

const KeyFacts = () => (
  <Card className='pb-2' style={{ height: 'auto' }}>
    <Card.Header className='pb-0 d-block d-sm-flex flex-wrap border-0 align-items-center'>
      <h4 className='mb-0 text-black fs-20'>Key Facts</h4>
    </Card.Header>
    <Card.Body className='pb-2'>
      <div className='d-flex justify-content-space-between align-items-center'>
        <div>
          <h4>Fund Size</h4>
          As of 01 Sep 2021
        </div>
        <div>1.74 Billion</div>
      </div>
      <hr />
      <div className='d-flex justify-content-space-between align-items-center'>
        <div>Minimum Investment</div>
        <div>PKR 10,000</div>
      </div>
      <hr />
      <div>
        <div>Benchmark</div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac
          nulla pharetra, gravida ex eu, ultrices magna. Aliquam pharetra dictum
          gravida. Nulla euismod scelerisque nisi ac mollis. Pellentesque semper
          vitae velit sed pulvinar. Suspendisse in tortor bibendum, rhoncus leo
          at, viverra arcu. In vulputate tincidunt neque non auctor. Praesent
          faucibus, metus eget lacinia efficitur, sem urna vehicula nisi, id
          convallis felis ex sed leo. Aenean ut consequat tortor.
        </p>
      </div>
    </Card.Body>
  </Card>
);

export default memo(KeyFacts);
