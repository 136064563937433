/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

export const useStatisticsData = (
  dataList: DynamicObjArray,
  name: string,
  dataKey: string,
  labelKey: string
) =>
  useMemo(
    () => ({
      data: dataList
        .filter((_v, index) => index % 5 === 0)
        .map(item => parseInt(item[dataKey] ?? 0)),
      labels: dataList
        .filter((_v, index) => index % 5 === 0)
        .map(item => item[labelKey] ?? ''),
      name
    }),
    [dataList, name, labelKey, dataKey]
  );

type DynamicObjArray = Array<Record<string, any>>;

export interface Data {
  data: Array<number>;
  labels: Array<number | string>;
  name: string;
}
