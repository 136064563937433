import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ContributionTableTopBar from './ContributionTableTopBar';
import {
  ContributionEmployee,
  EmployerContribution
} from 'redux/reducers/employer';

const ContributionTable = ({
  contributionEmployees,
  contributionEmployeesTotalRecords,
  employerContribution,
  setCurrentPage
}: ContributionTableProps) => {
  const [currentItems, setCurrentItems] = useState<Array<ContributionEmployee>>(
    contributionEmployees
  );
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setCurrentItems(contributionEmployees);
    setPageCount(Math.ceil(contributionEmployeesTotalRecords / itemsPerPage));
  }, [contributionEmployeesTotalRecords, contributionEmployees]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: Record<string, number>) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <ContributionTableTopBar employerContribution={employerContribution} />
      <div>
        <Items currentItems={currentItems} />
        {currentItems.length ? (
          <ReactPaginate
            activeClassName='active'
            breakClassName='page-item'
            breakLabel='...'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            disabledClassName='disabledBtn'
            marginPagesDisplayed={3}
            nextClassName='page-item'
            nextLabel={
              <i
                aria-hidden='true'
                className='fa fa-chevron-right cursor-pointer '
              />
            }
            nextLinkClassName='page-link'
            onPageChange={handlePageClick}
            pageClassName='page-item'
            pageCount={pageCount}
            pageLinkClassName='page-link'
            pageRangeDisplayed={3}
            previousClassName='page-item'
            previousLabel={
              <i
                aria-hidden='true'
                className='fa fa-chevron-left cursor-pointer '
              />
            }
            previousLinkClassName='page-link'
          />
        ) : null}
      </div>
    </>
  );
};

export default ContributionTable;

interface ContributionTableProps {
  employerContribution: Array<EmployerContribution>;
  contributionEmployees: Array<ContributionEmployee>;
  setCurrentPage: (page: number) => void;
  contributionEmployeesTotalRecords: number;
}
interface ItemsProps {
  currentItems: Array<ContributionEmployee>;
}
const Items = ({ currentItems }: ItemsProps) => {
  return (
    <div className='table-responsive '>
      <table className='table table-bordered table-striped'>
        <thead>
          <tr className='customContributionTableHeader'>
            <th>Employee Name</th>
            <th>Contribution</th>
            <th>%</th>
            <th>Matched Contributions</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody className='table'>
          {currentItems.map((contributionEmployee: ContributionEmployee) => {
            return (
              <tr key={contributionEmployee.id}>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {contributionEmployee.employee_name}
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {contributionEmployee.contribution.toLocaleString()} PKR
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {contributionEmployee.employee_contribution_percentage} %
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {contributionEmployee.matched_contribution.toLocaleString()}{' '}
                    PKR
                  </p>
                </td>
                <td className='fs-20'>
                  <p className='mb-0 fs-18'>
                    {contributionEmployee.employer_contribution_percentage} %
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
