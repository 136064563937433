import { useAppDispatch } from 'hooks/reduxHooks';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import { memo, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { EmployeData, onClearEmployerData } from 'redux/actions';
import {
  ProvidentFundProps,
  VPSBalanceDetailsProps
} from 'redux/reducers/employer';
import EmployerDetails from './EmployerDashboard/EmployerDetails';
import StackedColumn from './EmployerDashboard/StackedColumn';

const OccupationPensionDetails = ({
  VPSBalanceDetails,
  employeData,
  employeeProvidentFundDetails,
  formik
}: {
  employeData: EmployeData;
  employeeProvidentFundDetails: ProvidentFundProps;
  VPSBalanceDetails: VPSBalanceDetailsProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (employeData)
      formik.setFieldValue('salary', employeData.employeeContributionPercent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeData]);
  useEffect(() => {
    return () => {
      dispatch(onClearEmployerData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FadeIn>
      <Row>
        <Col lg={6}>
          <Card className='pb-2 ' style={{ height: 'auto' }}>
            <Card.Body>
              <Col lg={12}>
                <div className='w-100 pr-5 ml-3 fs-22'>
                  <strong>Your Details</strong>
                </div>
              </Col>
              <EmployerDetails employeData={employeData} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className='pb-2 ' style={{ height: 'auto' }}>
            <Card.Body className='p-0'>
              <Row>
                <Col className='mt-5 pb-2' lg={12}>
                  <StackedColumn
                    VPSBalanceDetails={VPSBalanceDetails}
                    employeeProvidentFundDetails={employeeProvidentFundDetails}
                    isExisting={true}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </FadeIn>
  );
};

export default memo(OccupationPensionDetails);
