import * as Yup from 'yup';

export const resetPassword = Yup.object({
  confirmPassword: Yup.string()
    .required()

    .oneOf([Yup.ref('new_password')], 'The both passwords do not match')
    .label('Password Confirmation'),
  new_password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])([A-Za-z\d]{6,})$/,
      'Password must contain at least 6 characters, one uppercase, one number'
    )
    .required()
    .min(6)
    .label('Password')
});
