import { useFormik } from 'formik';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import GoalForm from 'jsx/components/RiskProfile/GoalForm';
import KnowledgeForm from 'jsx/components/RiskProfile/KnowledgeForm';
import PersonalForm from 'jsx/components/RiskProfile/PersonalForm';
import RiskForm from 'jsx/components/RiskProfile/RiskForm';
import { FadeIn } from 'jsx/components/Shared/FadeIn';
import Loader from 'jsx/components/Shared/Loader';
import { memo, useCallback, useEffect } from 'react';
import { Button, Col, Row, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  onGetRiskProfileFields,
  onSendRiskProfile,
  onUpdateRiskProfile,
  RiskProfileSuccess,
  submitRiskProfileSuccess
} from 'redux/actions';
import { riskProfile } from 'validate/riskProfile';

const RiskProfile = ({
  setRiskFormFilled
}: {
  setRiskFormFilled?: (filled: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const { isRiskFormSuccess, isRiskProfileCompleted, user } = useAppSelector(
    state => state.auth
  );
  const { formData } = useAppSelector(state => state.riskProfile);
  const { loadingList } = useAppSelector(state => state.globalLoading);
  const loading = useAppLoading('riskProfile');
  const { pathname } = useLocation();
  const isPortolioPage = pathname === '/portfolio' ? true : false;
  const age =
    new Date().getFullYear() - new Date(user?.dob ?? '').getFullYear();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...formData.personalFormInitialValues,
      age,
      ...formData.investmentFormInitialValues,
      ...formData.goalFormInitialValues
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (values: Record<string, any>) => {
      const newValues = { ...values };
      for (const key of Object.keys(newValues)) {
        if (!newValues[key].length) {
          newValues[key] = '0';
        }
      }
      if (isRiskProfileCompleted) {
        dispatch(onUpdateRiskProfile(newValues));
        return;
      }
      dispatch(onSendRiskProfile(newValues));
    },
    validationSchema: riskProfile
  });

  const getInitialFormData = useCallback(() => {
    isRiskProfileCompleted && dispatch(onGetRiskProfileFields());
  }, [dispatch, isRiskProfileCompleted]);
  useEffect(() => {
    getInitialFormData();
  }, [getInitialFormData]);

  useEffect(() => {
    formik.status && dispatch(submitRiskProfileSuccess());
  }, [formik.status, dispatch]);
  useEffect(() => {
    if (isRiskFormSuccess) {
      setRiskFormFilled && setRiskFormFilled(true);

      // isRiskProfileCompleted &&
      toast.success(
        isRiskProfileCompleted
          ? 'Your risk profile is updated'
          : 'Your risk profile is completed'
      );
      dispatch(RiskProfileSuccess());
      dispatch(onGetRiskProfileFields());
    }
  }, [isRiskFormSuccess, dispatch, setRiskFormFilled, isRiskProfileCompleted]);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className='text-center'>
          <Loader width={200} />
        </div>
      ) : (
        <>
          <Card style={{ height: 100 }}>
            <Card.Body>
              <h2 className='text-center'>
                Let's find your portfolio based on your unique life
                circumstances
              </h2>
            </Card.Body>
          </Card>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={12}>
                <PersonalForm formik={formik} />
              </Col>

              {!!formik.getFieldProps('income_source').value && (
                <Col md={12}>
                  <FadeIn>
                    <KnowledgeForm formik={formik as never} />
                  </FadeIn>
                </Col>
              )}

              {!!formik.values.experience2.length && (
                <Col md={12}>
                  <FadeIn>
                    <GoalForm formik={formik as never} />
                  </FadeIn>
                </Col>
              )}

              {!!formik.getFieldProps('with_draw_years').value && (
                <Col md={12}>
                  <FadeIn>
                    <RiskForm formik={formik as never} />
                  </FadeIn>
                </Col>
              )}
            </Row>
            {!!formik.getFieldProps('with_draw_years').value && (
              <div className='text-right'>
                {!!loadingList.length ? (
                  <Button>
                    <Loader width={20} />
                  </Button>
                ) : (
                  <Button
                    disabled={Object.keys(formik.errors).length > 0}
                    type='submit'
                  >
                    {isRiskProfileCompleted ? (
                      'Update'
                    ) : isPortolioPage ? (
                      <>
                        Next <i className='fa fa-long-arrow-right mr-2' />
                      </>
                    ) : (
                      'PROCEED'
                    )}
                  </Button>
                )}
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};

export default memo(RiskProfile);
