import { AxiosResponse } from 'axios';
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
// import AccountConfirmation from 'jsx/pages/AccountConfirmation/AccountConfirmation';
import Error404 from 'jsx/pages/Error404';
import Forgot from 'jsx/pages/ForgotPassword';
import LoginPage from 'jsx/pages/Login';
import InviteDialog from 'jsx/pages/NewHome/InviteDialog';
import ResetPassword from 'jsx/pages/ResetPassword';
import Signup from 'jsx/pages/Signup';
import ValidateOTP from 'jsx/pages/ValidateOTP';
import { memo, useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  onResendPhoneCodeHanele,
  onSignUpPhoneCodeHanele,
  onResendEmailCode,
  updateUserEmail,
  ForgotParams,
  LoginParams,
  onClearSignupProcess,
  onForgotPassword,
  onLogin,
  onRegister,
  onSendCode,
  onSendPhoneOTP,
  RegisterParams,
  SendCodeParams,
  SendOTPParams,
  ResetPassParams,
  onResetPassword,
  onInvite,
  InviteParams,
  onGetRiskProfileFields
} from 'redux/actions';
import { name } from 'redux/reducers/auth';
import { links } from 'static/links';

const Login = () => {
  const [showInvite, setShowInvite] = useState(false);
  const openInvite = () => setShowInvite(true);
  const closeInvite = () => setShowInvite(false);
  const dispatch = useAppDispatch();
  const appLoading = useAppLoading(name);
  const { push } = useHistory();
  const {
    createUserId,
    isAuth,
    isRiskProfileCompleted,
    isValidOtp,
    msg,
    otpUserId,
    roleType,
    user
  } = useAppSelector(state => state.auth);
  const resetPasswordHandle = (data: ResetPassParams) => {
    dispatch(onResetPassword(data));
  };

  const forgotPasswordHandle = (data: ForgotParams) => {
    dispatch(updateUserEmail(data));
    dispatch(onForgotPassword({ email: data.email }));
  };
  const emailCodeHandle = (data: string) => dispatch(onResendEmailCode(data));
  const phoneCodeHanele = (data: string) =>
    dispatch(onResendPhoneCodeHanele(data));
  const signupResendCodeHandle = (data: string) => {
    dispatch(onSignUpPhoneCodeHanele(data));
  };
  const onLoginFail = (message: string, response: AxiosResponse) => {
    if (response?.data?.Message === 'Phone Number is Not Verified') {
      push(links.otpVerify);
    }
  };

  const loginHandle = (data: LoginParams) =>
    dispatch(onLogin(data, onLoginFail));
  const inviteHandle = (data: InviteParams) => dispatch(onInvite(data));
  const registerHandle = (data: RegisterParams) => {
    dispatch(updateUserEmail(data));
    dispatch(onRegister(data));
  };
  const sendOTPHandle = (data: SendOTPParams) => dispatch(onSendPhoneOTP(data));
  const sendCodeHandle = (data: SendCodeParams) => dispatch(onSendCode(data));
  const signupMsg =
    'A code has been sent to your phone number for activation, please activate to continue.';
  const forgotPassMsg =
    'An email has been sent to your account for password reset.';
  const otpSuccessMsg = 'OTP Verified';
  const resendOtpSuccessMsg = 'Phone OTP is sent';
  const inActiveUserMsg = 'You are not an active user.';
  const notify = useCallback(
    () =>
      msg?.length &&
      toast.success(msg, {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onClose: () => dispatch(onClearSignupProcess()),
        onOpen: () => {
          msg === signupMsg
            ? push({
                pathname: links.otpVerify,
                search: `email=${user?.email}`
              })
            : msg === forgotPassMsg
            ? push({
                pathname: links.resetPassword,
                search: user?.token
                  ? `email=${user?.email}&token=${user?.token}`
                  : `email=${user?.email}`
              })
            : msg === otpSuccessMsg
            ? push(links.confirmation)
            : msg === resendOtpSuccessMsg
            ? push({
                pathname: links.otpVerify,
                search: `email=${user?.email}`
              })
            : msg === inActiveUserMsg
            ? push(links.login)
            : push(links.login);
        },
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, msg, push]
  );
  useEffect(() => {
    notify();
  }, [notify]);

  useEffect(() => {
    if (!isValidOtp) {
      toast.error('Invalid email or Sms code', {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        onOpen: () => dispatch(onClearSignupProcess()),
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined
      });
    }
  }, [dispatch, isValidOtp]);

  useEffect(() => {
    isAuth && push(links.home);
    isAuth && roleType === 1 && push(links.employerDashboard);
    isAuth && isRiskProfileCompleted && dispatch(onGetRiskProfileFields());
  }, [dispatch, isAuth, push, roleType, isRiskProfileCompleted]);

  return (
    <div className='mh100vh'>
      <div>
        <div style={{ minHeight: window.screen.height - 60 }}>
          {showInvite && (
            <InviteDialog
              closeInvite={closeInvite}
              inviteHandle={inviteHandle}
            />
          )}
          <Switch>
            <Route
              exact
              path={links.signup}
              render={() => (
                <Signup
                  createUserId={createUserId}
                  loading={appLoading}
                  otpHandle={sendOTPHandle}
                  registerHandle={registerHandle}
                />
              )}
            />
            <Route
              exact
              path={links.resetPassword}
              render={() => (
                <ResetPassword
                  emailCodeHanele={emailCodeHandle}
                  loading={appLoading}
                  phoneCodeHanele={phoneCodeHanele}
                  resetPasswordHandle={resetPasswordHandle}
                />
              )}
            />
            <Route
              exact
              path={links.forgot}
              render={() => (
                <Forgot
                  forgotPasswordHandle={forgotPasswordHandle}
                  loading={appLoading}
                  openInvite={openInvite}
                />
              )}
            />
            <Route
              exact
              path={links.login}
              render={() => (
                <LoginPage
                  loading={appLoading}
                  loginHandle={loginHandle}
                  openInvite={openInvite}
                />
              )}
            />

            <Route
              exact
              path={links.otpVerify}
              render={() => (
                <ValidateOTP
                  createUserId={createUserId}
                  loading={appLoading}
                  otpUserId={otpUserId}
                  phoneCodeHanele={signupResendCodeHandle}
                  sendCodeHandle={sendCodeHandle}
                />
              )}
            />
            <Route
              path='*'
              render={() => <Error404 pageName='Login' url={links.login} />}
            />
          </Switch>
          <ToastContainer
            closeOnClick
            draggable
            pauseOnFocusLoss
            pauseOnHover
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            position='top-right'
            rtl={false}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Login);
