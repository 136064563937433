import 'bootstrap-daterangepicker/daterangepicker.css';
import { Card, Col, Row } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import './style.css';
const data = [
  { percent: '10', pv: 5.3, uv: 5, year: '1-yr' },
  { percent: 5, pv: 8.2, uv: 8, year: '2-yr' },
  { percent: 0, pv: 6.4, uv: 6, year: '3-yr' },
  { percent: -5, pv: -2.2, uv: -2, year: '4-yr' },
  { percent: -10, pv: -3.4, uv: -3, year: 'Since inception' }
];

const Performance = () => {
  return (
    <Card className='pb-2' style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='mb-0 text-black fs-20'>Performance</h4>
      </Card.Header>
      <Card.Body>
        <h4 style={{ lineHeight: '1.5rem' }}>Average annual returns</h4>
        <p>as of 12/12/2021</p>
        <Row>
          <Col className='d-flex align-items-center' sm={12}>
            <h5 className='mr-2 mt-2'>Show</h5>
            <div className='btn-group ml-1' role='group'>
              <button className='btn rounded-0 border' type='button'>
                Quarter-end
              </button>
              <button className='btn rounded-0 border' type='button'>
                Month-end
              </button>
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body className='pl-2'>
        <ResponsiveContainer height={250} width='100%'>
          <BarChart data={data} margin={{ left: -20 }}>
            <CartesianGrid strokeDasharray={1} vertical={false} />
            <XAxis dataKey='year' />
            <YAxis
              axisLine={{ stroke: '#fff' }}
              dataKey='percent'
              tickFormatter={value => `${value}%`}
            />

            <Bar dataKey='pv' fill='#6495ed'>
              <LabelList dataKey='pv' position='top' />
            </Bar>
            <Bar dataKey='uv' fill='#DDDDDD'>
              <LabelList color='red' dataKey='uv' position='top' />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};

export default Performance;
