import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import AuthPage from 'jsx/components/Auth/AuthWrapper';
import { FormEvent, memo, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory, useLocation } from 'react-router-dom';
import { SendCodeParams } from 'redux/actions';
import { links } from 'static/links';

const ValidateOTP = ({
  createUserId,
  loading,
  otpUserId,
  phoneCodeHanele,
  sendCodeHandle
}: ValidateOTPProps) => {
  const { search } = useLocation();
  const [otp, setOtp] = useState('');
  const [disableOTP, setDisableOTP] = useState(false);
  const { push } = useHistory();
  const email = search.slice(7);

  useEffect(() => {
    otpUserId && push(links.login);
  }, [otpUserId, push]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    otp.length === 4 && sendCodeHandle({ code: otp.toString() });
  };
  const handleResendOTP = () => {
    if (!disableOTP) {
      phoneCodeHanele(email);
      setDisableOTP(true);
      setTimeout(() => {
        setDisableOTP(false);
      }, 30000);
    }
  };
  return (
    <AuthPage title='Code Verification'>
      <p className='px20'>
        Please type verification code which is sent to your mobile number.
      </p>
      <form onSubmit={handleSubmit}>
        <div id='otp-field'>
          <OtpInput
            isDisabled={loading}
            numInputs={4}
            onChange={setOtp}
            separator={<span className='separator'>•</span>}
            value={otp}
          />
        </div>
        <div
          className='new-account mt-3 px20 resendText'
          onClick={handleResendOTP}
          style={{ cursor: disableOTP ? 'not-allowed' : 'pointer' }}
        >
          <p>Resend phone code</p>
        </div>
        <AuthSubmitButton disabled={loading} isAuth={true} title='Verify OTP' />
      </form>
    </AuthPage>
  );
};

export default memo(ValidateOTP);

interface ValidateOTPProps {
  createUserId: number | null;
  loading: boolean;
  otpUserId: null | number;
  sendCodeHandle: (data: SendCodeParams) => void;
  phoneCodeHanele: (email: string) => void;
}
