import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Country } from 'redux/reducers/sharedStateData';
import { genders, martialStatuses } from 'static/selection';
import { booleans } from 'static/selection/booleans';
import AppSelect from '../Shared/AppSelect';
import { FadeIn } from '../Shared/FadeIn';
import InputField from '../Shared/FormikInputField';
import RadioField from '../Shared/FormikRadioField';

const Personal = ({ countries, formik }: PersonalProps) => {
  return (
    <FadeIn>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12}>
              <h3 className='text-center text-primary'>Personal Details</h3>
            </Col>

            <InputField
              required
              col={4}
              formik={formik}
              label='Name'
              name='name'
              placeholder='Enter Name'
            />
            <InputField
              required
              col={4}
              formik={formik}
              label='Father / Spouse Name'
              name='father_name'
              placeholder='Enter Father / Spouse Name'
            />
            <InputField
              required
              col={4}
              disabled={true}
              formik={formik}
              label='Enter Email'
              name='email'
              placeholder='Enter Your Email'
              type='text'
            />
            <InputField
              required
              col={4}
              formik={formik}
              label='Phone Number'
              name='phone'
              placeholder='000000000'
            />

            <InputField
              required
              col={4}
              disabled={true}
              formik={formik}
              label='Preferred Contact Number'
              name='PreferredContactNumber'
              placeholder='000000000'
            />
            <InputField
              required
              col={4}
              disabled={false}
              formik={formik}
              label='Office Number'
              name='OfficeNumber'
              placeholder='000000000'
            />
            <InputField
              required
              col={4}
              formik={formik}
              label='Date Of Birth'
              name='date_of_birth'
              placeholder=''
              type='Date'
            />
            <RadioField
              required
              singleLine
              col={4}
              fieldName='gender'
              fields={genders}
              formik={formik}
              label='Select Gender'
            />
            <RadioField
              required
              singleLine
              col={4}
              fieldName='marital_status'
              fields={martialStatuses}
              formik={formik}
              label='Select Martial Status'
            />
            <RadioField
              required
              singleLine
              col={4}
              fieldName='USAPerson'
              fields={booleans}
              formik={formik}
              label='USA National'
            />
            <AppSelect
              fieldSelect
              col={4}
              disabled={false}
              fieldName='resident_country'
              fields={countries}
              formik={formik}
              label='Resident Country'
              onChange={(val, option) => {
                formik.setFieldValue('resident_country', val);
                formik.setFieldValue(
                  'isOtherResidentCountry',
                  option.label === 'Other'
                );
              }}
              placeholder='Select Resident Country'
              required={true}
              value={formik.values.resident_country}
            />
            {countries.find(
              el => Number(formik.values.resident_country) === Number(el.value)
            )?.label === 'Other' && (
              <InputField
                required
                col={4}
                formik={formik}
                label='(Other Resident Country)'
                name='other_resident_country'
                placeholder=''
              />
            )}
            <AppSelect
              fieldSelect
              col={4}
              disabled={false}
              fieldName='native_country'
              fields={countries}
              formik={formik}
              label='Native Country'
              onChange={(val, option) => {
                formik.setFieldValue('native_country', val);
                formik.setFieldValue(
                  'isOtherNativeCountry',
                  option.label === 'Other'
                );
              }}
              placeholder='Select Native Country'
              value={formik.values.native_country}
            />
            {countries.find(
              el => Number(formik.values.native_country) === Number(el.value)
            )?.label === 'Other' && (
              <InputField
                required
                col={4}
                formik={formik}
                label='(Other Native Country)'
                name='other_native_country'
                placeholder=''
              />
            )}
            <InputField
              required
              col={4}
              formik={formik}
              label='First Nationality'
              name='Nationality'
              placeholder='Enter First Nationality'
            />
            <InputField
              required
              col={4}
              formik={formik}
              label='Second Nationality'
              name='Nationality2'
              placeholder='Enter Second Nationality'
            />
          </Row>
        </Card.Body>
      </Card>
    </FadeIn>
  );
};

export default memo(Personal);

interface PersonalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  countries: Array<Country>;
}
