import { Dropdown } from 'react-bootstrap';
import { memo } from 'react';

const DropDown = ({
  activeClass = '',
  children,
  subTitle,
  title,
  titleStyle = {}
}: DropDownProps) => {
  return (
    <Dropdown as='li' className='nav-item dropdown header-profile'>
      <Dropdown.Toggle
        as='a'
        className={`${activeClass} nav-link i-false c-pointer`}
        data-toggle='dropdown'
        role='button'
        style={titleStyle}
      >
        <div className='header-info'>
          <span className='text-center'>{title}</span>
          {subTitle && <small className='text-center'>{subTitle}</small>}
        </div>
        <Dropdown.Menu align='right' className='mt-2 dropmenu'>
          {children}
        </Dropdown.Menu>
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default memo(DropDown);

interface DropDownProps {
  activeClass?: string;
  children: JSX.Element;
  subTitle?: number | string;
  title: string;
  titleStyle?: Record<string, string | number>;
}
