/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps } from 'formik';
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { investmentReasons, withDrawYears } from 'static/selection';
import { monthlySavingBool } from 'static/selection/MonthlySavingBool';
import InputField from '../Shared/FormikInputField';
import RadioField from '../Shared/FormikRadioField';
import SelectField from '../Shared/FormikSelectField';

const GoalForm = ({ formik }: { formik: FormikProps<any> }) => (
  <Card>
    <Card.Body>
      <Row>
        <Col lg={12}>
          <h3 className='text-center text-warning mb-3'>Goals</h3>
        </Col>
        <SelectField
          isMulti
          required
          col={4}
          fieldName='investment_reason'
          fields={investmentReasons}
          formik={formik}
          label='Investment Reason'
          placeholder='Select Multiple reasons'
        />

        <RadioField
          required
          singleLine
          col={4}
          fieldName='monthly_saving_bool'
          fields={monthlySavingBool}
          formik={formik}
          label='Would you be interested in monthly savings'
        />
        {formik.values?.monthly_saving_bool === '41' && (
          <InputField
            // required
            col={4}
            formik={formik}
            label='Monthly Saving'
            name='monthly_saving'
            placeholder='1000'
          />
        )}

        <SelectField
          required
          col={4}
          fieldName='with_draw_years'
          fields={withDrawYears}
          formik={formik}
          label='Plan to Withdraw After'
          placeholder='Select Years ...'
        />
      </Row>
    </Card.Body>
  </Card>
);

export default memo(GoalForm);
