const Footer = () => {
  const d = new Date();
  return (
    <div className='footer'>
      <div className='copyright'>
        <p>Mahana {d.getFullYear()}</p>
      </div>
    </div>
  );
};

export default Footer;
