import { memo } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart as Chart,
  ResponsiveContainer,
  Sector,
  Tooltip
} from 'recharts';

const COLORS = ['#aaaaaa', '#8884d8', '#666666'];

const PieChart = ({ data, selection }: PieChartProps) => {
  return (
    <ResponsiveContainer aspect={2} width='100%'>
      <Chart>
        <Legend />
        <Pie
          activeIndex={selection?.active}
          activeShape={renderActiveShape}
          cx='50%'
          cy='50%'
          data={data}
          dataKey='value'
          outerRadius={100}
          startAngle={-270}
        >
          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={COLORS[index]}
              onClick={() => selection?.setActive(index)}
            />
          ))}
        </Pie>
        <Tooltip />
      </Chart>
    </ResponsiveContainer>
  );
};

export default memo(PieChart);

interface Selection {
  active: number;
  setActive: (active: number) => void;
}

type Shape = { name: string; value: number };
interface PieChartProps {
  data: Array<Shape>;
  selection?: Selection;
  heading?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    endAngle,
    fill,
    innerRadius,
    midAngle,
    outerRadius,
    payload,
    startAngle,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 15}
        startAngle={startAngle}
      />
      <circle cx={ex} cy={ey} fill={fill} r={2} stroke='none' />
      <text
        fill='#333'
        textAnchor={textAnchor}
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
      >{`${payload.name} ${value}%`}</text>
    </g>
  );
};
