import { memo } from 'react';

import profile05 from 'images/profile/5.jpg';
import profile06 from 'images/profile/6.jpg';
import profile07 from 'images/profile/7.jpg';

const LatestNews = () => (
  <div className='card'>
    <div className='card-header border-0 pb-0'>
      <h5 className='text-black'>Our Latest News</h5>
    </div>
    <div className='card-body pt-3'>
      <div className='profile-news'>
        <div className='media pt-3 pb-3'>
          <img alt='' className='mr-3 rounded' src={profile05} width={75} />
          <div className='media-body'>
            <h5 className='m-b-5'>
              <span className='text-black'>Lorem ipsum dolor sit</span>
            </h5>
            <p className='mb-0'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
        <div className='media pt-3 pb-3'>
          <img alt='' className='mr-3 rounded' src={profile06} width={75} />
          <div className='media-body'>
            <h5 className='m-b-5'>
              <span className='text-black'>Lorem ipsum dolor sit</span>
            </h5>
            <p className='mb-0'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
        <div className='media pt-3 '>
          <img alt='' className='mr-3 rounded' src={profile07} width={75} />
          <div className='media-body'>
            <h5 className='m-b-5'>
              <span className='text-black'>Lorem ipsum dolor sit</span>
            </h5>
            <p className='mb-0'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default memo(LatestNews);
