import { memo } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './account.confirmation.css';

const AccountConfirmation = () => {
  const history = useHistory();

  const handleLogin = () => {
    history.push('/auth/login');
  };
  return (
    <Container className='ConfirmationContainer'>
      <Row className='ConfirmationRow'>
        <Col>
          <Card className='ConfirmationCard'>
            <Card.Body>
              <div className='ConfirmationContentDiv'>
                <span className='ConfirmationContentIcon'>
                  <i aria-hidden='true' className='fa fa-check-circle-o'></i>
                </span>
                <span>
                  <h3>Thank you for confirming you account</h3>
                </span>
                <span>
                  <p>You can now sign in with your account</p>
                </span>
                <span className='ConfirmationContentButton'>
                  <button
                    className='btn btn-primary btn-block'
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(AccountConfirmation);
