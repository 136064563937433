/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Field } from 'jsx/components/Shared/FormikSelectField';

const initialState: InitialState = {
  banksList: []
};

const name = 'payment';
const paymentSlice = createSlice({
  initialState,
  name,
  reducers: {
    banksListSuccess: (state, action: PayloadAction<any>) => {
      const banks: Array<Field> = [];
      action.payload.forEach((item: bankProps) => {
        banks.push({ label: item.name, value: item.id.toString() });
      });
      state.banksList = banks;
    }
  }
});

export default paymentSlice.reducer;

//? APIS ACTIONS

export const onGetBanksList = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'PaymentOption/GetBanks'
  },
  type: 'banksList'
});

export const onBankTransfer = (data: bankTransferPost) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'PaymentOption/BankTransfer'
  },
  type: 'bankTransfer'
});

// ? Types

export interface bankProps {
  id: number;
  name: string;
}

interface InitialState {
  banksList: Array<Field>;
}

export interface bankTransferPost {
  AccTitle: string;
  AccNumber: string;
  BankId: number;
}
