import LinkListRow from 'jsx/components/Dashboard/LinkListRow';
import { AvailableGoalsProps } from 'redux/actions';
import { links } from 'static/links';
import styles from './dashboard.module.css';

const WelcomeScreen = ({
  availableGoals,
  userName
}: {
  userName: string;
  availableGoals?: Array<AvailableGoalsProps>;
}) => {
  return (
    <div>
      <div className={styles.hero_section}>
        <div className={styles.hero_section_content}>
          <h1 className={styles.hero_heading}>Welcome {userName}</h1>
          <p className={styles.hero_text}>
            Lets make most from your money together
          </p>
        </div>
      </div>
      <div className={styles.links_container}>
        <div className={styles.links_list}>
          <div style={{ color: 'black', padding: '1% 3%' }}>
            <h3>Let's Start Investing</h3>
          </div>

          {availableGoals?.map(el => {
            return (
              <>
                <LinkListRow
                  description={el.goal_type_description}
                  goal_id={el.id}
                  iconClass='fa fa-umbrella fa-2x text-primary'
                  link={
                    el.id === 1
                      ? {
                          pathname: links.portfolio,
                          state: { goal_id: el.id }
                        }
                      : {
                          pathname: links.portfolio,
                          search: '?role=employer',
                          state: { goal_id: el.id }
                        }
                  }
                  title={el.goal_type_short_description}
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
