import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  conservative: {},
  created_goal_id: null,
  employeData: {
    accumulated_benefit: '',
    avg_tax: '',
    basic_salary: '',
    current_accumulated_value: '',
    current_gross_monthly_salary: '',
    employeeContributionPercent: '',
    employeecontributions_todate: '',
    employer_contribution: '',
    employer_contribution_percentage: '',
    employer_id: '',
    employer_name: '',
    mahaana_pension_account_amount: '',
    outstanding_employer_loan: '',
    plan_amount: '',
    retirement_benefit: ''
  },

  graphData: { data: [], final: {}, initial: {} },
  message: '',
  totalPortfolio: {
    available_to_invest: 0,
    today_change: 0,
    today_change_perc: 0,
    total_account: 0,
    total_change: 0,
    total_change_perc: 0,
    total_portfolio: 0
  }
};

const name = 'portfolio';
const portfolioSlice = createSlice({
  initialState,
  name,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addGoalSuccess: (state, action: PayloadAction<any>) => {
      state.created_goal_id = action.payload.id;
    },
    clearEmployerData: state => {
      state.message = '';
      state.employeData = {
        accumulated_benefit: '',
        avg_tax: '',
        basic_salary: '',
        current_accumulated_value: '',
        current_gross_monthly_salary: '',
        employeeContributionPercent: '',
        employeecontributions_todate: '',
        employer_contribution: '',
        employer_contribution_percentage: '',
        employer_id: '',
        employer_name: '',
        mahaana_pension_account_amount: '',
        outstanding_employer_loan: '',
        plan_amount: '',
        retirement_benefit: ''
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    employerFail: (state, action: PayloadAction<any>) => {
      state.message = action.payload.message;
      state.employeData = {
        ...state.employeData,
        accumulated_benefit: '',
        avg_tax: '',
        current_accumulated_value: '',
        current_gross_monthly_salary: '',
        employeeContributionPercent: '',
        employeecontributions_todate: '',
        employer_contribution: '',
        employer_contribution_percentage: '',
        employer_name: '',
        mahaana_pension_account_amount: '',
        outstanding_employer_loan: '',
        plan_amount: '',
        retirement_benefit: ''
      };
    },
    employerSuccess: (state, action: PayloadAction<EmployeData>) => {
      const {
        accumulated_benefit,
        avg_tax,
        basic_salary,
        current_accumulated_value,
        current_gross_monthly_salary,
        employeecontributions_todate,
        employer_contribution,
        employer_contribution_percentage,
        employer_id,
        employer_name,
        mahaana_pension_account_amount,
        outstanding_employer_loan,
        plan_amount,
        retirement_benefit
      } = action.payload;
      state.employeData = {
        accumulated_benefit,
        avg_tax,
        basic_salary,
        current_accumulated_value,
        current_gross_monthly_salary,
        employeeContributionPercent: `${
          (parseInt(employer_contribution_percentage) *
            parseInt(current_gross_monthly_salary)) /
          100
        }`,
        employeecontributions_todate,
        employer_contribution,
        employer_contribution_percentage,
        employer_id,
        employer_name,
        mahaana_pension_account_amount,
        outstanding_employer_loan,
        plan_amount,
        retirement_benefit
      };
      state.message = '';
    },
    graphSuccess: (state, action: PayloadAction<PortfolioGraphDataSuccess>) => {
      const { Data, Final, Initial } = action.payload;
      state.graphData.data = Data.filter((_, i) => i % 5 === 0);
      state.graphData.final = Final;
      state.graphData.initial = Initial;
    }
  }
});
export const { clearEmployerData: onClearEmployerData } =
  portfolioSlice.actions;
export default portfolioSlice.reducer;

//? APIS ACTIONS

export const onPostPorfolio = (data: PostPortfolioData) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'Dashboard/InsertPortfolio'
  },
  type: 'post'
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onAddGoal = (data: addGoalProps | Record<string, any>) => ({
  payload: {
    apiName: name,

    data,
    method: 'post',
    url: 'Goal/SaveGoal'
  },
  type: 'addGoal'
});

export const onGetEmployer = (cnic: number, userId?: number) => ({
  payload: {
    apiName: name,
    data: { cnic, userId },
    method: 'get',
    url: `Employeer/GetEmployeeData?cnic=${cnic}`
  },
  type: 'employer'
});

//? Types

interface InitialState {
  message: string;
  conservative: Record<string, never> | Conservative;
  graphData: PortfolioGraphData;
  totalPortfolio: TotalPortfolio;
  employeData: EmployeData;
  created_goal_id: number | null;
}
export interface addGoalProps {
  goal_type_id: number;
  initial_amount: number;
  age: number;
  monthly_amount: number;
  goal_amount: number;
}
export interface EmployeData {
  success?: boolean;
  employer_id: string;
  employer_name: string;
  accumulated_benefit: string;
  avg_tax: string;
  basic_salary: string;
  current_accumulated_value: string;
  current_gross_monthly_salary: string;
  employeeContributionPercent: string;
  employeecontributions_todate: string;
  employer_contribution: string;
  mahaana_pension_account_amount: string;
  outstanding_employer_loan: string;
  employer_contribution_percentage: string;
  plan_amount: string;
  retirement_benefit: string;
}
export interface PortfolioGraphData {
  data: Array<GraphData>;
  final: Record<string, never> | Final;
  initial: Record<string, never> | Initial;
}

interface GraphData {
  amount: number;
  age: number;
  unrealized: number;
  vol: number;
  bonds: Array<Record<string, string | number>>;
  stocks: Array<Record<string, string | number>>;
}

interface Final {
  age: number;
  amount: number;
}

interface Initial {
  amount: number;
}

export interface PortfolioGraphDataSuccess {
  Data: Array<GraphData>;
  Final: Final;
  Initial: Initial;
}

export interface PostPortfolioData {
  dob: string;
  liquid_worth: string;
  monthly_exp: string;
  pre_tax_income: string;
}

interface TotalPortfolio {
  available_to_invest: number;
  today_change: number;
  today_change_perc: number;
  total_account: number;
  total_change: number;
  total_change_perc: number;
  total_portfolio: number;
}

export interface Conservative {
  bonds: number;
  data: GraphData;
  stocks: number;
  type: string;
}
