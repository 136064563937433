import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import HomeContainer from 'jsx/components/Dashboard/HomeContainer';
import WelcomeScreen from 'jsx/components/Dashboard/WelcomeScreen';
import Loader from 'jsx/components/Shared/Loader';
import { memo, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  onGetAvailableGoals,
  onGetGoals,
  onGetMonthWiseNavValues,
  onGetTotalGainLoss,
  onGetUserFundAllocation
} from 'redux/actions';
import SideBar from './SideBar';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.auth);
  const { availableGoals, chartLoading, completedGoals, totalGainLossLoading } =
    useAppSelector(state => state.dashboard);
  const loading = useAppLoading('dashboard');
  const getInitialData = useCallback(() => {
    if (completedGoals?.length) {
      dispatch(onGetTotalGainLoss());
      dispatch(onGetMonthWiseNavValues(0));
      dispatch(onGetUserFundAllocation(completedGoals[0].goal_id));
    }

    dispatch(onGetGoals());
    dispatch(onGetAvailableGoals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, completedGoals?.length]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  const handleMonthChartFilterClick = (value: number) => {
    dispatch(onGetMonthWiseNavValues(value));
  };
  return (
    <>
      {(loading && !chartLoading) || totalGainLossLoading ? (
        <div className='text-center'>
          <Loader width={200} />
        </div>
      ) : completedGoals.length === 0 ? (
        <WelcomeScreen
          availableGoals={availableGoals}
          userName={user?.user_name ?? ''}
        />
      ) : (
        <>
          <Row>
            <Col lg={3}>
              <SideBar />
            </Col>
            <Col lg={9}>
              <HomeContainer
                handleMonthChartFilterClick={handleMonthChartFilterClick}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(Dashboard);
