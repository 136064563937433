import { memo } from 'react';

import profile08 from 'images/profile/8.jpg';
import profile09 from 'images/profile/9.jpg';

const Content = () => (
  <div className='card'>
    <div className='card-body'>
      <div className='profile-tab'>
        <div className='custom-tab-1'>
          <div className='tab-content'>
            <div id='my-posts'>
              <div className='profile-uoloaded-post border-bottom-1 pb-5'>
                <img alt='' className='img-fluid' src={profile08} />
                <span className='post-title'>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam egestas in tortor luctus suscipit. Praesent egestas
                  enim sed velit hendrerit, non vestibulum augue maximus. Sed
                  commodo ipsum vitae efficitur ullamcorper. Aenean varius
                  accumsan turpis vitae malesuada.
                </p>
                <button className='btn btn-primary mr-2'>
                  <span className='mr-2'></span>
                  Read More
                </button>
              </div>
              <div className='profile-uoloaded-post border-bottom-1 pb-5'>
                <img alt='' className='img-fluid' src={profile09} />
                <span className='post-title'>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam egestas in tortor luctus suscipit. Praesent egestas
                  enim sed velit hendrerit, non vestibulum augue maximus. Sed
                  commodo ipsum vitae efficitur ullamcorper. Aenean varius
                  accumsan turpis vitae malesuada.
                </p>
                <button className='btn btn-primary mr-2'>
                  <span className='mr-2'></span>
                  Read More
                </button>
              </div>
              <div className='profile-uoloaded-post pb-3'>
                <img alt='' className='img-fluid' src={profile08} />
                <span className='post-title'>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam egestas in tortor luctus suscipit. Praesent egestas
                  enim sed velit hendrerit, non vestibulum augue maximus.
                </p>
                <button className='btn btn-primary mr-2'>
                  <span className='mr-2'></span>
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default memo(Content);
