/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  data: [],
  formData: {
    goalFormInitialValues: {
      investment_reason: [],
      monthly_saving: '',
      monthly_saving_bool: '',
      with_draw_years: ''
    },
    investmentFormInitialValues: {
      experience: '',
      experience2: [],
      investment_plan: ''
    },
    personalFormInitialValues: {
      age: '',
      current_insurance: [],
      dependents: '',
      education: '',
      income_source: '',
      income_source_stability: '',
      income_source_sub: [],
      income_source_sub_other: '',
      investment_lost_options: '',
      market_down_options: '',
      martial_status: '',
      net_cash: '',
      occupation: '',
      other_education: '',
      other_occupation: '',
      tax: ''
    }
  }
};

const name = 'riskProfile';
const riskProfileSlice = createSlice({
  initialState,
  name,
  reducers: {
    getFormSuccess: (state, action: PayloadAction<any>) => {
      if (action?.payload?.Data) {
        const data = JSON.parse(action.payload.Data);
        const {
          age,
          current_insurance,
          dependents,
          education,
          experience,
          experience2,
          income_source,
          income_source_stability,
          income_source_sub,
          income_source_sub_other,
          investment_lost_options,
          investment_plan,
          investment_reason,
          market_down_options,
          martial_status,
          monthly_saving,
          monthly_saving_bool,
          net_cash,
          occupation,
          other_education,
          other_occupation,
          tax,
          with_draw_years
        } = data;
        state.formData.personalFormInitialValues = {
          ...state.formData.personalFormInitialValues,
          age,
          current_insurance,
          dependents,
          education,
          income_source,
          income_source_stability,
          income_source_sub: income_source_sub,
          income_source_sub_other: income_source_sub_other,
          investment_lost_options,
          market_down_options,
          martial_status,
          net_cash,
          occupation,
          other_education: other_education === 0 ? null : other_education,
          other_occupation: other_occupation === 0 ? null : other_occupation,
          tax
        };
        state.formData.investmentFormInitialValues = {
          experience,
          experience2,
          investment_plan
        };
        state.formData.goalFormInitialValues = {
          investment_reason,
          monthly_saving,
          monthly_saving_bool,
          with_draw_years
        };
      }
    }
  }
});
export const { getFormSuccess } = riskProfileSlice.actions;
export default riskProfileSlice.reducer;

//? APIS ACTIONS

export const onGetRiskProfileFields = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'RiskProfile/GetUserRiskProfile'
  },
  type: 'getForm'
});

// ? Types

interface InitialState {
  data: Array<string>;
  formData: Record<string, any>;
}
