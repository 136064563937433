import { Card } from 'react-bootstrap';

const PortfolioComposition = () => {
  return (
    <Card className='pb-2' style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='mb-0 text-black fs-20'>Portfolio composition</h4>
      </Card.Header>
      <Card.Body className='pb-0'>
        <h4 style={{ lineHeight: '1.5rem' }}>
          Distribution by credit quality(% of fund) as of 12/12/2021
        </h4>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th
                  style={{
                    background: '#dcc8c0'
                  }}
                ></th>
                <th
                  style={{
                    background: '#dcc8c0',
                    borderLeft: '2px solid white',
                    color: 'black'
                  }}
                >
                  Inter-Term Bond Index Adm
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>U.S. Government</td>
                <td className='text-right'>12.02%</td>
              </tr>
              <tr>
                <td>AAA</td>
                <td className='text-right'>12.02%</td>
              </tr>{' '}
              <tr>
                <td>AA</td>
                <td className='text-right'>12.02%</td>
              </tr>{' '}
              <tr>
                <td>A</td>
                <td className='text-right'>12.02%</td>
              </tr>{' '}
              <tr>
                <td>BBB</td>
                <td className='text-right'>12.02%</td>
              </tr>{' '}
              <tr>
                <td>NR</td>
                <td className='text-right'>12.02%</td>
              </tr>{' '}
              <tr>
                <th>Total</th>
                <td className='text-right'>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PortfolioComposition;
