import { links } from 'static/links';

export const employerSideTabs = [
  { key: 1, name: 'Dashboard', url: links.employerDashboard },
  { key: 2, name: 'Contribution', url: links.employerContributions },
  { key: 3, name: 'Employees', url: links.dashboardEmployees },
  { key: 3, name: 'Investments', url: links.investments }
];
export interface EmployerSideTabs {
  key: number;
  name: string;
}
