import * as Yup from 'yup';

export const ChecqueCollectionFormSchema = Yup.object({
  address: Yup.string().required().label('Address'),
  bank: Yup.string().required().label('Bank'),
  email: Yup.string().email().required().label('Email'),
  instruction: Yup.string().required().label('Instruction'),
  name: Yup.string().required().label('Name'),
  phoneNumber: Yup.string()
    .required()
    .matches(new RegExp(/^[\d ()+-]+$/), 'Invalid phone number')
    .label('Phone Number')
});
