import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import WelcomeScreen from 'jsx/components/Dashboard/WelcomeScreen';
import { memo, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { onGetGoals } from 'redux/actions';
import SideBar from './SideBar';
import RiskProfile from './RiskProfile';

const RiskScore = () => {
  const dispatch = useAppDispatch();
  const { goals, user } = useAppSelector(state => state.auth);
  const { completedGoals } = useAppSelector(state => state.dashboard);

  const getInitialData = useCallback(() => {
    dispatch(onGetGoals());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  return (
    <>
      {parseInt(goals) <= 0 && completedGoals.length === 0 ? (
        <WelcomeScreen userName={user?.user_name ?? ''} />
      ) : (
        <>
          <Row>
            <Col lg={3}>
              <SideBar />
            </Col>
            <Col lg={9}>
              <RiskProfile />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(RiskScore);
