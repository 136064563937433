import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  MonthwiseNavValues: [],
  availableGoals: [],
  chartLoading: false,
  completedGoals: [],
  graphData: [],
  graphFinal: null,
  graphInitial: null,
  investmentList: [],
  message: '',
  totalGainLoss: [],
  totalGainLossLoading: false,
  totalPortfolio: {
    available_to_invest: 0,
    today_change: 0,
    today_change_perc: 0,
    total_account: 0,
    total_change: 0,
    total_change_perc: 0,
    total_portfolio: 0
  }
};

const name = 'dashboard';
const dashboardSlice = createSlice({
  initialState,
  name,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AddFundsSuccess: (state, action: PayloadAction<any>) => {
      state.message = action.payload.Message;
    },
    MonthwiseNavValues: state => {
      state.chartLoading = true;
    },
    MonthwiseNavValuesFail: state => {
      state.MonthwiseNavValues = [];
      state.chartLoading = false;
    },
    MonthwiseNavValuesSuccess: (
      state,
      action: PayloadAction<MonthwiseValuesPayload>
    ) => {
      const data = JSON.parse(action.payload.data);
      state.chartLoading = false;
      state.MonthwiseNavValues = data
        .sort(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any, b: any) =>
            new Date(a.month).valueOf() - new Date(b.month).valueOf()
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((value: any) => ({
          ...value
        }));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OccupationalGoalSuccess: (state, action: PayloadAction<any>) => {
      state.message = action.payload.Message;
    },
    TotalGainLoss: state => {
      state.totalGainLossLoading = true;
      state.totalGainLoss = [];
    },
    TotalGainLossSuccess: (
      state,
      action: PayloadAction<TotalGainLossPayload>
    ) => {
      const data = JSON.parse(action.payload.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state.totalGainLoss = data.map((value: any) => ({
        ...value
      }));
      state.totalGainLossLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    VPSGoalSuccess: (state, action: PayloadAction<any>) => {
      state.message = action.payload.Message;
    },

    clearDashboardNotifications: state => {
      state.message = '';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteGoalsSuccess: (state, action: PayloadAction<any>) => {
      state.message = action.payload.Message;
    },

    goalsListSuccess: (state, action: PayloadAction<AvailableGoalsPayload>) => {
      const data = JSON.parse(action.payload.Data);
      state.availableGoals = data.map((value: AvailableGoalsProps) => ({
        ...value
      }));
    },
    goalsSuccess: (state, action: PayloadAction<GoalsPayload>) => {
      if (action.payload.status_code === 200) {
        const data = JSON.parse(action.payload.data);
        state.completedGoals = data;
        state.message = '';
        return;
      }
      state.completedGoals = [];
      state.message = '';
    },
    // graphSuccess: (state, action: PayloadAction<GraphPayload>) => {
    //   state.graphData = action.payload.Data.map(value => ({
    //     ...value,
    //     vol: parseInt(value.vol.toFixed(0))
    //   }));
    // },
    gridsSuccess: (state, action: PayloadAction<GridsPayload>) => {
      const {
        available_to_invest,
        today_change,
        today_change_perc,
        total_account,
        total_change,
        total_change_perc,
        total_portfolio
      } = action.payload.Total_portfolio;
      state.totalPortfolio = {
        available_to_invest,
        today_change,
        today_change_perc,
        total_account,
        total_change,
        total_change_perc,
        total_portfolio
      };
    },

    investmentListSuccess: (
      state,
      action: PayloadAction<InvestmentListPayload>
    ) => {
      state.investmentList = action.payload.Data.map(value => ({
        ...value
      }));
    }
  }
});
export const { clearDashboardNotifications: onClearDashboardNotifications } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;

//? APIS ACTIONS

export const onGetGridsData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'dashboard/GetUserTotalStocks'
  },
  type: 'grids'
});

export const onGetInvestmentList = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'dashboard/GetInvestmentList'
  },
  type: 'investmentList'
});

export const onGetGoals = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Goal/GetUserGoals'
  },
  type: 'goals'
});
export const onGetAvailableGoals = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Goal/GetGoalsList'
  },
  type: 'goalsList'
});

export const onAddVPSGoal = (data: VPSGoalParams) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'BankFundsAllocation/SaveUserFundInvestments'
  },
  type: 'VPSGoal'
});
export const onAddOccupationalGoal = (data: OccupationalGoalParams) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'BankFundsAllocation/SaveUserFundInvestments'
  },
  type: 'OccupationalGoal'
});

export const onAddFunds = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'BankApi/AddFunds'
  },
  type: 'AddFunds'
});

export const onDeleteGoal = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'delete',
    url: 'Goal/DeleteGoal'
  },
  type: 'deleteGoals'
});

export const onGetMonthWiseNavValues = (interval: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Dashboard/GetMonthlyGrowthRate?interval=${interval}`
  },
  type: 'MonthwiseNavValues'
});

export const onGetTotalGainLoss = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Dashboard/GetTotalGainLoss'
  },
  type: 'TotalGainLoss'
});
// ? Types
export interface VPSGoalParams {
  goal_id: number;
  fund_investment_type_id: number;
}
export interface OccupationalGoalParams {
  goal_id: number | null;
  fund_investment_type_id: number;
}
interface GoalsPayload {
  data: string;
  status_code: number;
}
interface InitialState {
  totalPortfolio: TotalPortfolio;
  graphFinal: HeadingValue | null;
  graphInitial: HeadingValue | null;
  graphData: Array<GraphData>;
  investmentList: Array<InvestmentData>;
  completedGoals: Array<CompletedGoalsProps>;
  chartLoading: boolean;
  availableGoals: Array<AvailableGoalsProps>;
  message: string;
  MonthwiseNavValues: Array<MonthwiseValuesProps>;
  totalGainLoss: Array<TotalGainLossProps>;
  totalGainLossLoading: boolean;
}
export interface AvailableGoalsProps {
  id: number;
  goal_type_description: string;
  goal_type_short_description: string;
}
interface AvailableGoalsPayload {
  Success: boolean;
  Data: string;
}
interface TotalGainLossPayload {
  Message: boolean;
  StatusCode: number;
  data: string;
}
export interface TotalGainLossProps {
  total_gain_loss: string;
  gain_loss: string;
  total_value: string;
}
export interface MonthwiseValuesProps {
  Month: string;
  NavValue: string;
}
export interface CompletedGoalsProps {
  goal_id: number;
  goal_type_id: number;
  user_id: number;
  initial_amount: number;
  monthly_amount: number;
  age: number;
  goal_type_description: string;
  title: string;
  balance: number;
}
export interface goalProps {
  goal_id?: number;
  age?: number;
  balance?: number;
  goal?: number;
  title?: string;
  goal_type_id?: number;
}
export interface InvestmentData {
  amount: number;
  close_value: number;
  gain: number;
  in_perc: number;
  market_v: number;
  name: string;
  symbol: string;
  units: number;
  valid_date: string;
}
export interface GraphData {
  age: number;
  unrealized: number;
  vol: number;
}
interface InvestmentListPayload {
  Data: Array<InvestmentData>;
}
interface MonthwiseValuesPayload {
  data: string;
}
// interface GraphPayload {
//   Data: Array<GraphData>;
//   Final: HeadingValue;
//   Initial: HeadingValue;
// }

interface GridsPayload {
  Total_portfolio: TotalPortfolio;
}

interface HeadingValue {
  age: number;
  amount: number;
  date: string;
}

interface TotalPortfolio {
  available_to_invest: number;
  today_change: number;
  today_change_perc: number;
  total_account: number;
  total_change: number;
  total_change_perc: number;
  total_portfolio: number;
}
