import { memo, useState } from 'react';

import profile from 'images/favicon.png';
import ContactModal from './ContactModal';
import LatestArticle from './LatestArticle';
import LatestNews from './LatestNews';
import Statistics from './Statistics';
import Content from './Content';

const Learn = () => {
  const [sendMessage, setSendMessage] = useState(false);

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='profile card card-body px-3 pt-3 pb-0'>
            <div className='profile-head'>
              <div className='photo-content'>
                <div className='cover-photo'></div>
              </div>
              <div className='profile-info'>
                <div className='profile-photo'>
                  <img
                    alt='profile'
                    className='img-fluid rounded-circle'
                    src={profile}
                    width={100}
                  />
                </div>
                <div className='profile-details'>
                  <div className='profile-name px-3 pt-2'>
                    <h4 className='text-primary mb-0'>Mahaana</h4>
                    <p>Blogs, Articles, News</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-4'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='profile-statistics'>
                    <Statistics setSendMessage={setSendMessage} />
                    {sendMessage && (
                      <ContactModal setSendMessage={setSendMessage} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12'>
              <LatestArticle title='Latest Article' />
            </div>
            <div className='col-lg-12'>
              <LatestNews />
            </div>
            <div className='col-lg-12'>
              <LatestArticle title='Hot Blog' />
            </div>
          </div>
        </div>
        <div className='col-xl-8'>
          <Content />
        </div>
      </div>
    </>
  );
};

export default memo(Learn);
