import { useAppSelector } from 'hooks/reduxHooks';
import { memo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PieChart from '../Shared/PieChart';
import FundDetailsModal from './FundDetailsModal';
const HomeExposure = () => {
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentProps | null>(null);
  const { riskPortfolioData } = useAppSelector(state => state.pieChartData);
  return (
    <>
      <Card className='pb-0' style={{ height: 'auto' }}>
        <Card.Body className='pb-0'>
          <Row>
            <Col lg={12}>
              <h3>Your Exposure Overview</h3>
            </Col>
            <Col lg={8}>
              <h5 className='text-align-center'>Asset Class</h5>
              <PieChart data={riskPortfolioData} />
            </Col>
            <Col lg={4}>
              <h5>Investments Class</h5>
              <ul className='widht-70'>
                {riskPortfolioData.map((item, index) => {
                  return (
                    <>
                      <li
                        key={index}
                        className='HomeAccountList'
                        onClick={e => setSelectedInvestment(item)}
                      >
                        <span>{item.name} </span>
                        <span className='float-right'> {item.value}% </span>
                      </li>
                    </>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <FundDetailsModal
        selectedInvestment={selectedInvestment}
        setSelectedInvestment={setSelectedInvestment}
      />
    </>
  );
};
export default memo(HomeExposure);
export interface InvestmentProps {
  list: Array<listProps>;
  name: string;
  value: number;
}
interface listProps {
  amount: number;
  close_value: number;
  gain: number;
  in_perc: number;
  market_v: number;
  name: string;
  symbol: string;
  units: number;
  valid_date: string;
}
