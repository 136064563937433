import { Button, Modal } from 'react-bootstrap';
import { memo } from 'react';

const ContactModal = ({
  setSendMessage
}: {
  setSendMessage: (val: boolean) => void;
}) => (
  <Modal className='modal fade' show={true}>
    <div className='modal-content'>
      <div className='modal-header'>
        <h5 className='modal-title'>Contact Us</h5>
        <Button
          className='close'
          data-dismiss='modal'
          onClick={() => setSendMessage(false)}
          type='button'
          variant=''
        >
          <span>×</span>
        </Button>
      </div>
      <div className='modal-body'>
        <form
          className='comment-form'
          onSubmit={e => {
            e.preventDefault();
            setSendMessage(false);
          }}
        >
          <div className='row'>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='text-black font-w600' htmlFor='author'>
                  {' '}
                  Name <span className='required'>*</span>{' '}
                </label>
                <input
                  className='form-control'
                  defaultValue='Author'
                  name='Author'
                  placeholder='Your Name'
                  type='text'
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='text-black font-w600' htmlFor='email'>
                  {' '}
                  Email <span className='required'>*</span>
                </label>
                <input
                  className='form-control'
                  defaultValue='Email'
                  name='Email'
                  placeholder='Email'
                  type='text'
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='text-black font-w600' htmlFor='comment'>
                  Description
                </label>
                <textarea
                  className='form-control'
                  defaultValue={''}
                  name='comment'
                  placeholder='Comment'
                  rows={8}
                />
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-group'>
                <input
                  className='submit btn btn-primary'
                  name='submit'
                  type='submit'
                  value='Send'
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Modal>
);

export default memo(ContactModal);
