import { memo } from 'react';
import HomeAccountsContainer from './HomeAccountsContainer';
import HomeExposure from './HomeExposure';
import HomeGraph from './HomeGraph';
import HomeTopGrid from './HomeTopGrid';

const HomeContainer = ({ handleMonthChartFilterClick }: HomeContainerProps) => {
  return (
    <>
      <HomeTopGrid />
      <HomeGraph handleMonthChartFilterClick={handleMonthChartFilterClick} />
      <HomeAccountsContainer />
      <HomeExposure />
    </>
  );
};

export default memo(HomeContainer);

interface HomeContainerProps {
  handleMonthChartFilterClick: (value: number) => void;
}
