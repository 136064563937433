/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikProps } from 'formik';
import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  education,
  martialStatuses,
  occupation,
  sources
} from 'static/selection';
import { multipleSources } from 'static/selection/MultipleSources';
import InputField from '../Shared/FormikInputField';
import RadioField from '../Shared/FormikRadioField';
import SelectField from '../Shared/FormikSelectField';

const PersonalForm = ({ formik }: { formik: FormikProps<any> }) => {
  const formValues = formik.values || null;
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col lg={12}>
            <h3 className='text-center text-info mb-3'>Personal Information</h3>
          </Col>
          <InputField
            autoFocus
            required
            col={3}
            formik={formik}
            label='Annual taxable income'
            name='tax'
            placeholder='Enter annual taxable income '
          />
          <InputField
            required
            col={3}
            formik={formik}
            label='No. of dependents'
            name='dependents'
            placeholder='Enter no. of dependents'
          />
          <InputField
            required
            col={3}
            formik={formik}
            label='Current cash/saving certificates'
            name='net_cash'
            placeholder='Enter Current cash'
          />
          <RadioField
            required
            singleLine
            col={3}
            fieldName='martial_status'
            fields={martialStatuses}
            formik={formik}
            label='Marital Status'
          />
          <InputField
            disabled
            required
            col={3}
            formik={formik}
            label='Your age (in years)'
            name='age'
            placeholder='Enter age'
          />

          <SelectField
            required
            col={3}
            fieldName='occupation'
            fields={occupation}
            formik={formik}
            label='Occupation'
          />

          {formValues.occupation === '11' && (
            <InputField
              // required
              col={3}
              formik={formik}
              label='(Other Occupation)'
              name='other_occupation'
              placeholder=''
            />
          )}

          <SelectField
            required
            col={3}
            fieldName='education'
            fields={education}
            formik={formik}
            label='Education'
          />

          {formValues.education === '16' && (
            <InputField
              // required
              col={3}
              formik={formik}
              label='(Other Education)'
              name='other_education'
              placeholder=''
            />
          )}

          <RadioField
            required
            singleLine
            col={3}
            fieldName='income_source'
            fields={sources}
            formik={formik}
            label='Source of income'
          />

          {formValues.income_source === '7' && (
            <SelectField
              col={3}
              fieldName='income_source_sub'
              fields={multipleSources}
              formik={formik}
              isMulti={true}
              label='Select Multiple Sources'
            />
          )}
          {formValues.income_source_sub?.find((item: string) => {
            return item === '5' ? true : false;
          }) && (
            <InputField
              // required
              col={3}
              formik={formik}
              label='(Other Source of income)'
              name='income_source_sub_other'
              placeholder=''
            />
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default memo(PersonalForm);
