import SimpleReactLightbox from 'simple-react-lightbox';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { withResizeDetector } from 'react-resize-detector';
import AppErrors from 'jsx/containers/AppErrors';
import Routes from 'jsx/routes';
import ThemeContextProvider from 'context/ThemeContext';
import { store, persistor } from 'redux/store';

import 'vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'css/style.css';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SimpleReactLightbox>
        <ThemeContextProvider>
          <AppErrors />
          <Router>
            <Routes />
          </Router>
        </ThemeContextProvider>
      </SimpleReactLightbox>
    </PersistGate>
  </Provider>
);

export default withResizeDetector(App);
