import { memo, useCallback, useEffect, useState } from 'react';
import Types from 'jsx/components/Portfolio/Types';
import WizardForm from 'jsx/components/Portfolio/WizardForm';
import { addGoalProps, onAddGoal, onGetAccountProfile } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';
import { useLocation } from 'react-router-dom';

const Portfolio = () => {
  const [pansion, setPansion] = useState(true);
  const [goalDetails, setGoalDetails] = useState({});
  const [active, setActive] = useState(1);
  const dispatch = useAppDispatch();
  const { conservative, graphData } = useAppSelector(state => state.portfolio);
  const { isRiskProfileCompleted } = useAppSelector(state => state.auth);
  const loading = useAppLoading('portfolio');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: Record<string, any> = useLocation();
  const goal_id = location?.state?.goal_id;
  const submitPortfolioHandle = (data: addGoalProps) => {
    setGoalDetails(data);
  };
  useEffect(() => {
    if ((isRiskProfileCompleted && active === 3) || active === 4) {
      dispatch(onAddGoal(goalDetails));
    }
  }, [active, dispatch, isRiskProfileCompleted, goalDetails]);
  const getInitialData = useCallback(() => {
    dispatch(onGetAccountProfile());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  return (
    <>
      {!pansion ? (
        <Types loading={loading} setPansion={() => setPansion(true)} />
      ) : (
        <WizardForm
          active={active}
          conservative={conservative}
          goal_id={goal_id}
          graphData={graphData}
          isRiskProfileCompleted={!!isRiskProfileCompleted}
          loading={loading}
          setActive={setActive}
          submitPortfolioHandle={submitPortfolioHandle}
        />
      )}
      {/* <PortfolioComponent />} */}
    </>
  );
};

export default memo(Portfolio);
