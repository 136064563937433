import { Link, useLocation } from 'react-router-dom';
import { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';

import AuthInputField from 'jsx/components/Shared/FormikInputField';
import AuthPage from 'jsx/components/Auth/AuthWrapper';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import { LoginParams, onConfirmUserInvitation } from 'redux/actions';
import { links } from 'static/links';
import { login } from 'validate/auth/login';
import { useAppDispatch } from 'hooks/reduxHooks';

const Login = ({ loading, loginHandle, openInvite }: LoginProps) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: (values: LoginParams) => {
      loginHandle(values);
    },
    validationSchema: login
  });
  const confirmEmployeeInvitation = useCallback(() => {
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if (token) {
      dispatch(onConfirmUserInvitation({ token }));
    }
  }, [dispatch, search]);
  useEffect(() => {
    confirmEmployeeInvitation();
  }, [confirmEmployeeInvitation]);

  return (
    <AuthPage title='Sign in your account'>
      <form onSubmit={formik.handleSubmit}>
        <AuthInputField
          required
          disabled={loading}
          formik={formik}
          label='Email'
          name='email'
          placeholder='hello@example.com'
        />
        <AuthInputField
          required
          disabled={loading}
          formik={formik}
          label='Password'
          name='password'
          placeholder='••••••••'
          type='password'
        />
        <div className='form-row d-flex px20 justify-content-end mt-4 mb-2'>
          <div className='form-group'>
            <Link to={links.forgot}>Forgot password?</Link>
          </div>
        </div>
        <AuthSubmitButton disabled={loading} isAuth={true} title='Sign Me In' />
      </form>
      <div className='new-account mt-3 px20'>
        <p>
          Don't have an account?
          <span
            className='text-primary ml-1 cursor-pointer'
            onClick={openInvite}
          >
            Sign up
          </span>
        </p>
      </div>
    </AuthPage>
  );
};

export default memo(Login);

interface LoginProps {
  loading: boolean;
  loginHandle: (data: LoginParams) => void;
  openInvite: () => void;
}
