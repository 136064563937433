import { Card } from 'react-bootstrap';

const YieldTable = () => {
  return (
    <Card className='pb-2' style={{ height: 'auto' }}>
      <Card.Header className='pb-0 border-0'>
        <h4 className='mb-0 text-black fs-20'>Price and yeild</h4>
      </Card.Header>
      <Card.Body className='pb-0'>
        <div className='table-responsive'>
          <table className='table'>
            <tbody>
              <tr>
                <td>Price 89 of 1029032</td>

                <td>PKR 12</td>
              </tr>
              <tr>
                <td>Change</td>
                <td>
                  <span className='text-primary'>0 PKR + </span>
                  <span className='text-primary'>0.17% +</span>
                </td>
              </tr>
              <tr>
                <td className='text-primary '>
                  {' '}
                  30 day yield as of 12/12/2021
                </td>
                <td>
                  1.67% <span className='text-primary'>A</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default YieldTable;
