import { memo } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import Loader from '../Shared/Loader';

const Types = ({ loading, setPansion }: PensionTypesProps) => {
  if (loading)
    return (
      <Card>
        <Card.Body>
          <div className='m-5 text-center'>
            <Loader width={100} />
          </div>
        </Card.Body>
      </Card>
    );
  return (
    <Card>
      <Card.Header>
        <Card.Title>Where do you want to start?</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <i className='fa fa-pagelines fa-2x text-primary' />
            <h3 className='mt-3 mb-3'>Pension</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque consectetur aliquam nisi, eget semper libero
              facilisis vitae. Fusce mattis augue id nisl fermentum, nec pretium
              eros elementum. Etiam nisi lacus, consequat pharetra mollis sit
              amet, fringilla egestas velit. Vestibulum velit massa, scelerisque
              nec arcu at, aliquet egestas est. Maecenas at varius ex,
              vestibulum dapibus elit. Aliquam erat volutpat. Fusce scelerisque
              convallis erat, lobortis vestibulum velit interdum eget. Curabitur
              et erat feugiat, vehicula turpis ultricies, bibendum lacus.
              Maecenas malesuada fringilla dictum.
            </p>
            <Button className='btn-lg' onClick={() => setPansion()}>
              Start With Pansion
            </Button>
          </Col>
          <Col md={6}>
            <i className='fa fa-leaf fa-2x text-info' />
            <h3 className='mt-3 mb-3'>Saving</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque consectetur aliquam nisi, eget semper libero
              facilisis vitae. Fusce mattis augue id nisl fermentum, nec pretium
              eros elementum. Etiam nisi lacus, consequat pharetra mollis sit
              amet, fringilla egestas velit. Vestibulum velit massa, scelerisque
              nec arcu at, aliquet egestas est. Maecenas at varius ex,
              vestibulum dapibus elit. Aliquam erat volutpat. Fusce scelerisque
              convallis erat, lobortis vestibulum velit interdum eget. Curabitur
              et erat feugiat, vehicula turpis ultricies, bibendum lacus.
              Maecenas malesuada fringilla dictum.
            </p>
            <Button disabled className='btn-lg'>
              Start With Saving
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default memo(Types);

interface PensionTypesProps {
  loading: boolean;
  setPansion: () => void;
}
