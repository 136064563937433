import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import HeaderText from 'jsx/components/PaymentOptions/HeaderText';
import PaymentOptionsConent from 'jsx/components/PaymentOptions/PaymentOptionsConent';
import Loader from 'jsx/components/Shared/Loader';
import { memo, useCallback, useEffect } from 'react';
import {
  bankTransferPost,
  onBankTransfer,
  onGetBanksList
} from 'redux/actions';

const PaymentOptions = () => {
  const dispatch = useAppDispatch();
  const { banksList } = useAppSelector(state => state.payment);

  const loading = useAppLoading('payment');

  const handleSubmitBankTransfer = (data: bankTransferPost) => {
    dispatch(onBankTransfer(data));
  };

  const getInitialData = useCallback(() => {
    dispatch(onGetBanksList());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  if (loading) return <Loader />;

  return (
    <>
      <HeaderText />
      <PaymentOptionsConent
        banksList={banksList}
        submitBankTransfer={handleSubmitBankTransfer}
      />
    </>
  );
};

export default memo(PaymentOptions);
