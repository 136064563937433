import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  ProvidentFundProps,
  VPSBalanceDetailsProps
} from 'redux/reducers/employer';

const StackedColumn = ({
  VPSBalanceDetails,
  employeeProvidentFundDetails,
  isExisting
}: {
  employeeProvidentFundDetails: ProvidentFundProps;
  VPSBalanceDetails: VPSBalanceDetailsProps;
  isExisting: boolean;
}) => {
  const { TaxCreditSum, VPSBalance } = VPSBalanceDetails;
  const contribution =
    parseInt(employeeProvidentFundDetails?.MonthlyContribution ?? '0') +
    parseInt(employeeProvidentFundDetails?.PFValue ?? '0');
  const data = {
    options: {
      chart: {
        stacked: true,
        toolbar: { show: false },
        type: 'bar'
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      grid: { show: false },
      plotOptions: { bar: { horizontal: false } },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '14px'
        }
      },
      xaxis: {
        axisTicks: { show: false },
        categories: isExisting
          ? ['Existing', 'With Mahaana']
          : ['With Mahaana'],
        type: 'string'
      },
      yaxis: { axisBorder: { show: true }, labels: { show: false } }
    },
    series: [
      {
        data: isExisting ? [contribution, VPSBalance] : [VPSBalance],
        name: 'CONTRIBUTION'
      },
      {
        data: isExisting ? [0, TaxCreditSum] : [TaxCreditSum],
        name: 'TAX BENEFIT'
      },
      {
        data: isExisting
          ? [0, parseInt(employeeProvidentFundDetails.ReturnValue)]
          : [parseInt(employeeProvidentFundDetails.ReturnValue)],
        name: 'RETURN'
      }
    ]
  };
  return (
    <ReactApexChart
      height={342}
      options={data.options}
      series={data.series}
      type='bar'
      width={600}
    />
  );
};

export default memo(StackedColumn);
