import { useFormik } from 'formik';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import AuthPage from 'jsx/components/Auth/AuthWrapper';
import AuthInputField from 'jsx/components/Shared/FormikInputField';
import AuthDatePickerField from 'jsx/components/Shared/FormikDatePicker';
import { memo, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { onGetUserInvitationData, RegisterParams } from 'redux/actions';
import { links } from 'static/links';
import { signup } from 'validate/auth/signup';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

const Signup = ({
  createUserId,
  loading,
  otpHandle,
  registerHandle
}: SignupProps) => {
  const { invitationDetails } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  useEffect(() => {
    createUserId && history.push(links.otpVerify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserId]);

  const getInitialData = useCallback(() => {
    dispatch(onGetUserInvitationData(params.get('hash')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cnic: '',
      companyName: invitationDetails.is_employer
        ? invitationDetails.company_name
        : '',
      dob: '',
      email: invitationDetails.email,
      firstname: invitationDetails.first_name,
      is_employer: invitationDetails.is_employer,
      lastname: invitationDetails.last_name,
      password: '',
      phone: ''
    },
    onSubmit: ({
      cnic,
      companyName,
      dob,
      email,
      firstname,
      is_employer,
      lastname,
      password,
      phone
    }) => {
      registerHandle({
        cnic,
        company_name: companyName,
        dob,
        email,
        firstname,
        is_employer,
        lastname,
        password,
        phone,
        token: params.get('hash')
      });
    },
    validationSchema: signup
  });
  const handleDateChange = (date: Date) => {
    formik.setFieldValue('dob', date);
  };
  return (
    <AuthPage title='Sign up your account'>
      <form onSubmit={formik.handleSubmit}>
        <AuthInputField
          required
          disabled={true}
          formik={formik}
          label='First Name'
          name='firstname'
          placeholder='John'
        />
        <AuthInputField
          required
          disabled={true}
          formik={formik}
          label='Last Name'
          name='lastname'
          placeholder='Doe'
        />
        <AuthInputField
          disabled
          required
          formik={formik}
          label='Email'
          name='email'
          placeholder='hello@example.com'
        />
        <AuthInputField
          required
          bottomInfo='Please enter CNIC without dashes'
          formik={formik}
          label='CNIC'
          name='cnic'
          placeholder='0000000000000'
        />
        <AuthInputField
          required
          disabled={loading}
          formik={formik}
          label='Password'
          name='password'
          placeholder='••••••••'
          type='password'
        />
        <AuthInputField
          required
          disabled={loading}
          formik={formik}
          label='Confirm Password'
          name='confirmPassword'
          placeholder='••••••••'
          type='password'
        />

        <AuthInputField
          disabled={loading}
          formik={formik}
          label='Phone Number'
          name='phone'
          placeholder='03xxxxxxxxxx'
          type='text'
        />
        <AuthDatePickerField
          required
          formik={formik}
          handleDateChange={handleDateChange}
          label='Date of Birth'
          name='dob'
        />
        {invitationDetails.is_employer && (
          <AuthInputField
            disabled={true}
            formik={formik}
            label='Company Name'
            name='companyName'
            placeholder='Enter Company Name'
            type='text'
          />
        )}
        <AuthSubmitButton
          disabled={loading || Object.keys(formik.errors).length > 0}
          isAuth={true}
          loading={loading}
          title='Sign Me Up'
        />
      </form>
      <div className='new-account px20 mt-3'>
        <p>
          Already have an account?{' '}
          <Link className='text-primary' to={links.login}>
            Sign in
          </Link>
        </p>
      </div>
    </AuthPage>
  );
};

export default memo(Signup);

interface SignupProps {
  createUserId: number | null;
  loading: boolean;
  registerHandle: (data: RegisterParams) => void;
  otpHandle: (data: RegisterParams) => void;
}
