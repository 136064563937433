import { memo, ReactChild } from 'react';

import logo from 'images/logo.png';
import { Link } from 'react-router-dom';
import { links } from 'static/links';

const AuthPage = ({ children, title }: AuthPageProps) => (
  <div className='authincation h-100 p-meddle'>
    <div className='container h-100'>
      <div className='row justify-content-center h-100 align-items-center'>
        <div className='col-md-6'>
          <div className='authincation-content'>
            <div className='row no-gutters'>
              <div className='col-xl-12'>
                <div className='auth-form'>
                  <div className='text-center mb-3'>
                    <Link to={links.login}>
                      <img alt='Mahaana' src={logo} />
                    </Link>
                  </div>
                  <h4 className='text-center mb-4 '>{title}</h4>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default memo(AuthPage);
interface AuthPageProps {
  children: ReactChild | ReactChild[];
  title: string;
}
