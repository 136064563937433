import { memo } from 'react';

import ChartContainer from 'jsx/components/Pension/ChartContainer';
import Loader from 'jsx/components/Shared/Loader';
import { useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';
// import { onGetPensionData } from 'redux/actions';

const Pension = () => {
  // const dispatch = useAppDispatch();
  const loading = useAppLoading('pension');
  const { data, graphFinal, graphInitial } = useAppSelector(
    state => state.pension
  );

  // const loadData = useCallback(() => {
  //   dispatch(onGetPensionData());
  // }, [dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  if (loading)
    return (
      <div className='text-center p-5'>
        <Loader width={100} />
      </div>
    );
  return (
    <ChartContainer
      data={data}
      heading={graphInitial?.amount?.toFixed(0) ?? ''}
      title={
        graphFinal
          ? `${graphFinal.amount?.toFixed(0)} net worth at ${graphFinal.age}`
          : ''
      }
    />
  );
};

export default memo(Pension);
