import { memo } from 'react';

import Loader from 'jsx/components/Shared/Loader';

const AuthSubmitButton = ({
  disabled = false,
  isAuth = false,
  loading,
  title
}: AuthSubmitButtonProps) => (
  <div className='col-md-12 text-center'>
    <button
      className={
        isAuth ? 'btn btn-primary  btn-block' : 'btn btn-primary h-38 btn-block'
      }
      disabled={disabled}
      type='submit'
    >
      {loading ? <Loader width={20} /> : title}
    </button>
  </div>
);

export default memo(AuthSubmitButton);

interface AuthSubmitButtonProps {
  disabled?: boolean;
  title: string;
  isAuth?: boolean;
  loading?: boolean;
}
