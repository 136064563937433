/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDateYMDFormat } from 'utils/getDateDMYFormat';

const initialState: InitialState = {
  IsAuthorized: false,
  accessToken: null,
  bankAccountProcessStarted: false,
  createUserId: null,
  firstTransactionCompleted: false,
  goals: '',
  invitationDetails: {
    company_name: '',
    email: '',
    first_name: '',
    is_employer: false,
    last_name: ''
  },
  isAuth: false,
  isConfirmationSent: false,
  isRiskFormSuccess: false,
  isRiskProfileCompleted: '0',
  isSetUpComplete: false,
  isValidOtp: true,
  msg: '',
  otpUserId: null,
  otpVerified: false,
  roleType: null,
  user: null,
  userAccountData: {
    accountValues: {
      beneficial_owner: 'no',
      send_account_statement: 'yes',
      zakat_deduction: 'no'
    },
    addressValues: {
      city: '',
      country: '',
      primary_address: '',
      resident_country: '',
      state: '',
      tax_residence: ''
    },
    cnicValues: {
      cnic_back_image: '',
      cnic_expiry_date: '',
      cnic_front_image: '',
      cnic_number: '',
      isCNIC: false,
      isLifeTimeCNIC: false
    },

    financialValues: {
      AnnualIncome: '',
      income_source_id: 1,
      occupation: '',
      occupation_id: '',
      otherOccupation: '',
      other_income: ''
    },
    personalValues: {
      CountryOfBirth: '',
      Nationality: '',
      Nationality2: '',
      OfficeNumber: '',
      PreferredContactNumber: '',
      USAPerson: 'no',
      date_of_birth: '',
      email: '',
      father_name: '',
      gender: '1',
      marital: '1',
      name: '',
      phone: '',
      resident_country: ''
    }
  }
};

export const name = 'auth';
const authSlice = createSlice({
  initialState,
  name,
  reducers: {
    RiskProfileSuccess: state => {
      state.isRiskFormSuccess = false;
    },
    accountConfirmationSuccess: state => {
      state.msg = 'Your account is activated successfully.';
    },
    clearSignupProcess: state => {
      state.msg = '';
      state.otpUserId = null;
      state.createUserId = null;
      state.isValidOtp = true;
    },
    forgotSuccess: state => {
      state.msg = 'An email has been sent to your account for password reset.';
    },
    getAccountSetupSuccess: (state, action: PayloadAction<any>) => {
      const {
        address,
        annual_income,
        beneficial_owner,
        city,
        cnic_back_image,
        cnic_expiry_date,
        cnic_front_image,
        cnic_name,
        cnic_number,
        country,
        date_of_birth,
        first_nationality,
        gender,
        income_source_id,
        is_usa_resident,
        marital_status,
        native_country,
        occupation_id,
        office_number,
        other_city,
        other_country,
        other_native_country,
        other_resident_country,
        other_state,
        phone_number,
        resident_country,
        second_nationality,
        send_account_statement,
        states,
        tax_residence,
        zakat_deduction
      } = action.payload.Data;
      state.userAccountData.personalValues = {
        ...state.userAccountData.personalValues,
        Nationality: first_nationality,
        Nationality2: second_nationality,
        OfficeNumber: office_number,
        PreferredContactNumber: '',
        USAPerson: is_usa_resident ? 'yes' : 'no',
        date_of_birth: getDateYMDFormat(date_of_birth),
        father_name: '',
        gender: gender === 'M' ? `1` : '2',
        marital_status: marital_status ? `17` : '18',
        name: '',
        native_country,
        other_native_country: other_native_country,
        other_resident_country: other_resident_country,
        phone: phone_number,
        resident_country: resident_country
      };
      state.userAccountData.cnicValues = {
        cnic_back_image,
        cnic_expiry_date: getDateYMDFormat(cnic_expiry_date),
        cnic_front_image,
        cnic_number,
        isCNIC: !!cnic_name ? !!cnic_name : false
      };
      state.userAccountData.financialValues = {
        AnnualIncome: annual_income,
        income_source_id: income_source_id,
        occupation_id
      };
      state.userAccountData.addressValues = {
        city,
        country: country,
        other_city: other_city,
        other_country: other_country,
        other_state: other_state,
        primary_address: address,
        resident_country,
        states,
        tax_residence
      };
      state.userAccountData.accountValues = {
        beneficial_owner: beneficial_owner ? 'yes' : 'no',
        send_account_statement: send_account_statement ? 'yes' : 'no',
        zakat_deduction: zakat_deduction ? 'yes' : 'no'
      };
    },

    invitationSuccess: (state, action: PayloadAction<any>) => {
      state.msg =
        'Thank you for sharing your details, we will get in touch when it is your turn on the wait list.';
    },
    inviteConfirmationSuccess: state => {
      state.isConfirmationSent = true;
    },

    loginSuccess: (state, action: PayloadAction<LoginSuccessPayload>) => {
      if (!action.payload.Data.success) {
        state.msg = action.payload.Data.message;
        return;
      }
      const {
        api_key,
        bank_account_process_started,
        dob,
        email,
        first_transaction_completed,
        goals,
        is_authorized,
        is_risk_profile_completed,
        is_setup_complete,
        phone,
        role_type,
        user_id,
        user_name
      } = action.payload.Data;
      state.isAuth = true;

      state.user = {
        dob,
        email,
        phone,
        token: '',
        user_id,
        user_name
      };
      state.isRiskProfileCompleted = is_risk_profile_completed;
      state.accessToken = api_key;
      state.roleType = parseInt(role_type);
      state.firstTransactionCompleted = first_transaction_completed;
      state.bankAccountProcessStarted = bank_account_process_started;
      state.IsAuthorized = is_authorized;
      state.goals = goals;
      state.isSetUpComplete = is_setup_complete;
    },
    logout: state => {
      state.accessToken = null;
      state.user = null;
      state.isAuth = false;
      state.isRiskProfileCompleted = false;
    },
    registerSuccess: (state, action: PayloadAction<any>) => {
      state.createUserId = action.payload.id;
      state.msg =
        'A code has been sent to your phone number for activation, please activate to continue.';
    },
    resendEmailCodeSuccess: state => {
      state.msg = 'An email has been sent to your account for password reset.';
    },
    resendPhoneCodeSuccess: (state, action: PayloadAction<any>) => {
      state.msg = action.payload.PhoneOTP;
    },
    resetPasswordSuccess: (state, action: PayloadAction<any>) => {
      if (action.payload.Success) {
        state.msg = 'Your Password is changed successfully.';
        state.isValidOtp = true;
      } else {
        state.isValidOtp = false;
      }
    },
    sendCodeSuccess: state => {
      state.otpUserId = null;
      state.msg = 'OTP Verified';
      state.otpVerified = true;
    },
    sendOTPSuccess: () => {
      // state.createUserId = action.payload.id;
    },
    submitAccountSetupSuccess: (state, action: PayloadAction<any>) => {
      state.isSetUpComplete = true;
      state.msg = action.payload.Message;
    },
    submitRiskProfileSuccess: state => {
      state.isRiskProfileCompleted = true;
      state.isRiskFormSuccess = true;
    },
    updateUserEmail: (state, action: PayloadAction<any>) => {
      const { email, token } = action.payload;
      if (token) {
        state.user = {
          email,
          token
        };
        return;
      }
      state.user = {
        email
      };
    },
    userInvitationDataSuccess: (state, action: PayloadAction<any>) => {
      const { company_name, email, first_name, is_employer, last_name } =
        action.payload;
      state.invitationDetails = {
        company_name,
        email,
        first_name,
        is_employer,
        last_name
      };
    }
  }
});

export const {
  RiskProfileSuccess,
  clearSignupProcess: onClearSignupProcess,
  loginSuccess: setCurrentLogin,
  logout: onLogout,
  submitRiskProfileSuccess,
  updateUserEmail
} = authSlice.actions;

export default authSlice.reducer;

//? APIS ACTIONS
export const onLogin = (
  data: LoginParams,
  onFail: (msg: string, response: any) => void
) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onFail,
    onSuccess: setCurrentLogin,
    url: 'SignIn/UserSignin'
  },
  type: 'login'
});

export const onInvite = (data: InviteParams) => ({
  payload: {
    apiName: name,
    data,
    headers: { Authorization: process.env.REACT_APP_INVITE_TOKEN },
    method: 'post',
    url: 'UserInvitation/CreateUserInvitation'
  },
  type: 'invitation'
});
export const onConfirmUserInvitation = (data: ConfirmTokenParams) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'UserInvitation/ConfirmEmplyeeInvitation'
  },
  type: 'confirmUserInvitation'
});

export const onGetUserInvitationData = (token: string | null) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `SignUp/GetUserInvitationData?token=${token}`
  },
  type: 'userInvitationData'
});

export const onRegister = (data: RegisterParams) => ({
  payload: { apiName: name, data, method: 'post', url: 'SignUp/UserSignUp' },
  type: 'register'
});

export const onForgotPassword = (data: ForgotParams) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'SignIn/SendEmailForResetPswd'
  },
  type: 'forgot'
});
export const onResendEmailCode = (data: string) => ({
  payload: {
    apiName: name,
    data: { email: data },
    method: 'post',
    url: 'SignIn/ResendEmailCode'
  },
  type: 'resendEmailCode'
});
export const onResendPhoneCodeHanele = (data: string) => ({
  payload: {
    apiName: name,
    data: { email: data },
    method: 'post',
    url: 'SignIn/ResendSmsCode'
  },
  type: 'resendPhoneCode'
});
export const onSignUpPhoneCodeHanele = (data: string) => ({
  payload: {
    apiName: name,
    data: { email: data },
    method: 'post',
    url: 'SignUp/ResendOTP'
  },
  type: 'resendPhoneCode'
});

export const onResetPassword = (data: ResetPassParams) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'SignIn/ResetPassword'
  },
  type: 'resetPassword'
});

export const onSendPhoneOTP = (data: SendOTPParams) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `User/RegisterPhonenum?phonenumber=${data.phone}`
  },
  type: 'sendOTP'
});

export const onSendCode = ({ code }: SendCodeParams) => ({
  payload: {
    apiName: name,
    data: { code },
    method: 'post',
    url: `SignUp/VerifyPhoneOTP`
  },
  type: 'sendCode'
});

export const onGetAccountProfile = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `User`
  },
  type: 'getAccountSetup'
});
export const onSubmitAccountSetup = (data: Record<string, any>) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    url: 'User'
  },
  type: 'submitAccountSetup'
});

export const onSendRiskProfile = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'RiskProfile/SaveRiskProfile'
  },
  type: 'submitRiskProfile'
});

export const onUpdateRiskProfile = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    url: 'RiskProfile/UpdateRiskProfile'
  },
  type: 'submitRiskProfile'
});

export const onAccountConfirmation = (data: unknown) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'User/ConfirmUserAccount'
  },
  type: 'accountConfirmation'
});

export const onInviteConfirmation = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'User/ConfirmUserInvitation'
  },
  type: 'inviteConfirmation'
});

export const onGetInvitationStatus = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: 'User/GetInvitationStatus'
  },
  type: 'getInvitationStatus'
});

// ? Types
type User = {
  email: string;
  user_id?: number;
  user_name?: string;
  phone?: number;
  dob?: string;
  token?: string;
};
interface InitialState {
  accessToken: string | null;
  createUserId: number | null;
  isRiskFormSuccess: boolean;
  isAuth: boolean;
  msg: string;
  otpUserId: number | null;
  otpVerified: boolean;
  user: User | null;
  isRiskProfileCompleted: boolean | string;
  roleType: number | null;
  firstTransactionCompleted: boolean;
  bankAccountProcessStarted: boolean;
  IsAuthorized: boolean;
  goals: string;
  userAccountData: Record<string, any>;
  isSetUpComplete: boolean;
  isConfirmationSent: boolean;
  invitationDetails: InvitationDetails;
  isValidOtp: boolean;
}
export interface InvitationDetails {
  first_name: string;
  last_name: string;
  email: string;
  is_employer: boolean;
  company_name: string;
}
export interface LoginSuccessPayload extends User {
  Data: LoginSuccessDataPayload;
}
export interface LoginSuccessDataPayload extends LoginSuccessPayload {
  api_key: string;
  is_risk_profile_completed: string;
  role_type: string;
  bank_account_process_started: boolean;
  first_transaction_completed: boolean;
  is_authorized: boolean;
  goals: string;
  is_setup_complete: boolean;
  dob: string;
  success: boolean;
  message: string;
}
export interface LoginParams {
  email: string;
  password: string;
}

export interface InviteParams {
  email: string;
  first_name: string;
  last_name: string;
}
export interface ConfirmTokenParams {
  token: string;
}

export interface RegisterParams {
  dob: string;
  email: string;
  cnic: string;
  firstname: string;
  lastname: string;
  password: string;
  // userId: number;
  phone: string;
  token: string | null;
  is_employer: boolean;
  company_name: string;
}

export interface ForgotParams {
  email: string;
}
export interface ResetPassParams {
  new_password: string | null;
  email: string | null;
  sms_code: string | null;
  email_code: string | null;
  token?: string;
}

export interface SendOTPParams {
  phone: string;
}
export interface SendCodeParams {
  code: string;
}
export interface AccountConfirmationParams {
  email: string | null;
  hash: string | null;
}
