import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './dashboard.module.css';

const LinkListRow = ({
  description,
  goal_id,
  iconClass,
  link,
  title
}: LinkListRowProps) => {
  const { push } = useHistory();
  return (
    <div className={styles.link_item} onClick={() => push(link)}>
      <div className={styles.list_icon_container}>
        <div className={styles.list_icon}>
          <i className={iconClass} />
        </div>
      </div>
      <div className={styles.link_text_container}>
        <h4>{title}</h4>
        <p style={{ marginTop: '-5px' }}>{description}</p>
      </div>
      <div>
        <i className='fa fa-chevron-right' />
      </div>
    </div>
  );
};

export default memo(LinkListRow);

interface LinkListRowProps {
  iconClass: string;
  link: customRouteProps;
  title: string;
  description: string;
  goal_id: number;
}
interface customRouteProps {
  pathname: string;
  search?: string;
  state: RouteStateProps;
}
interface RouteStateProps {
  goal_id: number;
}
