import { Card, Col, Row } from 'react-bootstrap';
import { lazy, memo, Suspense, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';

import { monthsArray } from 'static/months';

const ReactApexChart = lazy(() => import('react-apexcharts'));

const HomeGraph = ({ handleMonthChartFilterClick }: HomeGraphProps) => {
  const { chartLoading } = useAppSelector(state => state.dashboard);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDisplay(true), 1);
  }, []);
  useEffect(() => {
    if (chartLoading) {
      setDisplay(false);
    }
  }, [chartLoading]);

  const { MonthwiseNavValues } = useAppSelector(state => state.dashboard);

  if (!display) {
    return <></>;
  }

  const months = MonthwiseNavValues.map((el, i) => {
    const d = Object.values(el);
    const setDate = new Date(d[0]);
    const dateString = monthsArray[setDate.getMonth()].split(' ');
    const date = String(dateString).substring(0, 3);
    return `${date} ${setDate.getDate()} ${setDate.getFullYear()}`;
  });
  const values =
    MonthwiseNavValues &&
    MonthwiseNavValues.map(el => {
      const arr = parseInt(Object.values(el)[1]);
      return arr;
    });
  const series = [
    {
      data: values,
      name: 'Pension'
    }
  ];
  const state = {
    options: {
      chart: { height: 360, toolbar: { show: false }, type: 'line' },
      colors: ['#4012A0', '#AC4CBC'],
      legend: { show: true },
      stroke: { curve: 'smooth', width: 4 },
      xaxis: {
        categories: months,
        labels: {
          style: {
            cssClass: 'apexcharts-xaxis-label',
            fontSize: '12px'
          }
        },
        tickPlacement: 'on'
      },

      yaxis: { show: true }
    },
    series: series
  };

  return (
    <>
      <Card className='pb-0' style={{ height: 'auto' }}>
        <Card.Body className='pb-0'>
          <Row>
            <Col lg={6}>
              <div className='btn-group ml-1' role='group'>
                <button
                  className='btn rounded-0 border width-110'
                  onClick={e => handleMonthChartFilterClick(1)}
                  type='button'
                >
                  1 Month
                </button>
                <button
                  className='btn rounded-0 border width-110'
                  onClick={e => handleMonthChartFilterClick(2)}
                  type='button'
                >
                  3 Month
                </button>
                <button
                  className='btn rounded-0 border width-110'
                  onClick={e => handleMonthChartFilterClick(3)}
                  type='button'
                >
                  1 Year
                </button>
                <button
                  className='btn rounded-0 border width-110'
                  onClick={e => handleMonthChartFilterClick(4)}
                  type='button'
                >
                  3 Year
                </button>
              </div>
            </Col>
            <Col lg={12}>
              <div id='chart'>
                <Suspense fallback={<div>Loading...</div>}>
                  <ReactApexChart
                    height={320}
                    options={state.options}
                    series={state.series}
                  />
                </Suspense>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default memo(HomeGraph);

interface HomeGraphProps {
  handleMonthChartFilterClick: (value: number) => void;
}
