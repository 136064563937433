import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  VPSBalanceDetails: {
    TaxCreditSum: 0,
    VPSBalance: 0,
    VPSBalanceWithTaxCredit: 0,
    VPSGraphData: []
  },
  companies: [],
  contributionEmployees: [],
  contributionEmployeesTotalRecords: 0,
  contributionMembers: [],
  contributionSummary: [],
  employeeProvidentFundDetails: {
    MonthlyContribution: '',
    PFValue: '',
    ReturnValue: ''
  },
  employee_investment_detail: [],
  employerContribution: [],
  employerEmployees: [],
  employerEmployeesTotalRecords: 0,
  employer_investment_detail: [],
  enrolled: null,
  fund_detail: [],
  memberRequests: [],
  members: [],
  msg: '',
  userAccountStates: {
    MonthlyContribution: '',
    ReturnTotal: '',
    TaxSaved: '',
    TotalContribution: '',
    VPSBalance: ''
  }
};

const name = 'employer';
const employerSlice = createSlice({
  initialState,
  name,
  reducers: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    UserAccountStatesSuccess: (state, action: PayloadAction<any>) => {
      if (action.payload.Message !== 'Provident Fund does not exist.')
        state.userAccountStates = action.payload;
    },

    VPSBalanceSuccess: (state, action: PayloadAction<any>) => {
      state.VPSBalanceDetails = {
        TaxCreditSum: parseInt(action.payload.TaxCreditSum),
        VPSBalance: parseInt(action.payload.VPSBalance),
        VPSBalanceWithTaxCredit: parseInt(
          action.payload.VPSBalanceWithTaxCredit
        ),
        VPSGraphData: state.VPSBalanceDetails.VPSGraphData
      };
    },

    VPSGraphDataSuccess: (state, action: PayloadAction<any>) => {
      state.VPSBalanceDetails.VPSGraphData = JSON.parse(action.payload?.data);
    },

    clearEmployerMsg: state => {
      state.msg = '';
    },

    companiesSuccess: (state, action: PayloadAction<Array<Company>>) => {
      const companies = action.payload.map(company => ({
        ...company,
        label: company.name,
        value: company.id.toString()
      }));
      state.companies = companies;
    },
    contributionMembersSuccess: (state, action: PayloadAction<any>) => {
      state.contributionMembers = action.payload;
    },

    employeeListForEmployerSuccess: (state, action: PayloadAction<any>) => {
      const data = JSON.parse(action.payload.data);
      state.employerEmployees = data;
      state.employerEmployeesTotalRecords = action.payload.toatl_records;
    },
    employeeProvidentFundSuccess: (state, action: PayloadAction<any>) => {
      const { MonthlyContribution, PFValue, ReturnValue } = action.payload;
      state.employeeProvidentFundDetails = {
        MonthlyContribution,
        PFValue,
        ReturnValue
      };
    },
    employerAndEmployeeIndividualContributionSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      const data = JSON.parse(action.payload.data);
      state.contributionEmployees = data;
      state.contributionEmployeesTotalRecords = action.payload.toatl_records;
    },
    employerContributionSuccess: (state, action: PayloadAction<any>) => {
      const data = JSON.parse(action.payload.data);
      state.employerContribution = data;
    },
    employerContributionSummarySuccess: (state, action: PayloadAction<any>) => {
      const data = JSON.parse(action.payload.data);
      state.contributionSummary = data;
    },
    employerInvestmentDetailSuccess: (state, action: PayloadAction<any>) => {
      const {
        employee_investment_detail,
        employer_investment_detail,
        fund_detail
      } = action.payload;
      state.employer_investment_detail = JSON.parse(employer_investment_detail);
      state.fund_detail = JSON.parse(fund_detail);
      state.employee_investment_detail = JSON.parse(employee_investment_detail);
    },
    enrollMemberSuccess: (state, action: PayloadAction<any>) => {
      state.enrolled = action.payload.Success;
    },
    importExcelFileSuccess: (state, action: PayloadAction<any>) => {
      state.msg = action.payload.Message;
    },
    memberRequestsSuccess: (state, action: PayloadAction<any>) => {
      state.memberRequests = action.payload;
    },
    membersSuccess: (state, action: PayloadAction<any>) => {
      state.members = action.payload;
    }
  }
});
export const { clearEmployerMsg: onClearEmployerMsg } = employerSlice.actions;
export default employerSlice.reducer;

//? APIS ACTIONS

export const onGetMembers = (id: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Employer/GetMembers?id=${id}`
  },
  type: 'members'
});

export const onGetEmployerContributionSummary = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `/employeer/GetEmployerContributionSummary`
  },
  type: 'employerContributionSummary'
});
export const onGetEmployerContribution = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `/employeer/GetEmployerAndEmployeeTotalContribution`
  },
  type: 'employerContribution'
});
export const onImportExcelFile = (data: File) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: `/employeer/ImportExcelFile`
  },
  type: 'importExcelFile'
});
export const onGetEmployerAndEmployeeIndividualContribution = (
  pageNum: number
) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `/employeer/GetEmployerAndEmployeeIndividualContribution?page_no=${pageNum}`
  },
  type: 'employerAndEmployeeIndividualContribution'
});

export const onGetEmployerInvestmentDetail = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `/employeer/GetEmployerInvestmentDetail`
  },
  type: 'employerInvestmentDetail'
});
export const onGetEmployeeListForEmployer = (pageNum: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `/employeer/GetEmployeeListForEmployer?page_no=${pageNum}`
  },
  type: 'employeeListForEmployer'
});
export const onGetContributionMembers = (id: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Employer/GetContributionMembers?id=${id}`
  },
  type: 'contributionMembers'
});

export const onGetCompanies = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Employer/GetCompanies`
  },
  type: 'companies'
});

export const onGetMemberRequests = (id: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Employer/GetRequest?id=${id}`
  },
  type: 'memberRequests'
});

export const onUpdateMemberStatus = (enroll_id: number, status: number) => ({
  payload: {
    apiName: name,
    data: { enroll_id, status },
    method: 'post',
    url: `Employer/UpdateMemberStatus`
  },
  type: 'updateMemberStatus'
});

export const onEnrollMember = (company_id: string, salary: number) => ({
  payload: {
    apiName: name,
    data: { company_id, salary },
    method: 'post',
    url: `Employer/EnrollMember`
  },
  type: 'enrollMember'
});

export const onGetEmployeeProvidentFund = (id?: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Employeer/GetEmployeeProvidentFund`
  },
  type: 'employeeProvidentFund'
});

export const onGetVPSBalance = (id?: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Employeer/GetVPSBalance`
  },
  type: 'VPSBalance'
});

export const onGetVPSGraphData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `employeer/GetOccupationalPensionGoalVPSBalanceChart`
  },
  type: 'VPSGraphData'
});

export const onGetUserAccountStates = (id?: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `employeer/GetUserAccountStates`
  },
  type: 'UserAccountStates'
});

// ? Types

export interface member {
  enroll_id: number;
  username: string;
  contribution_amount: number;
}

export interface Company {
  id: number;
  name: string;
  cont_prec: number;
  label: string;
  value: string;
}

interface InitialState {
  msg: string;
  members: Array<member>;
  contributionMembers: Array<member>;
  companies: Array<Company>;
  contributionSummary: Array<ContributionSummary>;
  employerContribution: Array<EmployerContribution>;
  contributionEmployees: Array<ContributionEmployee>;
  contributionEmployeesTotalRecords: number;
  employerEmployees: Array<EmployerEmployee>;
  employerEmployeesTotalRecords: number;
  employee_investment_detail: Array<EmployeeInvestmentDetail>;
  employer_investment_detail: Array<EmployerInvestmentDetail>;
  fund_detail: Array<FundDetail>;
  memberRequests: Array<member>;
  enrolled: boolean | null;
  employeeProvidentFundDetails: ProvidentFundProps;
  VPSBalanceDetails: VPSBalanceDetailsProps;
  userAccountStates: UserAccountStatesProps;
}
export interface FundDetail {
  user_id: number;
  employee_name: string;
  age: number;
  employee_contribution: number;
  equity: number;
  money_market: number;
  debit: number;
  commodities: number;
  current_value: number;
  return_absolute: number;
  return_annualize: number;
}
export interface EmployerInvestmentDetail {
  employeer_name: string;
  contributions_todate: number;
  matched_contribution: number;
  total_contribution: number;
  accumulated_values: number;
  return_absolute: number;
  return_annualize: number;
}
export interface EmployeeInvestmentDetail {
  holdings: string;
  values: number;
  percent: number;
}
export interface EmployerEmployee {
  accumlated: number;
  balance: number;
  employee_contribution: number;
  employee_name: string;
  employer_contribution: number;
  id: number;
  profit_loss: number;
  risk_score: number;
  salary: number;
  signed_up: boolean;
  status: string;
}
export interface ContributionEmployee {
  contribution: number;
  employee_contribution_percentage: number;
  employee_name: string;
  employer_contribution_percentage: number;
  id: number;
  matched_contribution: number;
}
export interface EmployerContribution {
  employeer_name: string;
  payroll: number;
  vps_contribution: number;
  matched_contribution: number;
  no_of_employees_participating: number;
}
export interface ContributionSummary {
  employeer_name: string;
  employer_contribution_percentage: number;
  growth_since_inspection: number;
  last_contribution: number;
  total_balance: number;
  total_contributions_todate: number;
  average_return: number;
}
export interface UserAccountStatesProps {
  MonthlyContribution: string;
  ReturnTotal: string;
  TaxSaved: string;
  TotalContribution: string;
  VPSBalance: string;
}
export interface VPSGraphData {
  age: number;
  vps_balance: number;
}
export interface VPSBalanceDetailsProps {
  TaxCreditSum: number;
  VPSBalance: number;
  VPSBalanceWithTaxCredit: number;
  VPSGraphData: Array<VPSGraphData>;
}
export interface ProvidentFundProps {
  MonthlyContribution: string;
  PFValue: string;
  ReturnValue: string;
}
