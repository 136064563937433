import GoalsOverviewComponent from 'jsx/components/GoalsOverview';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import GoalsList from 'jsx/components/GoalsOverview/GoalsList';
import { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  goalProps,
  onDeleteGoal,
  onGetAvailableGoals,
  onGetGoals
} from 'redux/actions';
import { toast, ToastContainer } from 'react-toastify';
import { useAppLoading } from 'hooks/appLoading';
import Loader from 'jsx/components/Shared/Loader';
import DeleteConfirmation from 'jsx/components/Shared/DeleteConfirmation';

const GoalsOverview = () => {
  const { availableGoals, completedGoals, message } = useAppSelector(
    state => state.dashboard
  );
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<goalProps>({});
  const isLoading = useAppLoading('dashboard');

  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (message.length) {
      dispatch(onGetGoals());
      setSelectedGoal({});
      toast.success(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
  useEffect(() => {
    dispatch(onGetGoals());
    dispatch(onGetAvailableGoals());
  }, [dispatch]);

  const handleGoalDelete = (goal: goalProps) => {
    setSelectedGoal(goal);
    setDeleteConfirm(true);
  };
  const handleDeleteConfirm = () => {
    setDeleteConfirm(false);
    const data = {
      GoalId: selectedGoal.goal_id
    };
    dispatch(onDeleteGoal(data));
    dispatch(onGetGoals());
  };
  const handleDeleteCancel = () => {
    setDeleteConfirm(false);
    setSelectedGoal({});
  };
  return (
    <>
      <ToastContainer
        closeOnClick
        draggable
        pauseOnFocusLoss
        pauseOnHover
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        position='top-right'
        rtl={false}
      />
      <DeleteConfirmation
        deleteConfirm={deleteConfirm}
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteConfirm={handleDeleteConfirm}
      />
      {completedGoals && completedGoals.length ? (
        isLoading ? (
          <Loader width={50} />
        ) : (
          <GoalsOverviewComponent
            completedGoals={completedGoals}
            handleGoalDelete={handleGoalDelete}
            pathname={location.pathname}
          />
        )
      ) : null}
      <GoalsList
        availableGoals={availableGoals}
        completedGoals={completedGoals}
        pathname={location.pathname}
      />
    </>
  );
};
export default memo(GoalsOverview);
