import { Card, Col, Row } from 'react-bootstrap';
import { memo } from 'react';

import SelectField from '../Shared/FormikSelectField';
import {
  investmentPlans,
  experienceLevels,
  experienceTypes
} from 'static/selection';
const KnowledgeForm = ({ formik }: { formik: never }) => (
  <Card>
    <Card.Body>
      <Row>
        <Col lg={12}>
          <h3 className='text-center text-danger mb-3'>Investment Knowledge</h3>
        </Col>
        <SelectField
          required
          col={4}
          fieldName='investment_plan'
          fields={investmentPlans}
          formik={formik}
          label='Investment Plan'
          placeholder='Long term plan'
        />
        <SelectField
          required
          col={4}
          fieldName='experience'
          fields={experienceLevels}
          formik={formik}
          label='Your Experience'
          placeholder='Investing in stocks/bonds'
        />
        <SelectField
          isMulti
          required
          col={4}
          fieldName='experience2'
          fields={experienceTypes}
          formik={formik}
          label='Your Past Investments'
          placeholder='Select multiple options'
        />
      </Row>
    </Card.Body>
  </Card>
);

export default memo(KnowledgeForm);
