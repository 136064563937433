import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { booleans } from 'static/selection/booleans';
import { FadeIn } from '../Shared/FadeIn';
import RadioField from '../Shared/FormikRadioField';

const Account = ({ formik }: AccountProps) => {
  return (
    <FadeIn>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={12}>
              <h3 className='text-center text-secondary'>Account Details</h3>
            </Col>
            <RadioField
              required
              singleLine
              col={4}
              fieldName='beneficial_owner'
              fields={booleans}
              formik={formik}
              label='Benificial Owner'
            />
            <RadioField
              required
              singleLine
              col={4}
              fieldName='send_account_statement'
              fields={booleans}
              formik={formik}
              label='Send Account Statement'
            />
            <RadioField
              required
              singleLine
              col={4}
              fieldName='zakat_deduction'
              fields={booleans}
              formik={formik}
              label='Zakat Deduction'
            />
          </Row>
        </Card.Body>
      </Card>
    </FadeIn>
  );
};

export default memo(Account);

interface AccountProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}
