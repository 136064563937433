import { memo } from 'react';

import profile01 from 'images/profile/1.jpg';
import profile02 from 'images/profile/8.jpg';

const LatestArticle = ({
  center,
  title
}: {
  center?: boolean;
  title: string;
}) => (
  <div className='card'>
    <div className='card-header border-0 pb-0'>
      <h5 className='text-black'>{title}</h5>
    </div>
    <div className='card-body pt-3'>
      <div className='profile-blog '>
        <img
          alt='profile'
          className='img-fluid  mb-4 w-100'
          src={center ? profile02 : profile01}
        />
        <span>
          <h4>Lorem ipsum dolor sit amet</h4>
        </span>
        <p className='mb-0'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          egestas in tortor luctus suscipit. Praesent egestas enim sed velit
          hendrerit, non vestibulum augue maximus. Sed commodo ipsum vitae
          efficitur ullamcorper.
        </p>
      </div>
    </div>
  </div>
);

export default memo(LatestArticle);
