import { useFormik } from 'formik';
import { memo, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { InviteParams } from 'redux/actions';
import AuthInputField from 'jsx/components/Shared/FormikInputField';
import { inviteSchema } from 'validate/auth/inviteSchema';
import { toast, ToastContainer } from 'react-toastify';

const InviteDialog = ({ closeInvite, inviteHandle }: InviteDialogProps) => {
  const [isEmployer, setIsEmployer] = useState(false);
  const formik = useFormik({
    initialValues: {
      company_name: '',
      email: '',
      first_name: '',
      last_name: ''
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (values: Record<string, any>) => {
      if (isEmployer && values.company_name === '') {
        toast.error('Please enter company name');
        return;
      }
      if (isEmployer) {
        const data = {
          company_name: values.company_name,
          email: values.email,
          first_name: values.first_name,
          is_employer: true,
          last_name: values.last_name
        };
        inviteHandle(data);
        closeInvite();
        return;
      }
      const data = {
        email: values.email,
        first_name: values.first_name,
        is_employer: false,
        last_name: values.last_name
      };
      inviteHandle(data);
      closeInvite();
    },
    validationSchema: inviteSchema
  });
  return (
    <>
      <ToastContainer />
      <Modal
        centered
        aria-labelledby='contained-modal-title-vcenter'
        onHide={closeInvite}
        show={true}
      >
        <Modal.Header closeButton>Please Enter Your Information</Modal.Header>
        <Modal.Body className=''>
          <form onSubmit={formik.handleSubmit}>
            <div className='form-group'>
              <AuthInputField
                required
                formik={formik}
                label='First Name'
                name='first_name'
                placeholder='Enter First Name'
              />
              <AuthInputField
                required
                formik={formik}
                label='Last Name'
                name='last_name'
                placeholder='Enter Last Name'
              />
              <AuthInputField
                required
                formik={formik}
                label='Email Address'
                name='email'
                placeholder='Enter Email Address'
              />
              <Col lg={12}>
                <Form.Check
                  checked={isEmployer}
                  className=' mb-4 font-weight-bold font-size-14'
                  label='Are you employer?'
                  onChange={() => setIsEmployer(!isEmployer)}
                  type='checkbox'
                />
              </Col>
              {isEmployer && (
                <AuthInputField
                  required
                  formik={formik}
                  label='Company Name'
                  name='company_name'
                  placeholder='Enter Company Name'
                />
              )}
              <div className='small-info mb-2 mt-1 text-muted'>
                Access to Mahaana is currently on invite only basis. Please
                share your email and we will contact you soon as it is your turn
                on the wait list.
              </div>
            </div>
            <Button className='mr-2' type='submit'>
              Submit
            </Button>
            <Button onClick={closeInvite} variant='dark'>
              Cancel
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(InviteDialog);

interface InviteDialogProps {
  closeInvite: () => void;
  inviteHandle: (data: InviteParams) => void;
}
