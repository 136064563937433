import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Loader from 'jsx/components/Shared/Loader';
import PieChart from 'jsx/components/Shared/PieChart';
import { useCallback, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { onGetEmployerContributionSummary } from 'redux/reducers/employer';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { contributionSummary } = useAppSelector(state => state.employer);
  const loading = useAppLoading('employer');

  const getInitialData = useCallback(() => {
    dispatch(onGetEmployerContributionSummary());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  const growthCinceInspectionColor = (item: number) => {
    if (item >= 0) {
      return 'green';
    }
    return 'red';
  };
  return (
    <div>
      {loading ? (
        <div className='text-center'>
          <Loader width={200} />
        </div>
      ) : (
        <>
          <Card className='pb-2 ' style={{ height: 'auto' }}>
            <Col className='custom-padding' lg={12} sm={12}>
              <p className='mb-0 text-black text-capitalize'>
                {contributionSummary[0]?.employeer_name || 'no company'}/Summary
              </p>
              <h2 className='mb-0 text-primary '>Summary</h2>
            </Col>
          </Card>
          <Row className='mt-5'>
            <Col md={4} sm={4}>
              <Card className='pb-2 ' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h2 className='mb-0 text-black text-capitalize '>
                    Last Contributions
                  </h2>
                </Card.Header>
                <Card.Body>
                  <h2
                    className='mb-0 '
                    style={{
                      color: growthCinceInspectionColor(
                        contributionSummary[0]?.last_contribution || 0
                      )
                    }}
                  >
                    {contributionSummary[0]?.last_contribution.toLocaleString() ||
                      0}{' '}
                    PKR
                  </h2>
                  <Button className='mt-4 w-50 h-38 p-0 '>View</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={4}>
              <Card className='pb-2 ' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h2 className='mb-0 text-black text-capitalize '>
                    Total Balance
                  </h2>
                </Card.Header>
                <Card.Body>
                  <h2
                    className='mb-0 '
                    style={{
                      color: growthCinceInspectionColor(
                        contributionSummary[0]?.total_balance || 0
                      )
                    }}
                  >
                    {contributionSummary[0]?.total_balance.toLocaleString() ||
                      0}{' '}
                    PKR
                  </h2>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={4}>
              <Card className='pb-2 ' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h2 className='mb-0 text-black text-capitalize'>
                    Contributiom Rate
                  </h2>
                </Card.Header>
                <Card.Body>
                  <h2
                    className='mb-0 '
                    style={{
                      color: growthCinceInspectionColor(
                        contributionSummary[0]
                          ?.employer_contribution_percentage || 0
                      )
                    }}
                  >
                    {contributionSummary[0]?.employer_contribution_percentage.toLocaleString() ||
                      0}{' '}
                    %
                  </h2>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={6} sm={12}>
              <Card className='pb-2 ' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h2 className='mb-0 text-black text-capitalize'>
                    Growth Since Inception
                  </h2>
                </Card.Header>
                <Card.Body>
                  <h2
                    className='mb-0'
                    style={{
                      color: growthCinceInspectionColor(
                        contributionSummary[0]?.growth_since_inspection || 0
                      )
                    }}
                  >
                    {contributionSummary[0]?.growth_since_inspection.toLocaleString() ||
                      0}{' '}
                    PKR
                  </h2>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={12}>
              <Card className='pb-2 ' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h2 className='mb-0 text-black text-capitalize'>
                    Average Return
                  </h2>
                </Card.Header>
                <Card.Body>
                  <h2
                    className='mb-0'
                    style={{
                      color: growthCinceInspectionColor(
                        contributionSummary[0]?.average_return || 0
                      )
                    }}
                  >
                    {contributionSummary[0]?.average_return || 0} %
                  </h2>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col md={6} sm={12}>
              <Card className='pb-2 ' style={{ height: 'auto' }}>
                <Card.Header className='pb-0 border-0'>
                  <h2 className='mb-0 text-black text-capitalize'>
                    Participation
                  </h2>
                </Card.Header>
                <Card.Body>
                  <PieChart
                    data={[
                      {
                        name: 'participation',
                        value:
                          contributionSummary[0]
                            ?.employer_contribution_percentage || 0
                      },
                      {
                        name: 'None',
                        value: 10
                      }
                    ]}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Dashboard;
