import logo from 'images/logo.png';
import { memo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { links } from 'static/links';
import HeaderLeftDropDown from './HeaderLeftDropDown';

const HeaderLeft = () => {
  const fundsLinks = [
    { link: links.fundsOverview, name: 'Funds' },
    { link: links.thematic, name: 'Invest in themes' }
  ];

  return (
    <div className='header-left'>
      <div className='dashboard_bar'>
        <Link to={links.home}>
          <img alt='logo' className='logo' src={logo} />
        </Link>
      </div>
      <div className='d-flex'>
        <NavLink
          activeClassName='active-link'
          className='top-nav-link'
          to={links.overview}
        >
          Goals
        </NavLink>

        {/* <HeaderLeftDropDown items={goalsLinks} title='Goals' /> */}
        <HeaderLeftDropDown items={fundsLinks} title='Products' />
        <NavLink
          activeClassName='active-link'
          className='top-nav-link'
          to={links.learn}
        >
          Learn
        </NavLink>
      </div>
    </div>
  );
};

export default memo(HeaderLeft);
