import { useFormik } from 'formik';
import RiskProfile from 'jsx/containers/RiskProfile';
import EmployerScreen from 'jsx/pages/EmployerScreen';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MultiStepForm, Step } from 'react-multi-form';
import { useLocation } from 'react-router-dom';
import { addGoalProps, Conservative, onClearEmployerData } from 'redux/actions';
import Loader from '../Shared/Loader';
import { AreaChartStepProps } from './Steps/AreaChartStep';
import ProgressBarChart from './Steps/ConservativeStep/ProgressBarChart';
import Recommendation from './Steps/ConservativeStep/Recommendation';
import PlannedSaving from './Steps/PlannedSaving';
import PortfolioForm from './Steps/PortfolioForm';
import { useStatisticsData } from 'hooks/useStatisticsData';
import { Compound } from './Steps/Compound';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { VPSGoal } from 'validate/VPSGoal';
import { OccupationalGoal } from 'validate/OccupationalGoal';
import { checkProperties } from 'utils/validateObject';

const WizardForm = ({
  active,
  conservative,
  goal_id,
  graphData,
  isRiskProfileCompleted,
  loading,
  setActive,
  submitPortfolioHandle
}: WizardFormProps) => {
  const chartData = useStatisticsData(
    graphData.data,
    'amount',
    'amount',
    'age'
  );
  const { employeData, message } = useAppSelector(state => state.portfolio);
  const { VPSBalanceDetails, companies, employeeProvidentFundDetails } =
    useAppSelector(state => state.employer);
  const { user } = useAppSelector(state => state.auth);
  const age =
    new Date().getFullYear() - new Date(user?.dob ?? '').getFullYear();
  const [modalShow, setModalShow] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [intrestData, setIntrestData] = useState<any>({});
  const [isRiskFormFilled, setRiskFormFilled] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(onClearEmployerData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formikVP = useFormik({
    initialValues: voluntaryPension,
    onSubmit: values => {
      const goalDetails = {
        age: 65,
        current_salary: 0,
        employeer_id: 0,
        goal_amount: isNaN(parseInt(intrestData?.result))
          ? 0
          : parseInt(intrestData?.result),
        goal_type_id: goal_id,
        initial_amount: parseInt(values.initial_amount),
        monthly_amount: parseInt(values.monthly_saving)
      };
      submitPortfolioHandle(goalDetails);
    },
    validationSchema: VPSGoal
  });

  const formikOPS = useFormik({
    enableReinitialize: true,
    initialValues: occupationalPension,
    onSubmit: values => {
      const goalDetails = {
        age: 65,
        current_salary: parseInt(employeData.current_gross_monthly_salary),
        employeer_id: parseInt(employeData.employer_id),
        goal_amount: isNaN(parseInt(intrestData?.result))
          ? 0
          : parseInt(intrestData?.result),
        goal_type_id: goal_id,
        initial_amount: 0,
        monthly_amount:
          (parseInt(employeData?.avg_tax ?? 1) *
            parseInt(employeData.current_gross_monthly_salary)) /
          100
      };
      submitPortfolioHandle(goalDetails);
    },
    validationSchema: OccupationalGoal
  });
  const nextHandler = () => {
    if (active === 1) {
      formikVP.handleSubmit();
      formikOPS.handleSubmit();

      const values = formikVP.values;

      handleIntrestCompute({
        initial_amount: isOPS ? 0 : values.initial_amount,
        monthly_saving: isOPS
          ? (parseInt(employeData?.avg_tax ?? 1) *
              parseInt(employeData.current_gross_monthly_salary)) /
            100
          : values.monthly_saving
      });
    }
    setActive(active + 1);
    window.scrollTo(0, 0);
  };
  const handleIntrestCompute = (values: Record<string, string | number>) => {
    const computedIntrest = Compound(
      values.initial_amount,
      values.monthly_saving,
      65 - age,
      0.096,
      12
    );
    setIntrestData(computedIntrest);
  };
  const prevHandler = () => {
    setActive(active - 1);
    window.scrollTo(0, 0);
  };

  const { search } = useLocation();
  const param = search.slice(6);
  const isOPS = param === 'employer';
  const selectedCompany = companies.find(item => {
    return item.id === parseInt(formikOPS.values.employer);
  });
  const plannedSavingData = () =>
    isOPS
      ? {
          initialAmount: 0,
          monthlySaving:
            ((selectedCompany?.cont_prec ?? 1) * formikOPS.values.salary) / 100
        }
      : {
          initialAmount: formikVP.values.initial_amount,
          monthlySaving: formikVP.values.monthly_saving
        };

  return (
    <>
      {isRiskProfileCompleted ? (
        <>
          <MultiStepForm activeStep={active}>
            <Step label='1'>
              {isOPS ? (
                <EmployerScreen
                  companies={companies}
                  cont_prec={selectedCompany?.cont_prec ?? 1}
                  formik={formikOPS}
                  isPortfolioForm={true}
                />
              ) : (
                <PortfolioForm formik={formikVP} />
              )}
            </Step>
            <Step label='2'>
              {loading ? (
                <div className='m-5 text-center'>
                  <Loader width={100} />
                </div>
              ) : (
                <PlannedSaving
                  employeData={employeData}
                  formik={isOPS ? formikOPS : formikVP}
                  handleIntrestCompute={handleIntrestCompute}
                  intrestData={intrestData}
                  isEmployer={isOPS}
                  {...plannedSavingData()}
                />
              )}
            </Step>
            <Step label='3'>
              {loading ? (
                <div className='m-5 text-center'>
                  <Loader width={100} />
                </div>
              ) : (
                <ProgressBarChart
                  age={age}
                  chartData={chartData}
                  conservative={conservative}
                  employeData={employeData}
                  formik={isOPS ? formikOPS : formikVP}
                  graphData={VPSBalanceDetails.VPSGraphData}
                  intrestData={intrestData}
                  isEmployer={isOPS}
                />
              )}
            </Step>
          </MultiStepForm>
        </>
      ) : (
        <MultiStepForm activeStep={active}>
          <Step label='1'>
            {isOPS ? (
              <EmployerScreen
                companies={companies}
                cont_prec={selectedCompany?.cont_prec ?? 1}
                formik={formikOPS}
                isPortfolioForm={true}
              />
            ) : (
              <PortfolioForm formik={formikVP} />
            )}
          </Step>
          <Step label='2'>
            {active === 2 && (
              <>
                {loading ? (
                  <div className='m-5 text-center'>
                    <Loader width={100} />
                  </div>
                ) : (
                  <PlannedSaving
                    formik={isOPS ? formikOPS : formikVP}
                    handleIntrestCompute={handleIntrestCompute}
                    intrestData={intrestData}
                    isEmployer={isOPS}
                    {...plannedSavingData()}
                    employeData={employeData}
                  />
                )}
              </>
            )}
          </Step>
          <Step label='3'>
            {active === 3 && (
              <>
                {loading ? (
                  <div className='m-5 text-center'>
                    <Loader width={100} />
                  </div>
                ) : (
                  <RiskProfile
                    setRiskFormFilled={val => {
                      setRiskFormFilled(val);
                      nextHandler();
                    }}
                  />
                )}
              </>
            )}
          </Step>
          <Step label='4'>
            <ProgressBarChart
              age={age}
              chartData={chartData}
              conservative={conservative}
              employeData={employeData}
              formik={isOPS ? formikOPS : formikVP}
              graphData={VPSBalanceDetails.VPSGraphData}
              intrestData={intrestData}
              isEmployer={isOPS}
            />
          </Step>
        </MultiStepForm>
      )}

      {(isRiskProfileCompleted || isRiskFormFilled || active !== 3) && (
        <div>
          {active === 4 || (isRiskProfileCompleted && active === 3) ? (
            <>
              <Button className='rit' onClick={() => setModalShow(true)}>
                Invest Now
              </Button>
              <Recommendation
                formik={isOPS ? formikOPS : formikVP}
                intrestData={intrestData}
                isEmployer={isOPS}
                onHide={() => setModalShow(false)}
                show={modalShow}
              />
            </>
          ) : (
            <Button
              className='rit'
              disabled={
                !isOPS
                  ? Object.keys(formikVP.errors).length > 0 ||
                    formikVP.values.investment_type.length === 0
                  : Object.keys(formikOPS.errors).length > 0 ||
                    formikOPS.values.investment_type.length === 0 ||
                    checkProperties(employeeProvidentFundDetails) ||
                    message.length > 0 ||
                    loading
              }
              onClick={nextHandler}
            >
              Next
              <i className='fa fa-long-arrow-right ml-2' />
            </Button>
          )}

          {active !== 1 && (
            <Button className='rit mr-2' onClick={prevHandler}>
              <i className='fa fa-long-arrow-left mr-2' />
              Previous
            </Button>
          )}
        </div>
      )}
      <div style={{ clear: 'both' }} />
    </>
  );
};

export default WizardForm;

interface PieChartStepProps {
  conservative: Conservative | Record<string, never>;
}

interface WizardFormProps extends AreaChartStepProps, PieChartStepProps {
  active: number;
  setActive: (active: number) => void;
  loading: boolean;
  isRiskProfileCompleted: boolean;
  goal_id: number;
  submitPortfolioHandle: (data: addGoalProps) => void;
}

const voluntaryPension = {
  dob: '',
  initial_amount: '',
  investment_type: '',
  liquid_worth: '',
  monthly_exp: '',
  monthly_saving: '',
  plan_type: '',
  pre_tax_income: '',
  total_amount: ''
};

const occupationalPension = {
  CNIC: '',
  contribution: '',
  employer: '1',
  investment_type: '',
  salary: 4000
};
