import { memo } from 'react';

import loadingImage from 'images/svg/loader.svg';

const Loader = ({ className = '', width = 25 }: LoaderProps) => (
  <img alt='loading' className={className} src={loadingImage} width={width} />
);

export default memo(Loader);

interface LoaderProps {
  className?: string;
  width?: number;
}
