/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Loader from 'jsx/components/Shared/Loader';
import PieChart from 'jsx/components/Shared/PieChart';
import Statistics from 'jsx/components/Shared/Statistics';
import { memo, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import {
  Conservative,
  // onGetBankFundAllocationList,
  onGetUserFundAllocation,
  onGetUserFundInvestmentList,
  VPSGraphData
} from 'redux/actions';
import ProgressBarSingleCard from './ProgressBarSingleCard';

const ProgressBarChart = ({
  age,
  conservative: { bonds, stocks },
  employeData,
  formik,
  graphData,
  intrestData,
  isEmployer
}: Props) => {
  const { InvestmentList, riskPortfolioData } = useAppSelector(
    state => state.pieChartData
  );
  const { created_goal_id } = useAppSelector(state => state.portfolio);
  const isLoading = useAppLoading('progressBarChart');
  const dispatch = useAppDispatch();
  const goal_type_id = formik?.values?.investment_type === 'islamic' ? 1 : 2;
  useEffect(() => {
    if (created_goal_id) {
      dispatch(onGetUserFundAllocation(created_goal_id));
      // dispatch(onGetBankFundAllocationList(created_goal_id));
      dispatch(onGetUserFundInvestmentList(created_goal_id, goal_type_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [created_goal_id, dispatch]);
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const temp = [
    {
      data: isEmployer
        ? [
            formik.values.salary,
            ...graphData.map(val => val.vps_balance.toFixed())
          ]
        : intrestData.total,
      labels: range(age, 66),
      name: 'amount'
    }
  ];

  const unique = Array.from(
    new Set(InvestmentList.map(item => item.bank_name))
  );
  const banksData = unique.map(bank => ({
    label: bank,
    value: bank
  }));
  return (
    <Row>
      <Col lg={12} xl={12}>
        <Row>
          <Col lg={6}>
            <Card style={{ height: '450px' }}>
              <div className='pt-4 pl-4'>
                <h2 className='px-5'>Your personalized portfolio</h2>
              </div>
              <Card.Body className='pie-chart'>
                <Row>
                  <Col lg={12}>
                    {isLoading ? (
                      <Loader width={50} />
                    ) : (
                      <PieChart data={riskPortfolioData} />
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ height: '450px' }}>
              <div className='pt-4 pl-4'>
                <h2 className='px-5'>
                  You will have{' '}
                  {!isEmployer ? (
                    <span className='plannedAmount text-primary fs-38'>
                      {isNaN(parseInt(intrestData?.result))
                        ? 0
                        : parseInt(intrestData?.result).toLocaleString()}
                    </span>
                  ) : (
                    <span className=' text-primary'>
                      {`${parseInt(
                        employeData.mahaana_pension_account_amount
                      ).toLocaleString()}`}
                    </span>
                  )}{' '}
                  PKR by age 65.
                </h2>
              </div>
              <Card.Body className='pie-chart'>
                <Row>
                  <Col lg={12}>
                    <Statistics series={temp} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {banksData?.length
          ? riskPortfolioData.map((v: any) => (
              <Card key={v.name} style={{ height: 'auto' }}>
                <Card.Header>
                  <h2>{v.name}</h2>
                </Card.Header>
                <Card.Body className='pie-chart'>
                  {InvestmentList.filter(
                    el => el.catergory_name === v.name.slice(0, -1)
                  ).map((item: Record<string, any>, index: number) => (
                    <ProgressBarSingleCard
                      key={index}
                      InvestmentList={InvestmentList}
                      data={item}
                      type={v.name}
                    />
                  ))}
                </Card.Body>
              </Card>
            ))
          : null}
      </Col>
    </Row>
  );
};

export default memo(ProgressBarChart);
export interface intrestDataProps {
  interest: number[];
  principal: number[];
  result: string;
  total: number[];
}
interface Props {
  age: number;
  conservative: Conservative | Record<string, never>;
  chartData: Record<string, any>;
  intrestData: intrestDataProps | Record<string, never>;
  formik: any;
  employeData: any;
  isEmployer: boolean;
  graphData: Array<VPSGraphData>;
}
