import { Button, Col } from 'react-bootstrap';
import { memo, useCallback, useEffect, useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify';

const FileUploadField = ({
  col = 6,
  formik,
  imageError,
  required = false,
  setImageError,
  setImages
}: FileUploadFieldProps) => {
  const [files, setFiles] = useState<Files>([]);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);
  useEffect(() => {
    if (formik.values.cnic_back_image && formik.values.cnic_front_image) {
      setFiles([
        { id: 0, preview: formik.values.cnic_back_image },
        { id: 1, preview: formik.values.cnic_front_image }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: Files, fileRejections: FileRejection[]) => {
      const allFIles = [...acceptedFiles, ...files];
      if (allFIles.length > 2) {
        toast.error('Please select only two files');
        return;
      }
      fileRejections.forEach((file: FileRejection) => {
        file.errors.forEach((err: FileError) => {
          if (err.code === 'file-too-large') {
            return toast.error('File is too large');
          }
        });
      });
      const newFiles = [...acceptedFiles];
      if (acceptedFiles.length > 2 || files.length === 2) {
        return toast.error('You can only upload 2 images');
      }
      if (acceptedFiles.length === 1) {
        acceptedFiles.push(...files);
      }
      acceptedFiles.forEach((el, index) => (el.id = index));
      setImages(acceptedFiles);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const previewFiles = newFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      setFiles([...previewFiles, ...files]);
      setImageError(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [files, setImageError, setImages]
  );
  const MAX_SIZE = 2000000;
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxFiles: 2,
    maxSize: MAX_SIZE,
    onDrop
  });
  const handleDeleteImage = (id: number) => {
    setFiles(files.filter(el => el.id !== id));
  };
  const thumbs = files.map(file => (
    <div key={file.name} className='thumb' id={file.id}>
      <div className='thumb-inner'>
        <img alt='Id Card' className='thumb-image' src={file.preview} />
      </div>
      <span
        className='fa fa-minus-circle fa-lg'
        onClick={e => handleDeleteImage(file.id)}
      />
    </div>
  ));

  return (
    <>
      <ToastContainer />
      <Col lg={col}>
        <div className='form-group'>
          <div {...getRootProps()} className=''>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <Button className='btn-sm'>Upload Images of ID Card</Button>
            )}
          </div>
          <aside className='thumb-container'>{thumbs}</aside>
          {imageError && (
            <span className='ml-2 text-danger'>Images are required.</span>
          )}
        </div>
      </Col>
    </>
  );
};

export default memo(FileUploadField);

interface FileUploadFieldProps {
  col?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  required?: boolean;
  setImages: (images: Files) => void;
  imageError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setImageError: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Files = Array<Record<string, any>>;
