import * as Yup from 'yup';

export const mutualAccountForm = Yup.object({
  AnnualIncome: Yup.number()
    .required('Annual Income is required')
    .typeError('Annual Income must be a number'),
  Nationality: Yup.string()
    .required('First Nationality is required')
    .typeError('First Nationality is required'),
  Nationality2: Yup.string()
    .required('Second Nationality is required')
    .typeError('Second Nationality is required'),
  OfficeNumber: Yup.number()
    .required('Office Number is required')
    .typeError('Office Number must be a number'),
  PreferredContactNumber: Yup.number()
    .required('Preferred Contact Number is required')
    .typeError('Preferred Contact Number must be a number'),
  USAPerson: Yup.string().required('USA National is required'),
  city: Yup.string().required('City is required').typeError('City is required'),
  cnic_expiry_date: Yup.string()
    .required('CNIC Expiry is required')
    .typeError('CNIC Expiry is required.'),
  cnic_number: Yup.string()
    .required('This field is Required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'CNIC number is not valid'
    )
    .length(13, 'CNIC number is not valid'),
  country: Yup.string()
    .required('Country is required')
    .typeError('Country is required'),
  date_of_birth: Yup.string().required('Date of birth is required'),
  email: Yup.string().required('Email is required'),
  father_name: Yup.string().required('Father Name is required'),
  gender: Yup.string().required('Gender is required'),
  income_source_id: Yup.string()
    .required('Income Source is required')
    .typeError('Income Source is required'),
  marital_status: Yup.string().required('Marital Status is required'),
  name: Yup.string().required('Name is required'),
  native_country: Yup.number().required('Native Country is required'),
  occupation_id: Yup.string().required('Occupation is required'),
  other_city: Yup.string().when('isOtherCity', {
    is: (val: string) => val,
    then: Yup.string().required('Other city is required.')
  }),
  other_country: Yup.string().when('isOtherCountry', {
    is: (val: string) => val,
    then: Yup.string().required('Other country is required.')
  }),
  other_native_country: Yup.string().when('isOtherNativeCountry', {
    is: (val: string) => val,
    then: Yup.string()
      .required('Other native country is required.')
      .typeError('Other native country is required.')
  }),
  other_resident_country: Yup.string().when('isOtherResidentCountry', {
    is: (val: string) => val,
    then: Yup.string().required('Other resident country is required.')
  }),
  other_state: Yup.string().when('isOtherState', {
    is: (val: string) => val,
    then: Yup.string().required('Other state is required.')
  }),
  phone: Yup.number()
    .required('Phone is required')
    .typeError('Phone Number must be a number'),

  primary_address: Yup.string().required('Primary Address is required'),
  resident_country: Yup.number().required('Resident Country is required'),
  states: Yup.string()
    .required('State is required')
    .typeError('state is required'),
  tax_residence: Yup.string().required('Country Tax Residence is required')
});
