import { memo, useEffect, useState } from 'react';
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';

import Loader from 'jsx/components/Shared/Loader';
import { History } from 'redux/actions';
import { statuses } from 'static/statuses';
import ReactPaginate from 'react-paginate';

const heads = ['NAME', 'UNITS', 'AMOUNT', 'PRICE', 'STATUS'];
const Items = ({ currentItems }: ItemsProps) => {
  return (
    <div className='items'>
      <Table responsive>
        <thead>
          <tr>
            {heads.map(value => (
              <th key={value}>
                <strong>{value}</strong>
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((row: History, index: number) => {
            const status = statuses.find(({ name }) => row.status === name);
            return (
              <tr key={index}>
                <td>{row.fund_symbol}</td>
                <td>{row.unit_purchase}</td>
                <td>{row.amount?.toFixed(2)}</td>
                <td>{row.amount?.toFixed(2)}</td>
                <td>
                  <Badge variant={`${status?.color} light`}>
                    {status?.name}
                  </Badge>
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
const TransactionHistory = ({
  currentPage,
  data,
  loading,
  setCurrentPage,
  totalRecords
}: TransactionHistoryProps) => {
  const [currentItems, setCurrentItems] = useState<Array<History>>(data);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setCurrentItems(data);
    setPageCount(Math.ceil(totalRecords / itemsPerPage));
  }, [data, totalRecords]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: any) => {
    const selectedPage = event.selected + 1;
    setCurrentPage(selectedPage);
  };
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <Card.Header style={{ border: 'none' }}>
            <Card.Title>Transaction History</Card.Title>
          </Card.Header>
          <Card.Body>
            {loading ? (
              <div className='m-5 text-center'>
                <Loader width={100} />
              </div>
            ) : (
              <>
                <Items currentItems={currentItems} />
              </>
            )}
            {currentItems.length && !loading ? (
              <ReactPaginate
                activeClassName='active'
                breakClassName='page-item'
                breakLabel='...'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                disabledClassName='disabledBtn'
                forcePage={currentPage - 1}
                marginPagesDisplayed={3}
                nextClassName='page-item'
                nextLabel='>'
                nextLinkClassName='page-link'
                onPageChange={handlePageClick}
                pageClassName='page-item'
                pageCount={pageCount}
                pageLinkClassName='page-link'
                pageRangeDisplayed={3}
                previousClassName='page-item'
                previousLabel='< '
                previousLinkClassName='page-link'
              />
            ) : null}
            {!currentItems.length && !loading && (
              <h2 className='text-center'>No Data Found</h2>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default memo(TransactionHistory);

interface TransactionHistoryProps {
  data: Array<History>;
  loading: boolean;
  totalRecords: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
}
interface ItemsProps {
  currentItems: Array<History>;
}
