/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
  cities: [],
  countries: [],
  occupations: [],
  sourcesOfIncome: [],
  states: []
};

const name = 'sharedStateData';
const sharedStateDataSlice = createSlice({
  initialState,
  name,
  reducers: {
    citiesSuccess: (state, action: PayloadAction<CityPayload>) => {
      const data = JSON.parse(action.payload.Data);
      state.cities = data.map((city: any) => ({
        id: city.Id,
        label: city.Name,
        name: city.Name,
        value: `${city.Id}`
      }));
    },
    countriesSuccess: (state, action: PayloadAction<CountryPayload>) => {
      const data = JSON.parse(action.payload.Data);
      state.countries = data.map((country: any) => ({
        label: country.Name,
        value: country.Id
      }));
    },
    incomeSourceSuccess: (
      state,
      action: PayloadAction<sourcesOfIncomePayload>
    ) => {
      const data = JSON.parse(action.payload.Data);
      state.sourcesOfIncome = data.map((source: any) => ({
        label: source.Name,
        value: `${source.Id}`
      }));
    },
    occupationsSuccess: (state, action: PayloadAction<OccupationPayload>) => {
      const data = JSON.parse(action.payload.Data);
      state.occupations = data.map((occupation: any) => ({
        label: occupation.Name,
        value: occupation.Id
      }));
    },
    statesSuccess: (state, action: PayloadAction<StatePayload>) => {
      const data = JSON.parse(action.payload.Data);
      state.states = data.map((elem: any) => ({
        StateCode: elem.StateCode,
        id: elem.Id,
        label: elem.Name,
        name: elem.Name,
        value: `${elem.Id}`
      }));
    }
  }
});

export default sharedStateDataSlice.reducer;

//? APIS ACTIONS

export const onGetCountries = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetCountryList'
  },
  type: 'countries'
});

export const onGetStates = (id: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Common/GetStateList?country_id=${id}`
  },
  type: 'states'
});

export const onGetCities = (id: number, stateId: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    url: `Common/GetCityList?country_id=${id}&state_id=${stateId}`
  },
  type: 'cities'
});

export const onGetSourcesOfIncome = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetSourceOfIncomeList'
  },
  type: 'incomeSource'
});

export const onGetOccupations = () => ({
  payload: {
    apiName: name,
    method: 'get',
    url: 'Common/GetOccupationList'
  },
  type: 'occupations'
});

// ? Types

export interface Country {
  id: number;
  name: string;
  phone_code: string;
  currency: string;
  label: string;
  value: string;
}
interface CountryPayload {
  Data: string;
}
interface CityPayload {
  Data: string;
}
interface OccupationPayload {
  Data: string;
}
// type OccupationPayload = Array<Omit<Occupation, 'label' | 'value'>>;
// type sourcesOfIncomePayload = Array<Omit<SourcesOfIncome, 'label' | 'value'>>;

interface sourcesOfIncomePayload {
  Data: string;
}
interface StatePayload {
  Data: string;
}
export interface State {
  id: number;
  name: string;
  StateCode: string;
  label: string;
  value: string;
}
export interface SourcesOfIncome {
  id: number;
  name: string;
  label: string;
  value: string;
}
export interface Occupation {
  id: number;
  name: string;
  id1: number;
  label: string;
  value: string;
}
export interface City {
  id: number;
  name: string;
  label: string;
  value: string;
}

export interface sourceOfIncomeProps {
  id: number;
  name: string;
}

export interface occupationProps {
  id: number;
  name: string;
  id1: number;
}

interface InitialState {
  countries: Array<Country>;
  cities: Array<City>;
  states: Array<State>;
  sourcesOfIncome: Array<SourcesOfIncome>;
  occupations: Array<Occupation>;
}
