import { memo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import Statistics from '../Shared/Statistics';

const CompanyFundsInfo = () => {
  const { push } = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_interval, setInterval] = useState('');

  return (
    <Card style={{ height: 'auto' }}>
      <Card.Header className='pb-0 d-block d-sm-flex flex-wrap border-0 align-items-center'>
        <div className='mr-auto mb-3'>
          <h4 className='fs-20 text-black'>Theme Chart</h4>
          <p className='mb-0 fs-12'>Lorem ipsum dolor sit amet, consectetur</p>
        </div>
        <Button
          className='btn btn-primary ptr mr-3 mb-3'
          onClick={() => push(links.mutualFundAccount)}
        >
          Invest Now
        </Button>
        <span className='btn btn-primary  ptr light mr-3  mb-3'>
          <i className='las la-download scale5 mr-2' />
          Download Report
        </span>

        <div className='input-group detault-daterange mr-3  mb-3 coinDetails-datepiker'>
          <span className='input-group-text'>
            <i className='las la-calendar' />
          </span>
          <DateRangePicker>
            <input className='form-control' type='text' />
          </DateRangePicker>
        </div>
      </Card.Header>
      <Card.Body className='pb-0 pt-sm-3 pt-0'>
        <div className='row sp20 mb-4 align-items-center'>
          <div className='col-lg-8 col-xxl-12 d-flex flex-wrap justify-content-between align-items-center'>
            <div className='px-2 info-group'>
              <p className='fs-18 mb-1'>Nav as of date: 13 Aug 2021</p>
              <div className='d-flex align-end'>
                <h2 className='fs-28 font-w600 text-black mr-3'>103.57</h2>
                <h3 className='fs-20 font-w600 text-success'>
                  1.64% <i className='fa fa-caret-up' />
                </h3>
              </div>
            </div>

            <div className='px-2 info-group'>
              <Row>
                <Col sm={12} style={{ fontSize: '24px' }}>
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => setInterval('1D')}
                  >
                    1D |{' '}
                  </span>
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                      setInterval('5D');
                    }}
                  >
                    5D |{' '}
                  </span>
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                      setInterval('1M');
                    }}
                  >
                    1M |{' '}
                  </span>
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                      setInterval('3M');
                    }}
                  >
                    3M |{' '}
                  </span>
                  <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                      setInterval('1Y');
                    }}
                  >
                    1Y |{' '}
                  </span>
                  <span
                    className='text-white p-1 cursor-pointer btn-primary '
                    onClick={() => {
                      setInterval('5Y');
                    }}
                  >
                    5Y{' '}
                  </span>
                </Col>
              </Row>

              <p className='fs-18 mb-4 mt-2'>as of 04:10 EST PM 11/12/2021</p>
            </div>
          </div>
        </div>

        <div className='bar-chart' id='chart'>
          <Statistics series={series} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(CompanyFundsInfo);

const series = [
  { data: [50, 50, 35, 50, 40, 40, 55, 45, 35, 50, 55], name: 'series1' }
];
