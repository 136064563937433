import * as Yup from 'yup';

export const riskProfile = Yup.object({
  age: Yup.string().required('Age is required.'),
  current_insurance: Yup.array()
    .min(1, 'Current insurance is required.')
    .required(),
  dependents: Yup.number()
    .required('Dependents is required.')
    .typeError('Dependents must be a number'),
  education: Yup.string().required('Educationis required.'),
  experience: Yup.string().required('Experience is required.'),
  experience2: Yup.array().min(1, 'Past investments is required').required(),
  income_source: Yup.string().required('Income source is required.'),
  income_source_stability: Yup.string().required(
    'Income source stability required.'
  ),
  investment_lost_options: Yup.string().required('Investment lost required'),
  investment_plan: Yup.string().required('Investment plan is required'),
  investment_reason: Yup.array()
    .min(1, 'Investment reason is required')
    .required(),
  market_down_options: Yup.string().required('Market down option required'),
  martial_status: Yup.string().required('Martial Statusis required.'),
  monthly_saving_bool: Yup.string().required('Monthly saving required.'),
  net_cash: Yup.number()
    .required('Current cash is required.')
    .typeError('Net cash must be a number'),
  occupation: Yup.string().required('Occupation is required.'),
  tax: Yup.number()
    .required('Tax is required.')
    .typeError('Tax must be a number'),
  with_draw_years: Yup.string().required('Withdraw years required.')
});
