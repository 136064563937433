import { Card, Col, Row } from 'react-bootstrap';
import { memo } from 'react';
import { links } from 'static/links';
import { useHistory } from 'react-router-dom';

const HomeAccountsContainer = () => {
  const { push } = useHistory();

  const list = [
    { title: 'Occupation Pension' },
    { title: 'VPS' },
    { title: 'Tax Saving Account' }
  ];

  return (
    <>
      <Card className='pb-0' style={{ height: 'auto' }}>
        <Card.Body className='pb-0'>
          <Row>
            <Col lg={12}>
              <h2>Your Accounts</h2>
            </Col>
            <Col lg={6}>
              <ul className='widht-70'>
                {list.map(item => (
                  <li
                    key={item.title}
                    className='HomeAccountList'
                    onClick={() => {
                      push(links.myAccounts, {
                        title: item.title
                      });
                    }}
                  >
                    <span>{item.title}</span>
                    <i
                      className='fa fa-angle-right'
                      style={{ float: 'right', marginTop: '5px' }}
                    />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default memo(HomeAccountsContainer);
