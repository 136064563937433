import { memo, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import AuthPage from 'jsx/components/Auth/AuthWrapper';
import InputField from 'jsx/components/Shared/FormikInputField';
import AuthSubmitButton from 'jsx/components/Auth/AuthSubmitButton';
import { ResetPassParams } from 'redux/actions';
import { resetPassword } from 'validate/auth/resetPassword';
import { useHistory, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { links } from 'static/links';
import { FadeIn } from 'jsx/components/Shared/FadeIn';

const ResetPassword = ({
  emailCodeHanele,
  loading,
  phoneCodeHanele,
  resetPasswordHandle
}: ResetPasswordProps) => {
  const [emailOtp, setEmailOtp] = useState('');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [disableEmailOTP, setDisableEmailOTP] = useState(false);
  const [disablePhoneOTP, setDisablePhoneOTP] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { push } = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const email = params.get('email');
  useEffect(() => {
    if (!email) {
      return push(links.forgot);
    }
  }, [email, push, search]);

  const formik = useFormik({
    initialValues: {
      email: '',
      email_code: '',
      new_password: '',
      sms_code: ''
    },
    onSubmit: (values: ResetPassParams) => {
      const token = params.get('token');
      resetPasswordHandle({
        email: email,
        email_code: emailOtp,
        new_password: values.new_password,
        sms_code: phoneOtp,
        ...(token && { token })
      });
    },
    validationSchema: resetPassword
  });
  const handleResendEmailOTP = () => {
    if (!disableEmailOTP && email) {
      emailCodeHanele(email);
      setDisableEmailOTP(true);
      setTimeout(() => {
        setDisableEmailOTP(false);
      }, 30000);
    }
  };
  const handleResendPhoneOTP = () => {
    if (!disablePhoneOTP && email) {
      phoneCodeHanele(email);
      setDisablePhoneOTP(true);
      setTimeout(() => {
        setDisablePhoneOTP(false);
      }, 30000);
    }
  };
  return (
    <AuthPage title='Change Your Password'>
      <p className='px20 fs-15'>
        Please enter your new password along with Email Code and Sms Code.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div id='otp-field'>
          <p className='font-weight-bold customMargin'>
            Email Code
            <span className='ml-1 text-danger'>*</span>
          </p>
          <OtpInput
            isDisabled={loading}
            numInputs={4}
            onChange={setEmailOtp}
            separator={<span className='separator'>•</span>}
            value={emailOtp}
          />
          <div
            className='new-account mt-3 px20 resendText'
            onClick={handleResendEmailOTP}
            style={{ cursor: disableEmailOTP ? 'not-allowed' : 'pointer' }}
          >
            <p>Resend email code</p>
          </div>
        </div>
        {emailOtp.length > 3 && (
          <FadeIn>
            <div id='otp-field'>
              <p className='font-weight-bold customMargin'>
                Phone Code
                <span className='ml-1 text-danger'>*</span>
              </p>
              <OtpInput
                isDisabled={loading}
                numInputs={4}
                onChange={setPhoneOtp}
                separator={<span className='separator'>•</span>}
                value={phoneOtp}
              />
              <div
                className='new-account mt-3 px20 resendText'
                onClick={handleResendPhoneOTP}
                style={{ cursor: disablePhoneOTP ? 'not-allowed' : 'pointer' }}
              >
                <p>Resend phone code</p>
              </div>
            </div>
          </FadeIn>
        )}
        {phoneOtp.length > 3 && (
          <>
            <FadeIn>
              <InputField
                required
                disabled={loading}
                formik={formik}
                label='New Password'
                name='new_password'
                placeholder='••••••••'
                type='password'
              />
              <InputField
                required
                disabled={loading}
                formik={formik}
                label='Confirm Password'
                name='confirmPassword'
                placeholder='••••••••'
                type='password'
              />

              <AuthSubmitButton
                disabled={loading}
                isAuth={true}
                title='Submit'
              />
            </FadeIn>
          </>
        )}
      </form>
    </AuthPage>
  );
};

export default memo(ResetPassword);

interface ResetPasswordProps {
  resetPasswordHandle: (data: ResetPassParams) => void;
  loading: boolean;
  emailCodeHanele: (email: string) => void;
  phoneCodeHanele: (email: string) => void;
}
