/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import UploadField from '../Shared/FileUploadField';
import InputField from '../Shared/FormikInputField';

const Cnic = ({ formik, imageError, setImageError, setImages }: CnicProps) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col lg={12}>
            <h3 className='text-center text-info'>CNIC Details</h3>
          </Col>
          <Col className='margins-bottom' lg={12}>
            <Form.Check
              checked={formik.isCNIC}
              className='custom-font-size'
              label='I am Pakistani National with valid CNIC / NICOP Number'
              type='checkbox'
            />
          </Col>
          <InputField
            required
            col={4}
            formik={formik}
            label='Name As Per CNIC'
            name='name'
            placeholder='Enter Name'
          />
          <Col md={4}>
            <InputField
              required
              bottomInfo={
                <Form.Check
                  checked={formik.isLifeTimeCNIC}
                  className='custom-font-size'
                  label='CNIC Expiry Lifetime'
                  name={formik.isLifeTimeCNIC}
                  type='checkbox'
                />
              }
              col={12}
              formik={formik}
              isMutual={true}
              label='CNIC Expiry Date'
              name='cnic_expiry_date'
              placeholder=''
              type='Date'
            />
          </Col>

          <InputField
            disabled
            required
            bottomInfo='Please enter CNIC without dashes'
            col={4}
            formik={formik}
            label='CNIC Number'
            name='cnic_number'
            placeholder='0000000000000'
          />

          <UploadField
            required
            col={4}
            formik={formik}
            imageError={imageError}
            setImageError={setImageError}
            setImages={setImages}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default memo(Cnic);

interface CnicProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setImages: any;
  imageError: boolean;
  setImageError: (isImage: boolean) => void;
}
