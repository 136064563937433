import { Card } from 'react-bootstrap';
import { memo } from 'react';

const RiskMeter = () => (
  <Card className='pb-5' style={{ height: 'auto' }}>
    <Card.Header className='pb-0 border-0'>
      <h4 className='mb-0 text-black fs-20'>Risk Meter</h4>
    </Card.Header>
    <Card.Body className='pb-0'>
      <div className='risk-meter'>
        <i className='fa fa-long-arrow-down' />
        <div className='labels'>
          <span>Low</span>
          <span>Moderately Low</span>
          <span>Moderate</span>
          <span>Moderately High</span>
          <span>High</span>
        </div>
      </div>
    </Card.Body>
  </Card>
);

export default memo(RiskMeter);
