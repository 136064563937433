import { EmployerContribution } from 'redux/reducers/employer';

export default function ContributionTableTopBar({
  employerContribution
}: ContributionTableTopBarProps) {
  return (
    <div>
      <div className='table-responsive'>
        <table className='table table-borderless'>
          <thead>
            <tr>
              <th>Payroll</th>
              <th>VPS Contributions</th>
              <th># of employees participating</th>
              <th>Matched Contributions</th>
            </tr>
          </thead>
          <tbody>
            <tr key={1}>
              <td className='fs-20'>
                <p className='mb-0 fs-18'>
                  {employerContribution[0]?.payroll.toLocaleString() || 0} PKR
                </p>
              </td>
              <td className='fs-20'>
                <p className='mb-0 fs-18'>
                  {employerContribution[0]?.vps_contribution.toLocaleString() ||
                    0}{' '}
                  PKR
                </p>
              </td>
              <td className='fs-20'>
                <p className='mb-0 fs-18'>
                  {employerContribution[0]?.no_of_employees_participating.toLocaleString() ||
                    0}
                </p>
              </td>
              <td className='fs-20'>
                <p className='mb-0 fs-18'>
                  {employerContribution[0]?.matched_contribution.toLocaleString() ||
                    0}{' '}
                  PKR
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

interface ContributionTableTopBarProps {
  employerContribution: Array<EmployerContribution>;
}
