import { Card, Col, Row } from 'react-bootstrap';
import { memo } from 'react';

import SelectField from '../Shared/FormikSelectField';
import {
  currentInsurance,
  incomeSourceStability,
  investmentLostOptions,
  marketDownOptions
} from 'static/selection';

const RiskForm = ({ formik }: { formik: never }) => (
  <Card>
    <Card.Body>
      <Row>
        <Col lg={12}>
          <h3 className='text-center text-primary mb-3'>Risk Tolerance</h3>
        </Col>
        <SelectField
          isMulti
          required
          col={4}
          fieldName='current_insurance'
          fields={currentInsurance}
          formik={formik}
          label='Current Insurance'
          placeholder='Select Multiple options'
        />
        <SelectField
          required
          col={4}
          fieldName='income_source_stability'
          fields={incomeSourceStability}
          formik={formik}
          label='My Income Sources'
          placeholder='salary, pension, social security...'
        />
        <SelectField
          required
          col={4}
          fieldName='investment_lost_options'
          fields={investmentLostOptions}
          formik={formik}
          label='If invest loses 10-15%, I would'
          placeholder='Select an option'
        />
        <SelectField
          required
          col={4}
          fieldName='market_down_options'
          fields={marketDownOptions}
          formik={formik}
          label='If the market goes down, I tend to sell some of my riskier investment and put the money in safer investments.'
          placeholder='Select an option'
        />
      </Row>
    </Card.Body>
  </Card>
);

export default memo(RiskForm);
