import { memo } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { links } from 'static/links';
import Performance from '../FundDetails/Performance';
import RiskMeter from '../FundDetails/RiskMeter';
import '../FundDetails/style.css';
import YieldTable from '../FundDetails/YieldTable';
import './thematic.css';
const TabContent = ({ bg, items, title }: TabContentProps) => {
  const { push } = useHistory();

  const activeBar = () => ({
    borderLeft: `7px solid ${bg}`,
    paddingLeft: 3,
    paddingRight: 10
  });

  return (
    <Row className='mt-5 mb-5'>
      <Col sm={3}>
        <h3>SPECIFIC GOALS</h3>
        <span>Choose one below</span>
        <div className='mt-5'>
          <h5>
            <b>CONVENTIONAL</b>
          </h5>
          <p style={activeBar()}>
            Invest according to conventional strategies.
          </p>
        </div>
        <div className='mt-5'>
          <h5>
            <b>ISLAMIC</b>
          </h5>
          <p style={activeBar()}>Invest according to islamic values.</p>
        </div>
      </Col>
      <Col className='p-0' sm={9}>
        <Row className='justify-content-space-between'>
          <h2 style={{ color: bg }}>{title}</h2>
          <Button
            className='rit btn btn-primary ptr mr-4 mb-3'
            onClick={() => push(links.mutualFundAccount)}
          >
            Invest Now
          </Button>
        </Row>

        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                <Card className='pb-2' style={{ height: 'auto' }}>
                  <Card.Header className='pb-0 border-0'>
                    <h4 className='mb-0 text-black fs-20'>Product Summary</h4>
                  </Card.Header>
                  <Card.Body>
                    <ul>
                      <li style={{ listStyleType: 'disc' }}>
                        Seeks to track the performance of Karachi Stock Exchange
                        (KSE) dividened growers index.
                      </li>
                      <li style={{ listStyleType: 'disc' }}>
                        Fund ramins fully invested.
                      </li>
                      <li style={{ listStyleType: 'disc' }}>
                        Low expenses minimize net tracking error.
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={12}>
                <Performance />
              </Col>
              <Col sm={12}>
                <RiskMeter />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={12}>
                <YieldTable />
              </Col>
              <Col sm={12}>
                <Card className='pb-2' style={{ height: 'auto' }}>
                  <Card.Header className='pb-0 border-0'>
                    <h4 className='mb-0 text-black fs-20'>
                      Stocks and Weights
                    </h4>
                  </Card.Header>
                  <Card.Body>
                    <div className='table-responsive'>
                      <table className='table table-striped cursor-pointer'>
                        <thead className='text-black'>
                          <tr>
                            <th>Stocks and Segments</th>
                            <th className='text-right'>Weightage(%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map(({ code, name }, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  push(`thematic/${code}`);
                                }}
                              >
                                <td
                                  style={{
                                    borderLeft: `15px solid ${
                                      index % 2 !== 0 ? bg : 'transparent'
                                    }`
                                  }}
                                >
                                  <span className='ml-1'>{name}</span>
                                </td>
                                <td className='text-right'>1{index}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(TabContent);

interface TabContentProps {
  bg: string;
  title: string;
  items: Array<{ code: string; name: string }>;
}
