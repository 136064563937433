import { useAppLoading } from 'hooks/appLoading';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import FormikRadioField from 'jsx/components/Shared/FormikRadioField';
import Loader from 'jsx/components/Shared/Loader';
import { useEffect, useState } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import {
  onGetEmployer,
  Company,
  onGetEmployeeProvidentFund,
  onGetVPSBalance,
  onGetVPSGraphData
} from 'redux/actions';
import { investmentType } from 'static/selection';
import { checkProperties } from 'utils/validateObject';
import OccupationPensionDetails from './OccupationPensionDetails';

const EmployerScreen = ({ formik }: vpFormProps) => {
  const [displayUserDetails, setDisplayUserDetails] = useState<boolean>(false);
  const { user, userAccountData } = useAppSelector(state => state.auth);
  const { employeData, message } = useAppSelector(state => state.portfolio);
  const { VPSBalanceDetails, employeeProvidentFundDetails } = useAppSelector(
    state => state.employer
  );
  const loading = useAppLoading('employer');

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      formik.values.CNIC &&
      formik.values.CNIC.length === 13 &&
      Object.entries(formik.errors).length === 0
    ) {
      setDisplayUserDetails(true);
    } else setDisplayUserDetails(false);
  }, [formik]);
  useEffect(() => {
    if (displayUserDetails) {
      dispatch(onGetEmployeeProvidentFund(user?.user_id));
      dispatch(onGetVPSBalance(user?.user_id));
      dispatch(onGetVPSGraphData());
    }
  }, [dispatch, displayUserDetails, user?.user_id]);
  useEffect(() => {
    if (
      checkProperties(employeeProvidentFundDetails) === false &&
      formik.values.CNIC.length === 13
    ) {
      dispatch(onGetEmployer(formik.values.CNIC, user?.user_id));
    }
  }, [
    dispatch,
    employeeProvidentFundDetails,
    formik.values.CNIC,
    user?.user_id
  ]);
  useEffect(() => {
    if (formik.values.investment_type) {
      formik.setFieldValue('CNIC', userAccountData.cnicValues.cnic_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountData, formik.values.investment_type]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card className='pb-2 mt-5' style={{ height: 'auto' }}>
          <Card.Body>
            <h1 className='mb-5 ml-3'>
              Occupational Pension / My Contribution
            </h1>
            <Row>
              <Col lg={6}>
                <FormikRadioField
                  singleLine
                  col={12}
                  fieldName='investment_type'
                  fields={investmentType}
                  formik={formik}
                  label='Choose Investment'
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </form>

      {loading ? (
        <Loader />
      ) : (
        !checkProperties(employeData) && (
          <OccupationPensionDetails
            VPSBalanceDetails={VPSBalanceDetails}
            employeData={employeData}
            employeeProvidentFundDetails={employeeProvidentFundDetails}
            formik={formik}
          />
        )
      )}

      {message.length && !loading ? (
        <Alert variant={'danger'}>{message}</Alert>
      ) : null}
    </>
  );
};

export default EmployerScreen;

export interface vpFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  isPortfolioForm: boolean;
  companies: Array<Company>;
  cont_prec: number;
}
