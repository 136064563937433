import { memo } from 'react';

const HeaderText = () => (
  <>
    <h1>
      Complete <span className='text-primary'>Account</span> Setup
    </h1>
    <p>Almost there, we just few more information from you for you</p>
  </>
);

export default memo(HeaderText);
