import { memo } from 'react';

const Statistics = ({
  setSendMessage
}: {
  setSendMessage: (val: boolean) => void;
}) => (
  <div className='text-center'>
    <div className='row'>
      <div className='col'>
        <h3 className='m-b-0'>150</h3>
        <span>News</span>
      </div>
      <div className='col'>
        <h3 className='m-b-0'>140</h3> <span>Articles</span>
      </div>
      <div className='col'>
        <h3 className='m-b-0'>45</h3> <span>Blogs</span>
      </div>
    </div>
    <div className='mt-4'>
      <span className='ptr btn btn-primary mb-1 mr-1'>Need Help?</span>
      <span
        className='ptr btn btn-primary mb-1 mr-1'
        onClick={() => setSendMessage(true)}
      >
        Contact Us
      </span>
    </div>
  </div>
);

export default memo(Statistics);
