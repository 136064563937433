import { memo } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const LinkAccounts = () => (
  <Row>
    <Col lg={12}>
      <Card>
        <Card.Header>
          <Card.Title>Link Accounts</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <strong>Cash</strong>
            </Col>
            <Col sm={6}>
              <strong>$1639.79</strong>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col sm={6}>
              <span className='mr-3 bgl-info p-3 text-muted round'>
                <span className='fa fa-credit-card fa-lg' />
              </span>
              <strong>Checking - 7563</strong>
            </Col>
            <Col sm={6}>
              <strong>$1639.79</strong>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col sm={6}>
              <span className=' mr-3 bgl-info p-3 text-muted round'>
                <span className='fa fa-credit-card fa-lg' />
              </span>
              <strong>Saving - 9874</strong>
            </Col>
            <Col sm={6}>
              <strong>$0</strong>
              <p className='lead' style={{ fontSize: '0.7rem' }}>
                5 days ago
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <hr />
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className='align-items-center'>
            <Col sm={6}>
              <div className='mr-3 icon-cont text-primary'>
                <span className='fa fa-plus fa-lg' />
              </div>
              <strong>Link New Account</strong>
              <p
                className='lead'
                style={{ fontSize: '0.7rem', marginLeft: 70 }}
              >
                Link a new account with mahaana
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default memo(LinkAccounts);
