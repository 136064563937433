import { memo, useCallback, useEffect, useState } from 'react';
import TransactionHistoryComponent from 'jsx/components/TransactionHistory';
import { onGetTransactionHistory } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useAppLoading } from 'hooks/appLoading';

const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const { data, totalRecords } = useAppSelector(
    state => state.transactionHistory
  );
  const loading = useAppLoading('transactionHistory');

  const loadData = useCallback(() => {
    dispatch(onGetTransactionHistory(currentPage));
  }, [currentPage, dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return (
    <TransactionHistoryComponent
      currentPage={currentPage}
      data={data}
      loading={loading}
      setCurrentPage={setCurrentPage}
      totalRecords={totalRecords}
    />
  );
};

export default memo(TransactionHistory);
