import { memo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AvailableGoalsProps, CompletedGoalsProps } from 'redux/actions';
import { links } from 'static/links';

const LinkAccounts = ({
  availableGoals,
  completedGoals
}: {
  completedGoals: Array<CompletedGoalsProps>;
  pathname: string;
  availableGoals?: Array<AvailableGoalsProps>;
}) => {
  const { push } = useHistory();
  const [show, setShow] = useState(false);

  const isPensionAccountOpen = !completedGoals?.find(
    el => el.goal_type_id === 1
  );
  const isOccupationalPAccountOpen = !completedGoals?.find(
    el => el.goal_type_id === 2
  );
  const VpsGoal = availableGoals?.find(el =>
    el.goal_type_description.includes('VPS')
  );
  const OpsGoal = availableGoals?.find(el =>
    el.goal_type_description.includes('occupational pension')
  );

  return (
    <Row className='p-3'>
      <Col sm={6}>
        <Card>
          <Card.Body>
            {isPensionAccountOpen && VpsGoal && (
              <>
                <Row className='align-items-center'>
                  <Col sm={12}>
                    <div
                      className='cursor-pointer'
                      onClick={() => {
                        push({
                          pathname: links.portfolio,
                          state: { goal_id: VpsGoal.id }
                        });
                      }}
                    >
                      <div className='mr-3 icon-cont text-primary'>
                        <span className='fa fa-level-up fa-lg' />
                      </div>
                      <strong>{VpsGoal.goal_type_description}</strong>
                      <p
                        className='lead'
                        style={{ fontSize: '0.7rem', marginLeft: 70 }}
                      >
                        {VpsGoal.goal_type_short_description}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {isOccupationalPAccountOpen && OpsGoal && (
              <>
                <Row className='align-items-center'>
                  <Col sm={12}>
                    <div
                      className='cursor-pointer'
                      onClick={() => {
                        push({
                          pathname: links.portfolio,
                          search: '?role=employer',
                          state: { goal_id: OpsGoal.id }
                        });
                      }}
                    >
                      <div className='mr-3 icon-cont text-primary'>
                        <span className='fa fa-pagelines fa-lg' />
                      </div>
                      <strong>{OpsGoal.goal_type_description}</strong>
                      <p
                        className='lead'
                        style={{ fontSize: '0.7rem', marginLeft: 70 }}
                      >
                        {OpsGoal.goal_type_short_description}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <Row className='align-items-center'>
              <Col sm={12}>
                <div className='cursor-pointer' onClick={() => setShow(true)}>
                  <div className='mr-3 icon-cont text-primary'>
                    <span className='fa fa-plus fa-lg' />
                  </div>
                  <strong>Add a goal or windfall</strong>
                  <p
                    className='lead'
                    style={{ fontSize: '0.7rem', marginLeft: 70 }}
                  >
                    Set a goal or tell us about future income.
                  </p>
                </div>
              </Col>
              <Col sm={6} />
            </Row>
          </Card.Body>
        </Card>
      </Col>
      {show && (
        <Col sm={6}>
          <AddGoal close={() => setShow(false)} />
        </Col>
      )}
    </Row>
  );
};

export default memo(LinkAccounts);

const AddGoal = ({ close }: { close: () => void }) => (
  <Card>
    <Card.Header>
      <div>What if I...</div>
      <div className='d-flex align-items-center'>
        <span
          className='badge bg-warning text-white fs-14'
          style={{ marginRight: '25px' }}
        >
          Coming Soon
        </span>
        <i
          aria-hidden='true'
          className='fa fa-times fa-lg ptr rit'
          onClick={close}
        />
      </div>
    </Card.Header>

    <Card.Body>
      <Row>
        <Col className='goal-list-options' sm={5}>
          <i aria-hidden='true' className='fa fa-home mr-2' />
          Buy a house
        </Col>
        <Col sm={2} />
        <Col className='goal-list-options' sm={5}>
          <i aria-hidden='true' className='fa fa-plane mr-2' />
          Take time off to travel
        </Col>
        <Col className='goal-list-options' sm={5}>
          <i aria-hidden='true' className='fa fa-graduation-cap mr-2' />
          Save for college
        </Col>
        <Col sm={2} />
        <Col className='goal-list-options' sm={5}>
          <i aria-hidden='true' className='fa fa-tag mr-2' />
          Expect a large expense
        </Col>
        <Col className='goal-list-options' sm={5}>
          <i aria-hidden='true' className='fa fa-calculator mr-2' />
          Receive a windfall
        </Col>
      </Row>
    </Card.Body>
  </Card>
);
