import { memo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import Statistics from '../Shared/Statistics';
import { PensionGraphData } from 'redux/reducers/pension';
import { useStatisticsData } from 'hooks/useStatisticsData';

const ChartContainer = ({ data, heading, title }: ChartContainerProps) => {
  const chartData = useStatisticsData(data, 'amount', 'amount', 'age');
  return (
    <Row>
      <Col lg={12} xl={12}>
        <Card style={{ overflow: 'scroll' }}>
          <div className='pt-4 pl-4'>
            <h3>{heading}</h3>
            <p>
              {title} <span className='ti-info-alt' />
            </p>
          </div>
          <Card.Body>
            <Statistics series={[chartData]} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default memo(ChartContainer);

interface ChartContainerProps {
  data: Array<PensionGraphData<string>>;
  heading: string;
  title: string;
}
